import { useSearchParams } from 'react-router-dom';
import { tabStyles } from '../../../../utils/helpers';
import { Button, Space, Tabs } from '@mantine/core';
import { CostComponent } from './cost';
import { BomComponent } from './bom';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Col, Row } from 'react-bootstrap';
import { ProjectOverview } from '../projectOverview';

const tabs = [
  {
    name: 'CONSOLIDATED COST COMPONENT',
    value: 'cost',
    component: (props) => <CostComponent {...props} />,
  },

  {
    name: 'BOM LEVEL VIEW',
    value: 'bom',
    component: (props) => <BomComponent {...props} />,
  },
];

export const Vic = ({ activeTab }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeSubTab = searchParams.get('subtab') || 'cost';

  const handleSubmit = () => {};

  const Footer = () => (
    <Row className='justify-content-md-end' md={4}>
      <Col md='auto'>
        <Button
          className='next'
          onClick={handleSubmit}
          variant='gradient'
          color='dark'
          radius='md'
          size='md'
          fullWidth
          disabled={false}
          style={{
            marginRight: '1rem',
            width: '200px',
          }}
        >
          Submit
        </Button>
      </Col>
    </Row>
  );

  return (
    <ContentPaper footer={<Footer />}>
      <ProjectOverview />
      <Space h='sm' />
      <Tabs
        value={activeSubTab}
        onTabChange={(value) => setSearchParams({ tab: activeTab, subtab: value })}
        variant='unstyled'
        styles={tabStyles}
        className='prod-dev-tabs'
        color='#e5b611'
      >
        <Tabs.List>
          {tabs.map((tab) => (
            <Tabs.Tab value={tab.value} className='menu-tabs'>
              {tab.name}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {tabs.map((tab) => (
          <Tabs.Panel value={tab.value}>{tab.component({ activeSubTab, activeTab })}</Tabs.Panel>
        ))}
      </Tabs>
    </ContentPaper>
  );
};
