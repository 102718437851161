import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Text,
  Select,
  Button,
  SimpleGrid,
  FileInput,
  Grid,
  Image,
  TextInput,
  Textarea,
  Center,
  Divider,
  CloseButton
} from '@mantine/core';
import { DownloadIcon, UploadIcon } from '@radix-ui/react-icons';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { axios } from '../../../utils/axios';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from '../../../utils/authConfig';
import { showAlert } from '../../../utils/alerts';
import { moduleRoles } from '../../../constants';
import '../../Login/Login.css';
import abilogo from '../../../images/abilogo.svg';
import abilogoback from '../../../images/abilogoback.jpg';
import { jwtDecode } from 'jwt-decode';
import { decodeToken } from 'react-jwt';
import { ACCEPTED_MIME_TYPE } from '../../../common/dropzone/constants';

function Register() {
  const [modules, setModules] = useState([
    { value: 'RFS', label: 'RFS' },
    { value: 'Costing Request', label: 'Costing Request' },
  ]);
  const { login, clearStorage, userName = '', user } = useContext(AuthContext);
  const [fetched, setFetched] = useState({});
  const [userEmail, setUserEmail] = useState();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [posted, setPosted] = useState(true);
  const [stay, setStay] = useState(true);
  const stayRef = useRef(null);
  stayRef.current = stay;
  const [selectedModule, setSelectedModule] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedSKUType, setSelectedSKUType] = useState('');
  const [justification, setJustification] = useState('');
  const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx','msg', 'eml']; 
  const [selectedRole, setSelectedRole] = useState(null);
  const [justificationError, setJustificationError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [skuTypeError, setSkuTypeError] = useState('');
  const [plantTypeError,setPlantTypeError ] = useState('');
  const [countryTypeError, setCountryTypeError] = useState('');
  const [zoneTypeError, setZoneTypeError] = useState('');
  const [attachTypeError, setAttachTypeError] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedPlant, setSelectedPlant] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [rejectedUploads, setRejectedUploads] = useState([]);

  const [fetchedOptions, setFetchedOptions] = useState({
    Country: {},
    Plant: {},
    Zone: [],
    skus: [],
  });

  useEffect(() => {
    if (user) {
      const { cr_registered = null, rfs_registered = null } = user;
      setSelectedModule(!cr_registered ? 'Costing Request' : !rfs_registered ? 'RFS' : '');
    }
  }, [user]);

  useEffect(() => {
    if (selectedRole) {
      fetchOptions(selectedRole);
    }
  }, [selectedRole]);

  useEffect(() => {
    !!fetched.role_requested ? setPosted(false) : setPosted(true);
  }, [fetched.role_requested]);

  useEffect(() => {
    if (accounts[0]) setUserEmail(accounts[0].idTokenClaims.email);
  }, [accounts[0]]);

  const fetchOptions = async (roleAlias) => {
    try {
      const response = await axios.get('/user/v2/options', {
        params: { role: roleAlias },
      });

      if (response.data.success) {
        const { zone_country_plant, skus, data } = response.data;

        setFetchedOptions({
          ...data,
          Zone: Object.keys(zone_country_plant),
          Country: zone_country_plant,
          skus: skus,
          Plant: {},
        });
      } else {
        console.error('Error fetching options:', response.data);
      }
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };
  const validateFileExtension = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    return allowedExtensions.includes(extension);
  };

  const handleFileSelect = (files) => {
    const newAttachments = [...attachments];
    const newRejectedUploads = [...rejectedUploads];

    Array.from(files).forEach((file) => {
      if (validateFileExtension(file.name)) {
        newAttachments.push(file);
      } else {
        newRejectedUploads.push(file.name);
      }
    });

    setAttachments(newAttachments);
    setRejectedUploads(newRejectedUploads);
  };

  const handleFileRemove = (index) => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };
  const handleSelect = (name, value) => {
    setFetched((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleModuleChange = (value) => {
    setSelectedModule(value);
    setSelectedRole(null);
    setSelectedZone(null);
    setSelectedCountry(null);
    setSelectedPlant(null);
    setSelectedSKUType(null);
  };

  const handleRoleChange = (value) => {
    setSelectedRole(value);
    setSelectedZone(null);
    setSelectedCountry(null);
    setSelectedPlant(null);
    setSelectedSKUType(null);
    fetchOptions(value);
  };

  const handleZoneChange = (value) => {
    setSelectedZone(value);
    setSelectedCountry(null);
    setSelectedPlant(null);
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setSelectedPlant(null);
  };

  const createRegister = (payload, callback) => {
    axios.post(`/register/add`, payload).then(
      (res) => {
        res.status === 200 ? setStay(false) : setStay(true);
        res.status === 200 ? setPosted(true) : setPosted(false);
        callback();
      },
      (err) => {
        setPosted(false);
        console.log(err);
        return showAlert({ message: 'Registration failed. Please try again.' }, 'error');
      },
    );
  };

  const handleNext = () => {
    setPosted(true);
    createRegister(
      {
        name: userName,
        email: userEmail,
        role_requested: fetched.role_requested,
        role_approved: 'initiator',
        role_reviewed: false,
      },
      () => {
        if (isAuthenticated) {
          const request = {
            ...loginRequest,
            account: accounts[0],
          };

          instance
            .acquireTokenSilent(request)
            .then((response) => {
              login(
                {
                  token: response.accessToken,
                  email: response.idTokenClaims.email,
                },
                (path) => {
                  if (!stayRef.current) navigate(path);
                },
              );
            })
            .catch((e) => {
              return showAlert({ message: 'Registration failed . Please try again.' }, 'error');
            });
        }
      },
    );
  };

  const handleAction = () => {
    if (selectedModule === 'RFS') {
      handleNext();
    } else {
      handleSubmit();
    }
  };
  const { is_scoped_to_country, is_scoped_to_plant, is_scoped_to_type_of_sku } = fetchedOptions || {};

  const handleSubmit = async (action) => {
    let isValid = true;

    if (!selectedRole) {
      setRoleError('Role is required');
      isValid = false;
    }
      if (is_scoped_to_country&&!selectedCountry) {
        setCountryTypeError('Country is required');
        isValid = false;
      }
    
     if (is_scoped_to_plant&&!selectedPlant) {
       setPlantTypeError('Plant is required');
       isValid = false;
     }
    if (is_scoped_to_country&&!selectedZone) {
      setZoneTypeError('Zone is required');
      isValid = false;
    }
    if (is_scoped_to_type_of_sku&& !selectedSKUType) {
      setSkuTypeError('Type of SKU is required');
      isValid = false;
    }
    if (attachments.length === 0) {
      setAttachTypeError('Attachment is required');
      isValid = false;
    }
    if (!justification.trim()) {
      setJustificationError('Justification is required');
      isValid = false;
    }
    if (!isValid) {
      return;
    }

    setLoading({ [action]: true });
    setPosted(true);

    try {
      let accessToken = '';
      if (isAuthenticated) {
        const request = {
          scopes: ['User.Read'],
          account: accounts[0],
        };

        try {
          const response = await instance.acquireTokenSilent(request);
          accessToken = response.accessToken;
        } catch (authError) {
          showAlert({ message: 'Authentication failed. Please try again.' }, 'error');
          setLoading({ [action]: false });
          return;
        }
      }

      const formData = new FormData();
      formData.append('access_token', accessToken);
      formData.append('module', selectedModule || '');
      formData.append('zone', selectedZone || '');
      formData.append('role_requested', selectedRole || '');
      formData.append('plant', selectedPlant || '');
      formData.append('country', selectedCountry || '');
      formData.append('type_of_sku', selectedSKUType || '');
      formData.append('justification', justification || '');

      attachments.forEach((file) => {
        formData.append('attachments', file);
      });

      formData.forEach((value, key) => {
        if (key === 'attachments') {
          console.log(`${key}: ${value.name}`);
        } else {
          console.log(`${key}: ${value}`);
        }
      });

      const response = await axios.post('/register/v2', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.data.success) {
        showAlert({
          message: 'Registration successful. Please wait for approval.',
          type: 'success',
        });
        navigate('/');
      } else {
        showAlert(
          { message: response.data.message || 'Registration failed. Please try again.' },
          'error',
        );
      }
    } catch (error) {
      if (error.response) {
        showAlert({
          message: `Error: ${
            error.response.data.message || 'Registration failed. Please try again.'
          }`,
          type: 'error',
        });
      } else if (error.request) {
        showAlert({ message: 'No response from the server. Please try again.', type: 'error' });
      } else {
        showAlert({ message: `Error: ${error.message}`, type: 'error' });
      }
    } finally {
      setLoading({ [action]: false });
    }
  };

  const renderRole = (role) => {
    return moduleRoles[role]?.map((role) => ({
      label: role.label,
      value: role.value,
    }));
  };

  const availableCountries = fetchedOptions.Zone.includes(selectedZone)
    ? Object.keys(fetchedOptions.Country[selectedZone] || {})
    : [];
  const availablePlants = selectedCountry
    ? fetchedOptions.Country[selectedZone][selectedCountry] || []
    : [];

  return (
    <>
      <div style={{ height: '100vh', overflow: 'hidden' }}>
        <Grid align='flex-start'>
          <Grid.Col span={12} className='photo' style={{ position: 'relative', height: '100%' }}>
            <Image
              src={abilogoback}
              alt='AB InBev Background'
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </Grid.Col>

          <Grid.Col span={12} className='data content'>
            <Grid.Col span={6} className='loginForm registerForm'>
              <div className='innerContent'>
                <div>
                  <Link to={'/'}>
                    <Image
                      style={{
                        maxWidth: '40%',
                        margin: '0 auto',
                      }}
                      className='logo'
                      src={abilogo}
                      alt='AB InBev logo'
                    />
                  </Link>
                  <Divider className='divider' my='1'></Divider>
                  <Text
                    style={{
                      fontSize: '3.8vh',
                      fontWeight: '600',
                      display: 'inline-flex',
                      justifyContent: 'center',
                      width: '100%',
                      color: '#fff',
                    }}
                  >
                    Speed to Market
                  </Text>

                  <div style={{ padding: '0 25px' }}>
                    <TextInput
                      style={{
                        marginTop: '25px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignContent: 'flex-start',
                      }}
                      styles={(theme) => ({
                        input: {
                          borderBottom: '1px solid #ffc107',
                        },
                        label: {
                          fontWeight: '500',
                          fontSize: '1.9vh',
                          color: '#fff',
                        },
                      })}
                      label='Full Name'
                      value={userName}
                      disabled='true'
                    />

                    <TextInput
                      style={{
                        paddingTop: '4vh',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                      styles={(theme) => ({
                        input: {
                          border: '1px solid #ffc107',
                        },
                        label: {
                          fontWeight: '500',
                          fontSize: '1.9vh',
                          color: '#fff',
                        },
                      })}
                      value={userEmail}
                      label='Email'
                      disabled='true'
                    />

                    <div style={{ display: 'flex', gap: '20px', paddingTop: '2vh' }}>
                      {!user && (
                        <Select
                          style={{
                            paddingTop: '4vh',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            flex: selectedModule ? 1 : 2,
                          }}
                          required
                          clearable
                          radius='md'
                          data={modules}
                          name='module'
                          placeholder='Choose a Module'
                          label='Select the Module'
                          value={selectedModule}
                          onChange={handleModuleChange}
                          styles={{
                            input: {
                              display: 'inline-flex',
                              justifyContent: 'center',
                              border: '2px solid #ffc107',
                            },
                            label: { fontSize: '1.9vh', color: '#fff' },
                          }}
                        />
                      )}

                      {selectedModule && (
                        <Select
                          style={{
                            paddingTop: '4vh',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            flex: selectedModule ? 1 : 2,
                          }}
                          required
                          clearable
                          radius='md'
                          data={renderRole(selectedModule)}
                          name='role_requested'
                          label='Select your desired Role'
                          placeholder='Choose a Role'
                          value={selectedRole}
                          error={roleError}
                          onChange={(value) => {
                            handleRoleChange(value);
                            if (value) setRoleError(''); 
                          }}
                          styles={{
                            input: {
                              display: 'inline-flex',
                              justifyContent: 'center',
                              border: '2px solid #ffc107',
                            },
                            label: { fontSize: '1.9vh', color: '#fff' },
                          }}
                        />
                      )}
                    </div>

                    {selectedModule === 'Costing Request' && selectedRole && (
                      <>
                        {(is_scoped_to_country) && (
                          <Select
                            style={{
                              paddingTop: '4vh',
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                            }}
                            name='zone'
                            label='Select Zone'
                            placeholder='Choose a zone'
                            data={fetchedOptions.Zone}
                            value={selectedZone}
                            error={zoneTypeError}
                            withAsterisk
                            onChange={(value) => {
                              handleZoneChange(value);
                              if (value) setZoneTypeError(''); 
                            }}
                            styles={{
                              input: {
                                border: '2px solid #ffc107',
                                color: '#fff',
                              },
                              label: { fontSize: '1.9vh', color: '#fff' },
                            }}
                          />
                        )}

                        {(is_scoped_to_country) &&
                          availableCountries.length > 0 && (
                            <Select
                              style={{
                                paddingTop: '4vh',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                              }}
                              required
                              clearable
                              radius='md'
                              data={availableCountries}
                              value={selectedCountry}
                              error={countryTypeError}
                              onChange={(value) => {
                                handleCountryChange(value);
                                if (value) setCountryTypeError(''); 
                              }}
                              name='country'
                              label='Select Country'
                              placeholder='Choose a Country'
                              styles={{
                                input: {
                                  display: 'inline-flex',
                                  justifyContent: 'center',
                                  border: '2px solid #ffc107',
                                },
                                label: { fontSize: '1.9vh', color: '#fff' },
                              }}
                            />
                          )}

                        {selectedCountry && (is_scoped_to_plant) && (
                            <Select
                              style={{
                                paddingTop: '4vh',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                              }}
                              required
                              clearable
                              radius='md'
                              data={availablePlants}
                              name='plant'
                              label='Select Producing Plant'
                              placeholder='Choose a Plant'
                              error={plantTypeError}
                              value={selectedPlant}
                              onChange={(value) => {
                                setSelectedPlant(value);
                                if (value) setPlantTypeError(''); 
                              }}
                              styles={{
                                input: {
                                  display: 'inline-flex',
                                  justifyContent: 'center',
                                  border: '2px solid #ffc107',
                                },
                                label: { fontSize: '1.9vh', color: '#fff' },
                              }}
                            />
                          )}
                          {selectedCountry && (is_scoped_to_type_of_sku) && (
                            <Select
                              style={{
                                paddingTop: '4vh',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                              }}
                              required
                              clearable
                              radius='md'
                              data={fetchedOptions.skus}
                              name='sku'
                              label='Select SKU Type'
                              value={selectedSKUType}
                              error={skuTypeError}
                              onChange={(value) => {
                                setSelectedSKUType(value);
                                if (value) setSkuTypeError(''); 
                              }}
                              styles={{
                                input: {
                                  display: 'inline-flex',
                                  justifyContent: 'center',
                                  border: '2px solid #ffc107',
                                },
                                label: { fontSize: '1.9vh', color: '#fff' },
                              }}
                            />
                          )}
                        </>
                    )}

                    {selectedModule && selectedModule !== 'RFS' && (
                      <>
                        <FileInput
                          style={{
                            paddingTop: '4vh',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                          required
                          radius='md'
                          accept={ACCEPTED_MIME_TYPE}
                          placeholder={
                            <span>
                              <UploadIcon style={{ marginLeft: '1px' }} /> Upload approval mail
                            </span>
                          }
                          name='managerApproval'
                          label='Manager Approval Attachment'
                          value={attachments}
                          maxSize={5 * 1024 ** 2}
                          onChange={(value) => {
                            handleFileSelect(value);
                            if (value.length > 0) setAttachTypeError('');
                          }}
                          error={attachTypeError}
                          onRemove={handleFileRemove}
                          multiple
                          description='Allowed file types: jpeg, jpg, png, eml, msg'
                          styles={{
                            input: {
                              display: 'inline-flex',
                              justifyContent: 'center',
                              border: '2px solid #ffc107',
                              background: 'transparent',
                              color: '#fff',
                            },
                            label: { fontSize: '1.9vh', color: '#fff' },
                            '::placeholder': {
                              color: '#fff',
                              opacity: 1,
                            },
                          }}
                        />

                        {attachments.length > 0 && (
                          <div style={{ marginTop: '8px' }}>
                            {attachments.map((file, index) => (
                              <div
                                key={index}
                                style={{
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  padding: '4px',
                                }}
                              >
                                <span style={{ color: '#fff' }}>{file.name}</span>
                                <Button
                                  variant="subtle"
                                  color="white"
                                  onClick={() => handleFileRemove(index)}
                                  compact
                                  style={{
                                    marginLeft: '8px',
                                    padding: '0',
                                    border: 'none',
                                    background: 'none',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    color: 'white',
                                  }}
                                >
                                 <CloseButton aria-label="Close modal" />
                                </Button>
                              </div>
                            ))}
                          </div>
                        )}
                        <Textarea
                          style={{
                            paddingTop: '4vh',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                          required
                          radius='md'
                          placeholder='Enter your justification here...'
                          name='justification'
                          label={<span style={{ color: '#fff' }}>Justification for the role</span>}
                          value={justification}
                          error={justificationError}
                          onChange={(e) => {
                            setJustification(e.currentTarget.value);
                            if (e.currentTarget.value) {
                              setJustificationError(''); 
                            }
                          }}
                          styles={{
                            input: {
                              display: 'inline-flex',
                              justifyContent: 'center',
                              border: '2px solid #ffc107',
                              background: 'transparent',
                              color: '#fff',
                            },
                            label: { fontSize: '1.9vh', color: '#fff' },
                            '::placeholder': {
                              color: '#fff',
                              opacity: 1,
                            },
                          }}
                        />
                      </>
                    )}

                    <Text
                      style={{
                        fontSize: '1.8vh',
                        padding: '5vh 0 2vh',
                        display: 'inline-flex',
                        justifyContent: 'center',
                        width: '100%',
                        color: '#fff',
                      }}
                    >
                      Submit for Approval and Proceed as Initiator
                    </Text>
                    <Center>
                      <Button
                        variant='gradient'
                        gradient={{ from: '#D7A833', to: '#F4DF03', deg: 90 }}
                        styles={{
                          root: {
                            color: '#000',
                          },
                        }}
                        className='w-50'
                        onClick={handleAction}
                      >
                        Submit
                      </Button>
                    </Center>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    flexWrap: 'wrap',
                    aligncontent: 'center',
                    alignItems: 'center',
                    maxWidth: '55%',
                    margin: '0 auto',
                  }}
                ></div>
              </div>
            </Grid.Col>
          </Grid.Col>
        </Grid>
      </div>
    </>
  );
}

export default Register;
