import React, { useState, useContext, useEffect } from 'react';
import { Box, Accordion, Grid, Button, TextInput } from '@mantine/core';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { RfsContext } from '../../../../contexts/RfsContext';
//import { MaterialCodeTableVeam } from './MaterialCodeTableVeam';
import MaterialsStatusChangeTableVEAM from './MaterialsStatusChangeTableVEAM';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppLoader } from '../../../../components/common/loader';
import csvDownload from 'json-to-csv-export';
import { TableExport } from 'tabler-icons-react';
import moment from 'moment';
import { AuthContext } from '../../../../contexts/AuthContext';
import { prodDevPermissions, liquidBomChanges } from '../../../../constants';
import { AccfComment } from '../../../../common/accf';

const MaterialsStatusChange = () => {
  const { form, info, fetchStatusVeam, StoreManualClosureVEAM } = useContext(RfsContext);
  const { userRole } = useContext(AuthContext);
  const { rfs_number, rfs_type = '' } = form.rfsInformation;
  const [statusData, setstatusData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, handlers] = useState(0);
  const [disable, setDisable] = useState(false);
  const [accState, setAccState] = useState([]);
  const [slaId, setSlaId] = useState(0);
  const [status, setStatus] = useState(true);
  const [accfData, setAccfData] = useState([]);

  /*useEffect(() => {
    handlers.setState({ initialItem: 0 });
  }, [active]);*/

  useEffect(() => {
    setDisable(!permissions['changeover'].includes(userRole));
  }, []);

  const save = (payload, id, callback) => {
    setLoading(true);
    StoreManualClosureVEAM(payload, id).then(() => {
      info(rfs_number);
      getStatusDetails(rfs_number);
      setAccState(callback);
    });
  };

  useEffect(() => {
    getStatusDetails(rfs_number);
  }, [rfs_number]);

  const getStatusDetails = (rfs_number) => {
    setLoading(true);
    fetchStatusVeam(rfs_number).then((res) => {
      const statusChangeFilter = res.data.data.filter(
        (item) => item.sla_weeks_id === 218 || item.sla_weeks_id === 237,
      );
      setAccfData(statusChangeFilter);

      const result = [];

      result['Materials'] = statusChangeFilter
        .filter((item) => item.selection_type === 'materials')
        .sort((a, b) => a.id - b.id);
      result['Liquids'] = statusChangeFilter
        .filter((item) => item.selection_type === 'liquids')
        .sort((a, b) => a.id - b.id);

      setstatusData(result);
      setSlaId(statusChangeFilter[0].sla_weeks_id);

      setLoading(false);
    });
  };

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < accfData.length; i++) {
      arr.push(accfData[i].status);
    }
    if (arr) {
      const setArray = [...new Set(arr)];
      if (
        (setArray.length === 1 && setArray.includes('Closed')) ||
        (setArray.length === 1 && setArray.includes('Not Started'))
      ) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    }
  }, [accfData]);

  if (loading || !rfs_type || rfs_type.length === 0) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  const permissions = prodDevPermissions[rfs_type];

  const downloadExcel = (type) => {
    const dataToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };
    const materialsToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };
    const liquidHeaders = [
      'Liquid Code',
      'Liquid Description',
      'Plant',
      'Status',
      'Action',
      'Step Deadline',
      'Step finished',
    ];
    const materialHeaders = [
      'Material Code',
      'Material Description',
      'Plant',
      'Status',
      'Action',
      'Step Deadline',
      'Step finished',
    ];

    const liquids = statusData.Liquids;
    const materials = statusData.Materials;
    if ((liquids || []).length > 0) {
      dataToExport.data = getDataToExport(liquids, 'liquids');
      dataToExport.filename =
        'Material_Status_change_liquids' + moment().format('YYYY-MM-DD HH:mm:ss');
      dataToExport.headers = liquidHeaders;
      csvDownload(dataToExport);
    }

    if ((materials || []).length > 0) {
      materialsToExport.data = getDataToExport(materials, 'materials');
      materialsToExport.filename =
        'Material_Status_change_materials' + moment().format('YYYY-MM-DD HH:mm:ss');
      materialsToExport.headers = materialHeaders;
      csvDownload(materialsToExport);
    }
  };

  const getDataToExport = (data, type) => {
    const csvArray = type === 'liquids' ? data.map(dataToExportL) : data.map(dataToExportM);
    return csvArray;
  };

  const dataToExportM = ({
    plant,
    sla_date,
    status,
    manually_closed,
    finished_date,
    int_rfs_material_number,
    material_type,
    pack_mat_material_text,
    new_material_code,
    new_material_description_intrfsml,
  }) => {
    let Code = 'N/A',
      Description = 'N/A';

    if (material_type === 'Existing Material' || material_type === 'Remove Material') {
      Code = int_rfs_material_number ? int_rfs_material_number : 'N/A';

      Description = pack_mat_material_text ? pack_mat_material_text : 'N/A';
    } else if (material_type === 'New Material') {
      Code = new_material_code ? new_material_code : 'N/A';

      Description = new_material_description_intrfsml ? new_material_description_intrfsml : 'N/A';
    }

    return {
      'Material Code': Code,
      'Material Description': Description,
      Plant: plant,
      Status: status,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'Step Deadline': sla_date,
      'Step Finished': status === 'Closed' ? finished_date : status,
    };
  };

  const dataToExportL = ({
    plant,
    sla_date,
    status,
    manually_closed,
    finished_date,
    bom_changes,
    liquid_code,
    liquid_number,
    material_text,
    liquid_description,
    liquid_type,
    material_description,
  }) => {
    let Code = 'N/A',
      Description = 'N/A';

    if (bom_changes.includes(liquidBomChanges.thickness)) {
      Code = liquid_number;
      Description = material_text;
    }
    if (
      bom_changes.includes(liquidBomChanges.drawing) ||
      bom_changes.includes(liquidBomChanges.removal)
    ) {
      Code = liquid_code;
      Description = liquid_description;

      if (liquid_type === 'Add Liquid') {
        Code = liquid_code;
        Description = material_description;
      } else {
        Code = liquid_number;
        Description = liquid_description;
      }
    }

    return {
      'Material Code': Code,
      'Material Description': Description,
      Plant: plant,
      Status: status,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'Step Deadline': sla_date,
      'Step Finished': status === 'Closed' ? finished_date : status,
    };
  };

  return (
    <ContentPaper>
      <Row>
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            marginRight: '4rem',
          }}
        >
          <Button
            leftIcon={<TableExport />}
            onClick={() => {
              downloadExcel();
            }}
            color='primary'
            style={{
              marginBottom: '1rem',
              marginTop: '2rem',
              background: 'black',
            }}
          >
            Export to CSV
          </Button>
        </div>
        <Accordion value={state} onChange={handlers}>
          {Object.keys(statusData) &&
            Object.keys(statusData).map((name, index) => {
              return (
                Object.values(statusData)[index].length > 0 && (
                  <Accordion.Item
                    className='scope-label'
                    key={`key_${name}_${index}`}
                    value={`key_${name}_${index}`}
                  >
                    <Accordion.Control>{`${name}`}</Accordion.Control>
                    <Accordion.Panel>
                      <Grid>
                        <Grid.Col span={12}>
                          <MaterialsStatusChangeTableVEAM
                            statusDataList={Object.values(statusData)[index]}
                            type={name}
                            save={save}
                            accState={accState}
                            readOnly={!permissions['status_change'].includes(userRole)}
                          />
                        </Grid.Col>
                      </Grid>
                    </Accordion.Panel>
                  </Accordion.Item>
                )
              );
            })}
        </Accordion>
      </Row>
      {!disable && <AccfComment slaId={slaId} status={status} rfs_number={rfs_number} />}
    </ContentPaper>
  );
};

export default MaterialsStatusChange;
