import { Accordion, Button, Grid, Space } from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { Table } from './table';
import { AppLoader } from '../../../../components/common/loader';
import { ViewBom } from './viewBom';
import { Col, Row } from 'react-bootstrap';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { ProjectOverview } from '../projectOverview';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';
import {
  filterCommonSkus,
  filterUniqueSkus,
  getAccordionTitle,
  getSelectedPlants,
  makePayload,
} from './helper';

export const ReferenceSKU = ({ activeTab }) => {
  const params = useParams();
  const { info } = useContext(CostingRequestsContext);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [bomModal, setBomsModal] = useState({
    open: false,
    sku: null,
    plant: null,
  });
  const [selectedSkus, setSelectSkus] = useState({});
  const [selectedSkusPayload, setSkusPayload] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [infoLoading, setInfoLoading] = useState(false);

  const [referenceSkus, setReferenceSkus] = useState({});

  const { producing_plant_data = [], sku_data = [] } = info;

  useEffect(() => {
    if (activeTab === 'referenceSKUSelection' && sku_data.length && producing_plant_data.length) {
      fetchSavedSkus();
      fetch();
    }

    return () => {
      setSelectSkus({});
    };
  }, [activeTab, producing_plant_data, sku_data]);

  const fetchSavedSkus = async () => {
    try {
      setInfoLoading(true);
      const { data } = await axios.get(
        `/costing-request/step/selected-ref-sku-selection/${params.rfsNumber}`,
      );
      const plants = getSelectedPlants(data);

      setSkusPayload({
        ...plants,
        allSelected: data.length > 0,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setInfoLoading(false);
    }
  };

  const validate = () => {
    const error = {};
    Object.values(selectedSkusPayload || {}).forEach((item) => {
      if (Object.keys(item.selectedData || {}).length === 0 && item.production_plant_id)
        error[item.production_plant_id] = true;
    });

    return error;
  };

  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      const validationErrors = validate();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(errors);
      } else {
        const payload = makePayload(selectedSkusPayload);

        await axios.post(`/costing-request/step/ref-sku-selection/${params.rfsNumber}`, payload);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitLoading(false);
    }
  };

  const fetch = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/costing-request/step/get-ref-sku-selection/${params.rfsNumber}`,
      );
      const { recommanded, referenceSku } = data;

      const filteredSKus = filterUniqueSkus(referenceSku[0] || []);

      const skus = filterCommonSkus(recommanded[0] || [], filteredSKus);
      setReferenceSkus(skus);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleRowSelection = (data, plantId, sku_id) => {
    let currentValues = { ...selectedSkus };
    currentValues = { ...data };

    setSkusPayload({
      ...selectedSkusPayload,
      [plantId]: {
        ...selectedSkusPayload[plantId],
        sku_id: sku_id,
        production_plant_id: plantId,
        selectedData: currentValues,
      },
    });
    setSelectSkus(currentValues);
  };

  const Footer = () => (
    <Row className='justify-content-md-end' md={4}>
      <Col md='auto'>
        <Button
          className='next'
          onClick={handleSubmit}
          variant='gradient'
          radius='md'
          size='md'
          fullWidth
          disabled={
            loading ||
            submitLoading ||
            Object.keys(validate() || {}).length > 0 ||
            selectedSkusPayload?.allSelected
          }
          style={{
            marginRight: '1rem',
            width: '200px',
          }}
        >
          Submit
        </Button>
      </Col>
    </Row>
  );

  if (loading || infoLoading)
    return (
      <ContentPaper footer={<Footer />}>
        <ProjectOverview />
        <Space h='sm' />
        <AppLoader center size='md' />
      </ContentPaper>
    );

  return (
    <ContentPaper footer={<Footer />}>
      <ProjectOverview />
      <Space h='sm' />
      <div>
        <Accordion value={value} onChange={setValue}>
          {producing_plant_data.map(({ producing_plant, id }, index) => {
            const sku = sku_data[0] || null;
            if (!sku) return null;

            const recommendedSkus = (referenceSkus.recommanded || [])
              .filter((item) => item.plant === producing_plant)
              .map((item) => ({
                ...item,
                unique: `recommended_${item.sku}_${item.plant}`,
              }));

            const referenceSkusList = (referenceSkus.reference || []).map((item) => ({
              ...item,
              unique: `reference_${item.sku}_${item.plant}`,
            }));

            const skus = [...recommendedSkus, ...referenceSkusList];

            return (
              <Accordion.Item className='scope-label' key={index} value={`key_${index}`}>
                <Accordion.Control>{getAccordionTitle(sku, producing_plant)}</Accordion.Control>
                <Accordion.Panel>
                  <Grid>
                    <Grid.Col span={12}>
                      <Table
                        {...sku}
                        skuId={sku.id}
                        plantId={id}
                        skus={skus}
                        setBomsModal={setBomsModal}
                        handleRowSelection={handleRowSelection}
                        selectedSkus={selectedSkus}
                        selectedSkusDetails={selectedSkusPayload[id] || {}}
                        readOnly={selectedSkusPayload?.allSelected}
                      />
                    </Grid.Col>
                  </Grid>
                </Accordion.Panel>
              </Accordion.Item>
            );
          })}
        </Accordion>

        {bomModal.open && (
          <ViewBom
            bomModal={bomModal}
            handleClose={() => setBomsModal({ open: false, sku: null, plant: null })}
          />
        )}
      </div>
    </ContentPaper>
  );
};
