import { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, TextInput, Button } from '@mantine/core';
import MantineTable from '../../../../common/table/mantine';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { Switch } from '@mantine/core';
import { IconRefresh, IconTrash, IconX } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';
import { Accordion, Grid, Space } from '@mantine/core';
import { notifications } from '@mantine/notifications';

export const BudgetBomAdjustment = ({ setLogsModal, activeTab }) => {
  const { rfsNumber } = useParams();
  const [loading, setLoading] = useState(false);
  const [skus, setSkus] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [searchParams] = useSearchParams();
  const [switchState, setSwitchState] = useState([]);
  const [value, setValue] = useState('');
  const [uniqueID, setUniqueID] = useState([]);
  const [baseSkus, setBaseSkus] = useState([]);
  const [componentsDict, setComponentsDict] = useState({
    delete_component: [],
    update_component: [],
    add_component: [],
  });
  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab === 'BudgetBomAdjustment') {
      fetch();
    }
  }, [searchParams]);

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/costing-request/step/bom-adjustment-data/${rfsNumber}`);
      const apiData = response.data?.data;
      console.log(response.data);
      const uniqueIds = [
        ...new Map(
          apiData.map((item) => [
            item.pp_id,
            {
              ppID: item.pp_id,
              prod_plant: item.producingPlant.producing_plant,
              ...item.sku_details,
            },
          ]),
        ).values(),
      ];
      setUniqueID(uniqueIds);
      setBaseSkus(apiData);
    } catch (e) {
      console.log('error', e);
      handleNotification('Error', '', 'red');
    } finally {
      setLoading(false);
    }
  };

  const handleSwitch = (status) => {
    setSwitchState(status);
    if (status) {
      const mismatchedData = skus.filter((sku) => {
        return parseFloat(sku.quantity) !== parseFloat(sku.reference_bom?.[0]?.quantity);
      });
      setDisplayData(mismatchedData);
    } else {
      setDisplayData(skus);
    }
  };
  const handleNotification = (title, message, color) => {
    notifications.show({
      color: color,
      title: title,
      message: message,
    });
  };
  const hasMismatch = (row) => {
    return (
      row.requested?.quantity !== row.primary?.quantity ||
      row.requested?.scrap !== row.primary?.scrap ||
      row.requested?.RequestedSkuCostEstimation?.total_value !==
        row.primary?.PrimaryRefSkuCostEstimation?.total_value
    );
  };

  const handleSubmit = async () => {
    const payload = componentsDict;
    try {
      const res = await axios.post(`/costing-request/step/bom-updates/${rfsNumber}`, payload);

      if (res.data?.success) {
        handleNotification('Success', 'Request Submitted', 'green');
      }
    } catch (err) {
      console.log('bom adjustment page submit', err.response);
      handleNotification('Error', 'Request not Submitted', 'red');
    } finally {
      // add the final action either to take on next page or success modal;
    }
  };

  const handleAccordionChange = (prodPlantId) => {
    const filteredSkus = baseSkus.filter((item) => item.pp_id === prodPlantId);
    setSkus(filteredSkus);
    setDisplayData(filteredSkus);
  };
  const handleInputChange = (component, field, value, componentToUpdate) => {
    const updateSkuData = (data) => {
      return data.map((sku) => {
        if (sku.component === component) {
          if (field.includes('.')) {
            const fields = field.split('.');
            return {
              ...sku,
              [fields[0]]: {
                ...sku[fields[0]],
                [fields[1]]: value,
              },
            };
          }
          return {
            ...sku,
            [field]: value,
          };
        }
        return sku;
      });
    };

    setSkus((prevSkus) => [...updateSkuData(prevSkus)]);
    setDisplayData((prevDisplayData) => [...updateSkuData(prevDisplayData)]);
    setBaseSkus((prevDisplayData) => [...updateSkuData(prevDisplayData)]);
    console.log('.......', displayData);
    setComponentsDict((prevDict) => {
      const updatedComponent = { ...componentToUpdate, [field]: value };
      return {
        ...prevDict,
        update_component: [
          ...prevDict.update_component.filter((comp) => comp.component !== component),
          updatedComponent,
        ],
      };
    });
  };

  const handleMaterialRemoval = (component, data) => {
    // Update displayData
    setDisplayData((prevDisplayData) => {
      const updatedDisplayData = prevDisplayData.filter(
        (item) => item.component_id.toString().trim() !== component.toString().trim(),
      );
      setSkus((prevSkus) =>
        prevSkus.filter(
          (item) => item.component_id.toString().trim() !== component.toString().trim(),
        ),
      );
      setBaseSkus((prevBaseSkus) =>
        prevBaseSkus.filter(
          (item) => item.component_id.toString().trim() !== component.toString().trim(),
        ),
      );

      console.log('Updated displayData:', updatedDisplayData);
      console.log('Updated skus:', updatedDisplayData);
      console.log('Updated baseSkus:', updatedDisplayData);

      return updatedDisplayData;
    });
    setComponentsDict((prevDict) => ({
      ...prevDict,
      delete_component: [...prevDict.delete_component, data],
    }));
  };

  const handleAutoAdjustment = () => {
    setDisplayData((prevData) =>
      prevData.map((ele) => {
        const updatedEle = { ...ele };

        if (ele.reference_bom?.[0]?.quantity) {
          updatedEle.quantity = ele.reference_bom[0].quantity;
        }
        if (ele.reference_bom?.[0]?.scrap) {
          updatedEle.scrap = ele.reference_bom[0].scrap;
        }
        if (ele.reference_bom?.[0]?.PrimaryRefSkuCostEstimation?.total_value) {
          updatedEle.RequestedSkuCostEstimation = {
            ...updatedEle.RequestedSkuCostEstimation,
            total_value: ele.reference_bom[0].PrimaryRefSkuCostEstimation.total_value,
          };
        }
        setComponentsDict((prevDict) => {
          const updatedComponent = {
            ...updatedEle,
          };
          return {
            ...prevDict,
            update_component: [
              ...prevDict.update_component.filter(
                (comp) => comp.component !== updatedEle.component,
              ),
              updatedComponent,
            ],
          };
        });

        return updatedEle;
      }),
    );
  };

  const handleRefresh = async (ppID) => {
    try {
      const res = await axios.get(`/costing-request/step/refresh-bom-adjustment/${rfsNumber}`);
      if (res?.data?.message === 'refreshed') {
        const response = await axios.get(`/costing-request/step/bom-adjustment-data/${rfsNumber}`);
        const apiData = response.data?.data;
        setBaseSkus(apiData);

        const updatedSkus = apiData.filter((item) => item.pp_id === ppID);
        setSkus(updatedSkus);
        setDisplayData(updatedSkus);
        handleNotification('Success', 'Data Refreshed', 'green');
      }
    } catch (e) {
      console.log('err', e);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'component',
        header: 'Material Number',
      },
      {
        accessorKey: 'component_description',
        header: 'Material Description',
      },
      {
        accessorKey: 'item_category',
        header: 'ICt',
      },
      {
        accessorKey: 'component_unit',
        header: 'UOM',
      },
      {
        accessorFn: (row) => row,
        header: 'Quantity',
        Cell: ({ row }) => {
          let isDifferent;
          if (row.original.reference_bom?.[0]?.quantity) {
            isDifferent =
              parseFloat(row.original?.quantity) !==
              parseFloat(row.original.reference_bom?.[0]?.quantity);
          } else {
            isDifferent = false;
          }
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>Requested: </span>
                <TextInput
                  value={parseFloat(row.original?.quantity ?? '')}
                  onChange={(e) =>
                    handleInputChange(
                      row.original?.component,
                      'quantity',
                      e.target.value,
                      row.original,
                    )
                  }
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '8px' }}>Reference: </span>
                <TextInput
                  disabled
                  value={row.original.reference_bom?.[0]?.quantity ?? ''}
                  styles={(theme) => ({
                    input: {
                      borderColor: isDifferent ? 'red' : theme.colors.gray[4],
                      borderWidth: '1px',
                    },
                  })}
                />
              </div>
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row,
        header: 'Costing Relevancy',
        Cell: ({ row }) => (
          <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <span style={{ marginRight: '8px' }}>Requested:</span>
              <Checkbox
                checked={row.original?.costing_relevancy ?? true}
                onChange={(e) =>
                  handleInputChange(
                    row.original?.component,
                    'costing_relevancy',
                    e.currentTarget.checked,
                    row.original,
                  )
                }
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <span style={{ marginRight: '8px' }}>Reference:</span>
              <Checkbox checked={row.original?.reference_bom?.[0]?.costing_relevancy} disabled />
            </div>
          </div>
        ),
      },
      {
        accessorFn: (row) => row,
        header: 'Scrap',
        Cell: ({ row }) => {
          const isDifferent = row.original?.reference_bom?.[0]?.scrap
            ? row.original?.scrap !== row.original?.reference_bom?.[0]?.scrap
            : false;
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>Requested:</span>
                <TextInput
                  value={row.original?.RequestedSkuCostEstimation?.scrap ?? ''}
                  onChange={(e) =>
                    handleInputChange(
                      row.original?.component,
                      'RequestedSkuCostEstimation.scrap',
                      e.target.value,
                      row.original,
                    )
                  }
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '8px' }}>Reference:</span>
                <TextInput
                  disabled
                  value={row.original?.reference_bom?.[0]?.PrimaryRefSkuCostEstimation?.scrap ?? ''}
                  styles={(theme) => ({
                    input: {
                      borderColor: isDifferent ? 'red' : theme.colors.gray[4],
                      borderWidth: '1px',
                    },
                  })}
                />
              </div>
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row,
        header: 'Total Value',
        Cell: ({ row }) => {
          const isDifferent = row.original?.reference_bom?.[0]?.PrimaryRefSkuCostEstimation
            ?.total_value
            ? parseFloat(row.original?.RequestedSkuCostEstimation?.total_value ?? 0) !==
              parseFloat(row.original?.reference_bom?.[0]?.PrimaryRefSkuCostEstimation?.total_value)
            : false;

          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>Requested:</span>
                <TextInput
                  value={row.original?.RequestedSkuCostEstimation?.total_value ?? ''}
                  onChange={(e) =>
                    handleInputChange(
                      row.original?.component,
                      'RequestedSkuCostEstimation.total_value',
                      e.target.value,
                      row.original,
                    )
                  }
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '8px' }}>Reference:</span>
                <TextInput
                  disabled
                  value={
                    row.original?.reference_bom?.[0]?.PrimaryRefSkuCostEstimation?.total_value ?? ''
                  }
                  styles={(theme) => ({
                    input: {
                      borderColor: isDifferent ? 'red' : theme.colors.gray[4],
                      borderWidth: '1px',
                    },
                  })}
                />
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: 'currency',
        header: 'Currency',
        Cell: ({ row }) => <span>{row.original?.currency}</span>,
      },
      {
        accessorKey: 'price_unit',
        header: 'Price Value',
        Cell: ({ row }) => <span>{row.original?.price_unit}</span>,
      },
      {
        accessorKey: 'delete',
        header: 'Delete',
        Cell: ({ row }) => (
          <Button
            color='dark'
            size='md'
            onClick={(e) => {
              e.stopPropagation();
              handleMaterialRemoval(row.original?.component_id, row.original);
            }}
          >
            <IconTrash size={16} />
          </Button>
        ),
      },
    ],
    [displayData],
  );

  return (
    <>
      <Space h='sm' />
      <div>
        <Accordion value={value} onChange={setValue}>
          {uniqueID.map((sku, index) => (
            <Accordion.Item
              className='scope-label'
              key={`${sku.component || index}`}
              value={`key_${index}`}
              onClick={() => handleAccordionChange(sku.ppID)}
            >
              <Accordion.Control>{`${sku?.sku} | ${sku?.sku_description} | ${sku.prod_plant}`}</Accordion.Control>
              <Accordion.Panel>
                <Grid>
                  <Grid.Col span={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '16px',
                        marginTop: '10px',
                      }}
                    >
                      <Switch
                        label='View Flagged Data Only'
                        size='md'
                        onChange={(e) => handleSwitch(e.target.checked)}
                      />
                      <div style={{ display: 'flex', gap: '8px' }}>
                        <Button
                          variant='light'
                          color='dark'
                          onClick={() => handleRefresh(sku.ppID)}
                        >
                          <IconRefresh size={16} />
                        </Button>
                        <Button variant='light' color='dark' onClick={() => handleAutoAdjustment()}>
                          Auto Adjustment of BOM
                        </Button>
                      </div>
                    </div>
                    <div className='flex' style={{ marginTop: '5px' }}>
                      <MantineTable
                        columns={columns}
                        initialData={displayData}
                        enableRowSelection={false}
                        showSelectedToggle={false}
                        hideSelectColumn={true}
                        showResetAll={false}
                        pageSize={10}
                        enableColumnFilter={false}
                        enableFilters={false}
                        columnFilterDisplayMode={false}
                        loading={false}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '5px',
                        marginTop: '10px',
                      }}
                    >
                      <Button variant='light' color='dark' onClick={() => handleSubmit()}>
                        Submit
                      </Button>
                    </div>
                  </Grid.Col>
                </Grid>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </>
  );
};
