import { useContext, useMemo, useState } from 'react';
import MantineTable from '../../../../common/table/mantine';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';
import { Button, Grid } from '@mantine/core';

export const skuColumns = [
  {
    accessorKey: 'sku',
    header: 'SKU ID',
  },
  {
    accessorKey: 'sku_description',
    header: 'SKU Description',
  },
  {
    accessorKey: 'brand',
    header: 'Brand',
  },
  {
    accessorKey: 'sub_brand',
    header: 'Sub Brand',
  },
  {
    accessorKey: 'returnability',
    header: 'Returnability',
  },
  {
    accessorKey: 'container',
    header: 'Container',
  },
  {
    accessorKey: 'container_size',
    header: 'Container size',
    Cell: ({ row }) =>
      row.original.container_vol ? row.original.container_vol : row.original.container_size,
  },
];

export const SkuTable = ({ onSubmit, active = {} }) => {
  const { skus } = useContext(CostingRequestsContext);
  const [activeMaterials, setActiveMaterials] = useState(active);

  let columns = useMemo(() => skuColumns, [skus]);

  const handleRowSelection = (data) => {
    let currentValues = { ...activeMaterials };
    currentValues = { ...data };

    setActiveMaterials(currentValues);
  };

  return (
    <>
      <MantineTable
        columns={columns}
        initialData={skus}
        unique={'sku'}
        handleRowSelection={handleRowSelection}
        activeRows={activeMaterials}
        enableRowSelection={true}
        loading={false}
        hideSelectColumn={false}
        showResetAll={false}
        fullScreen={false}
        multiRowSelection={false}
        showSelectedToggle={true}
        mantinePaperProps={{
          sx: {
            border: 'none !important',
            boxShadow: 'none',
          },
        }}
      />

      <Grid justify='flex-end' style={{ marginTop: '16px' }}>
        <Grid.Col span={2}>
          <Button color='dark' fullWidth onClick={() => onSubmit(activeMaterials)}>
            Confirm
          </Button>
        </Grid.Col>
      </Grid>
    </>
  );
};
