import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, Skeleton, Tabs } from '@mantine/core';
import { FaUserPlus, FaUserMinus } from 'react-icons/fa';
import { axios } from '../../../utils/axios';
import { AuthContext } from '../../../contexts/AuthContext';
import withConfirm from '../../../components/common/confirm-dialog';
import { tabStyles } from '../../../utils/helpers';
import { useSearchParams } from 'react-router-dom';


const roles = [
  { value: 'scheduling', label: 'Scheduling' },
  { value: 'req', label: 'Requestor' },
  { value: 'standard/transfer_pricing', label: 'GCC FP&A' },
  { value: 'user_admin', label: 'User Admin' },
  { value: 'masterdata', label: 'MDM Spoc' },
  { value: 'fpna_brewery_manager', label: 'FPnA Brewery Manager' },
  { value: 'procurement', label: 'Procurement' },
  { value: 'repack', label: 'Repack' },
  { value: 'fpna_brewery_heads', label: 'FPnA Brewery Heads' },
];

const EditModal = ({ state, close, confirm }) => {
  const [loading, setLoading] = useState(false); 
  const { loggedIn } = useContext(AuthContext);
  const loggedInUserId = loggedIn ? loggedIn : null;
  const [formData, setFormData] = useState({
    addAccess: {
      role: '',
      country: '',
      producingPlant: '',
      typeOfRequest: '',
      typeOfSKU: '',
    },
    revokeAccess: {
      user_id: '',
      roleMappingId: '',
      role: '',
      country: '',
      producingPlant: '',
      typeOfRequest: '',
      typeOfSKU: '',
    },
  });
 
  const [options, setOptions] = useState({
    roles: [],
    countries: [],
    plantsByCountry: {},
    typeOfRequests: ['Costing', 'Transfer Pricing', 'Both'],
    typeOfSKUs: [],
  });

  const [formOpen, setFormOpen] = useState(true);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = (roleAlias) => {
    setLoading(true); 
  
    let endpoint = '/user/v2/options';
    if (roleAlias) {
      endpoint += `?role=${roleAlias}`;
    }
  
    axios
      .get(endpoint)
      .then((response) => {
        if (response.data.success) {
          const { data, zone_country_plant, skus } = response.data;
  
          setOptions((prevOptions) => ({
            ...prevOptions,
            roles: data.roles || [],
            countries: zone_country_plant ? Object.keys(zone_country_plant.EUROPE || {}) : [],
            plantsByCountry: zone_country_plant ? zone_country_plant.EUROPE || {} : {},
            typeOfSKUs: skus || [],
            typeOfRequests: prevOptions.typeOfRequests,
          }));
        } else {
          console.error('Error fetching options:', response.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching options:', error);
      })
      .finally(() => {
        setLoading(false); 
      });
  };
  
  const handleChange = (event, section, field) => {
    const value = event.target.value;

    setFormData((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [field]: value,
      },
    }));

    if (field === 'role') {
      fetchOptions(value);
    }
  };
  const [selector, setSelector] = useState({ userId: null });

  const handleUserSelection = (userId) => {
    setSelector({ userId });
  };
  const handleSaveChanges = async () => {
    try {
      if (formData.addAccess.role) {
        await handleAddAccess(selector.userId);
      }
      if (formData.revokeAccess.role) {
        await handleRevokeAccess(selector.userId);
      }
      close();
    } catch (error) {
      console.error('Error saving changes:', error);
      alert('An error occurred while saving changes.');
    }
  };

  const handleAddAccess = async (userId) => {
    setLoading(true)
    try {
      const activeUser = userId || state.user.id;

      const payload = {
        zone: 'EUR',
        role_requested: formData.addAccess.role,
        plant: formData.addAccess.producingPlant || null,
        country: formData.addAccess.country || null,
        type_of_sku: formData.addAccess.typeOfSKU || null,
        type_of_request: formData.addAccess.typeOfRequest || null,
        justification: formData.addAccess.justification || '',
        status: 'Pending',
      };

      const response = await axios.post(`/user/v2/access/${activeUser}`, payload, {});
      if (response.data.success) {
        setFormData((prevState) => ({
          ...prevState,
          addAccess: {
            role: '',
            country: '',
            producingPlant: '',
            typeOfRequest: '',
            typeOfSKU: '',
          },
        }));
        fetchOptions();
      }
    } catch (error) {
      console.error('Error adding access:', error);
      alert('An error occurred while adding access.');
    } finally {
      setLoading(false); 
    }
  };

  const handleRevokeAccess = async (userId) => {
    try {
      const { userId, role_mapping_id } = formData.revokeAccess;
      const revokeUser = userId || state.user.id;

      const response = await axios.delete(`/user/v2/access/${revokeUser}/${role_mapping_id}`, {
      });
      if (response.data.success) {
        setFormData((prevState) => ({
          ...prevState,
          revokeAccess: {
            userId: '',
            role_mapping_id: '',
            role: '',
            country: '',
            producingPlant: '',
            typeOfRequest: '',
            typeOfSKU: '',
          },
        }));
        fetchOptions();
      } else {
        alert('Failed to revoke access. Please try again.');
      }
    } catch (error) {
      console.error('Error revoking access:', error);
    }
  };

  const handleDelete = async (userId) => {
    setLoading(true);  
    try {
      await axios.delete(`/user/v2/${userId}`, {
        params: {
          force: false,
        },
      });
    } catch (error) {
      if (error.response) {
        console.error('Server responded with an error:', error.response.data);
        alert(
          'Error: ' + (error.response.data.message || 'An error occurred while deleting the user.'),
        );
      } else if (error.request) {
        console.error('No response received:', error.request);
        alert('No response from the server. Please try again.');
      } else {
        console.error('Error in setting up request:', error.message);
        alert('Error: ' + error.message);
      }
    } finally {
      setLoading(false);  
    }
  };

  const renderFormSection = (section) => {
    const sectionData = formData[section];
    const { role, country } = sectionData;
    const countries = options.countries;
    const plants = options.plantsByCountry[country] || [];

    const hasDropdownAccess = (role, dropdown) => {
      switch (role) {
        case 'scheduling':
          return dropdown === 'country';
        case 'req':
          return dropdown === '';
        case 'standard/transfer_pricing':
          return (
            dropdown === 'country' ||
            dropdown === 'producingPlant' ||
            dropdown === 'typeOfSKU' ||
            dropdown === 'typeOfRequest'
          );
        case 'user_admin':
          return dropdown === '';
        case 'masterdata':
          return dropdown === 'country';
        case 'procurement':
          return dropdown === 'country' ;
            
        case 'fpna_brewery_manager':
          return (
            dropdown === 'country' ||
            dropdown === 'producingPlant' 
          );
        case 'fpna_brewery_heads':
          return (
            dropdown === 'country' ||
            dropdown === 'producingPlant' ||
            dropdown === 'typeOfSKU' 
          );
        case 'repack':
          return (
            dropdown === 'country' 
            ); 
        default:
          return false;
      }
    };

    return (
      <>
        <div className='form-group' style={{ marginBottom: '10px', display: 'inline-block', marginRight: '10px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>Select Role</label>
          <select
            value={role || ''}
            onChange={(e) => handleChange(e, section, 'role')}
            style={{ width: '250px', padding: '8px', borderRadius: '5px' }}
          >
            <option value=''>Select Role</option>
            {roles.map((role, index) => (
              <option key={index} value={role.value}>
                {role.label}
              </option>
            ))}
          </select>
        </div>
        {hasDropdownAccess(role, 'country') && (
          <div
            className='form-group'
            style={{ marginBottom: '10px', display: 'inline-block', marginRight: '10px' }}
          >
            <label style={{ display: 'block', marginBottom: '5px' }}>Select Country</label>
            <Skeleton visible={loading}>        
            <select       
              value={country || ''}
              onChange={(e) => handleChange(e, section, 'country')}
              style={{ width: '250px', padding: '8px', borderRadius: '5px' }}
            >
              <option value=''>Select Country</option>
              {countries.map((countryName, index) => (
                <option key={index} value={countryName}>
                  {countryName}
                </option>
              ))}
            </select>
            </Skeleton>
            
          </div>
        )}
        {hasDropdownAccess(role, 'producingPlant') && country && (
          <div
            className='form-group'
            style={{ marginBottom: '10px', display: 'inline-block', marginRight: '10px' }}
          >
            <label style={{ display: 'block', marginBottom: '5px' }}>Select Producing Plant</label>
            <select
              value={sectionData.producingPlant || ''}
              onChange={(e) => handleChange(e, section, 'producingPlant')}
              style={{ width: '250px', padding: '8px', borderRadius: '5px' }}
            >
              <option value=''>Select Plant</option>
              {plants.map((plant, index) => (
                <option key={index} value={plant}>
                  {plant}
                </option>
              ))}
            </select>
          </div>
        )}
        {hasDropdownAccess(role, 'typeOfSKU') && (
          <div
            className='form-group'
            style={{ marginBottom: '10px', display: 'inline-block', marginRight: '10px' }}
          >
            <label style={{ display: 'block', marginBottom: '5px' }}>Select Type of SKU</label>
            <select
              value={sectionData.typeOfSKU || ''}
              onChange={(e) => handleChange(e, section, 'typeOfSKU')}
              style={{ width: '250px', padding: '8px', borderRadius: '5px' }}
            >
              <option value=''>Select SKU</option>
              {options.typeOfSKUs.map((sku, index) => (
                <option key={index} value={sku.toLowerCase()}>
                  {sku}
                </option>
              ))}
            </select>
          </div>
        )}
        {hasDropdownAccess(role, 'typeOfRequest') && (
          <div
            className='form-group'
            style={{ marginBottom: '10px', display: 'inline-block', marginRight: '10px' }}
          >
            <label style={{ display: 'block', marginBottom: '5px' }}>Select Type of Request</label>
            <select
              value={sectionData.typeOfRequest || ''}
              onChange={(e) => handleChange(e, section, 'typeOfRequest')}
              style={{ width: '250px', padding: '8px', borderRadius: '5px' }}
            >
              <option value=''>Select Request</option>
              {options.typeOfRequests.map((request, index) => (
                <option key={index} value={request}>
                  {request}
                </option>
              ))}
            </select>
          </div>
        )}
      </>
    );
  };

  const handleClose = () => {
    setFormOpen(false);
  };

  if (!formOpen) {
    return null;
  }

  return (
    <Modal
      opened={state.open}
      onClose={() => close()}
      title={<span style={{ fontWeight: 'bold', fontSize: '24px', color: '#e5b611' }}>Admin</span>}
      centered
      size='lg'
    >
      <div style={{ maxWidth: '600px', margin: 'auto', padding: '20px', borderRadius: '5px' }}>
        <div style={{ marginBottom: '20px' }}>
          <div style={{ fontWeight: 'bold', marginBottom: '10px', fontSize: '14px' }}>
            Manage your access below by requesting for more access or revoke the existing
          </div>
        </div>
        <Tabs
          variant='unstyled'
          styles={tabStyles}
          color='primary'
          defaultValue={'addAccess'}
        >
          <Tabs.List>
            <Tabs.Tab value='addAccess'>
                <FaUserPlus style={{ marginRight: '10px' }} />
                Add Access
            </Tabs.Tab>
            <Tabs.Tab value='revokeAccess'>
              <FaUserMinus style={{ marginRight: '10px' }} />
              Revoke Access
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value='addAccess'>
            <div style={{ marginBottom: '20px', marginTop: '20px' }}>
              {renderFormSection('addAccess')}
            </div>
          </Tabs.Panel>

          <Tabs.Panel value='revokeAccess'>
            <div style={{ marginBottom: '20px', marginTop: '20px' }}>
              {renderFormSection('revokeAccess')}
            </div>
          </Tabs.Panel>
        </Tabs>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button
            variant='gradient'
            color='primary'
            style={{
              marginRight: '10px',
            }}
            onClick={handleSaveChanges}
          >
            Save Changes
          </Button>

          <Button
            color='red'
            onClick={() =>
              confirm(() => handleDelete(state.user.id), {
                title: 'Confirm!',
                description: 'Are you sure you want to delete user',
                confirmationText: 'Confirm',
              })
            }
          >
            Delete User   
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default withConfirm(EditModal);
