import { useEffect, useMemo, useState } from 'react';
import MantineTable from '../../common/table/mantine';
import { Tooltip, ActionIcon, Flex, Button, useMantineTheme, CloseButton } from '@mantine/core';
import { IconEdit, IconTrash, IconUserCheck } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { axios } from '../../utils/axios';
import { UserAccessPreview } from './UserAccessPreview';
import styled from '@emotion/styled';
import withConfirm from '../common/confirm-dialog';
import { AuthContext } from '../../contexts/AuthContext';
import { useContext } from 'react';

const Link = styled.span`
  color: #e5b611;
  cursor: pointer;
`;
const ScrollableContainer = styled.div`
  height: 400px; /* Adjust this value as needed */
  overflow-y: auto;
`;
const UserAccess = ({ userId, confirm }) => {
  const { userName, userEmail } = useContext(AuthContext);
  const [previewModal, setPreviewModal] = useState({ open: false, user: null });
  const [manageAccessOpened, { open: openManageAccess, close: closeManageAccess }] =
    useDisclosure(false);

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUserData();
  }, [userId]);

  const fetchUserData = async () => {
    try {
      const response = await axios.get('/user/v2/fetch', {
        params: { user_id: userId },
      });
      const formattedData = response.data.data
        .map((user) => {
          return user.int_user_role_mappings
            .filter(({ is_active }) => is_active)
            .map((roleMapping) => ({
              id: user.id,
              email: user.email,
              roleMappingId: roleMapping.id,
              existing_roles: roleMapping.int_role?.name || 'N/A',
              plant: roleMapping.plant || 'N/A',
              country: roleMapping.country || 'N/A',
              type_of_sku: roleMapping.type_of_sku || 'N/A',
              type_of_request: roleMapping.type_of_request || 'N/A',
            }));
        })
        .flat();

      setUsers(formattedData);
    } catch (error) {
      console.error('Error loading user data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAction = async (user_id, roleMappingId, action = 'Revoke') => {
    setLoading(true)
    try {
      if (action === 'Revoke') {
        await axios.delete(`/user/v2/access/${user_id}/${roleMappingId}`);
        fetchUserData();
      }
    } catch (error) {
      console.error('Error revoking access:', error);
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        header: 'Revoke Access',
        id: 'actions',
        enableColumnFilter: false,
        enableColumnSorting: false,
        enableColumnActions: false,
        size: 80,
        minSize: 80,
        maxSize: 120,
        Cell: ({ row }) => {
          const userId = row.original.id;
          const roleMappingId = row.original.roleMappingId;
          return (
            <Flex gap='md'>
              <Tooltip
                label='Revoke Access'
                onClick={() =>
                  confirm(() => handleAction(userId, roleMappingId), {
                    title: 'Confirm!',
                    description: 'Are you sure you want to revoke access for this user?',
                    confirmationText: 'Confirm',
                  })
                }
              >
                <ActionIcon color='red'>
                  <IconTrash />
                </ActionIcon>
              </Tooltip>
            </Flex>
          );
        },
      },

      {
        header: 'Role',
        accessorKey: 'existing_roles',
        enableEditing: false,
      },
      {
        header: 'Country',
        accessorKey: 'country',
        enableEditing: true,
      },
      {
        header: 'Producing Plant',
        accessorKey: 'plant',
        enableEditing: true,
      },
      {
        header: 'Type Of SKU',
        accessorKey: 'type_of_sku',
        enableEditing: false,
      },
      {
        header: 'Type Of Request',
        accessorKey: 'type_of_request',
        enableEditing: false,
      },
    ],
    [users],
  );

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
        <Button
          onClick={() => {
            setPreviewModal({ open: true, user: { name: userName, email: userEmail } });
            openManageAccess();
          }}
          color='dark'
        >
          Request New Access
        </Button>
      </div>{' '}
      <ScrollableContainer>
        <MantineTable
          columns={columns}
          initialData={users}
          unique={'id'}
          enableRowSelection={false}
          loading={loading}
          showSelectedToggle={false}
          hideSelectColumn={true}
          showResetAll={false}
          csvFilename={`pending-users`}
          pageSize={10}
          mantinePaperProps={{
            sx: {
              border: 'none !important',
              boxShadow: 'none',
            },
          }}
        />
      </ScrollableContainer>
      {previewModal.open && <UserAccessPreview state={previewModal} setState={setPreviewModal} />}
    </>
  );
};

export default withConfirm(UserAccess);
