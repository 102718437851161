import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import RfsLayout from '../layouts/RfsLayout';
import UserAccess from './UserAccess';
import ContentPaper from '../layouts/rfs/Content';

const ProfilePage = () => {
  const { userName, userEmail, userRole, profilePhoto } = useContext(AuthContext);
  const [profilePhotoURL, setProfilePhotoURL] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getInitials = (name) => {
    if (name?.includes(' ')) {
      const fullName = name.split(' ');
      const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      return initials;
    } else {
      return name?.charAt(0);
    }
  };

  useEffect(() => {
    if (profilePhoto) {
      const objectURL = URL.createObjectURL(profilePhoto);
      setProfilePhotoURL(objectURL);

      return () => {
        URL.revokeObjectURL(objectURL);
      };
    }
  }, [profilePhoto]);

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <RfsLayout>
      <ContentPaper footer={null}>
        <div
          style={{
            padding: '10px', 
            margin: '20px',
            borderRadius: '8px',
            maxHeight: 'calc(100vh - 40px)',
            overflowY: 'auto',
          }}
        >
          <h3>User Profile</h3>

          {profilePhotoURL ? (
            <img
              src={profilePhotoURL}
              alt={`${userName}'s profile`}
              style={{
                display: 'block',
                margin: '10px 0',
                width: '100px',
                height: '100px',
                borderRadius: '50%',
                objectFit: 'cover',
                cursor: 'pointer', 
              }}
              onClick={handleImageClick} 
            />
          ) : (
            <div
              style={{
                display: 'block',
                margin: '20px 0',
                width: '100px',
                height: '100px',
                borderRadius: '50%',
                backgroundColor: 'rgba(255, 249, 219, 1)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '32px',
                fontWeight: 'bold',
                color: '#e5b611',
              }}
            >
              {getInitials(userName)}
            </div>
          )}

          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
              <label htmlFor="userName" style={{ marginBottom: '5px' }}>
                <strong>Name</strong>
              </label>
              <input
                id="userName"
                type="text"
                value={userName}
                readOnly
                style={{
                  width: '300px',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                }}
              />
            </span>
            <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
              <label htmlFor="userEmail" style={{ marginBottom: '5px' }}>
                <strong>Email</strong>
              </label>
              <input
                id="userEmail"
                type="text"
                value={userEmail}
                readOnly
                style={{
                  width: '300px',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                }}
              />
            </span>
          </div>
        </div>
        <UserAccess />
      </ContentPaper>

      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000, 
          }}
          onClick={closeModal} 
        >
          <div
            style={{
              position: 'relative',
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '10px',
              boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)',
            }}
            onClick={(e) => e.stopPropagation()} 
          >
            <img
              src={profilePhotoURL}
              alt={`${userName}'s enlarged profile`}
              style={{ maxWidth: '500px', maxHeight: '500px', borderRadius: '10px' }}
            />
            <button
              onClick={closeModal}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'transparent',
                border: 'none',
                fontSize: '20px',
                cursor: 'pointer',
                color: '#000',
              }}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </RfsLayout>
  );
};

export default ProfilePage;
