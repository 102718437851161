import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, Accordion } from '@mantine/core';
import { TableExport } from 'tabler-icons-react';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { RfsContext } from '../../../../contexts/RfsContext';
import { AppLoader } from '../../../../components/common/loader';
import { SupplierTable } from './table';
import { axios } from '../../../../utils/axios';
import { ProjectOverviewContext } from '../../../../contexts/projectOverviewContext';
import checkClosedOrInProgress from '../../veamHelper';
import csvDownload from 'json-to-csv-export';

function Supplier({ checkClosuresSupplier, readOnly }) {
  const { form } = useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;
  const [loading, setLoading] = useState(false);
  const [supplierName, setSupplierName] = useState([]);
  const [supplierNameN, setSupplierNameN] = useState([]);
  const { getVendors, vendors } = useContext(ProjectOverviewContext);
  const [value, setValue] = useState(0);

  useEffect(() => {
    checkClosuresSupplier(checkClosedOrInProgress(supplierNameN));
  }, [supplierNameN]);

  useEffect(() => {
    getData(rfs_number);
    getVendors();
  }, []);

  const getData = (rfs_number, loading = true) => {
    setLoading(loading);
    axios
      .get(`/rfs/suppliers/${rfs_number}`)
      .then(
        (res) => {
          setSupplierNameN(res.data.suppliers);
          let arr = [];

          arr['Materials'] = res.data.suppliers.filter((item) => item.int_rfs_material !== null);
          arr['Liquids'] = res.data.suppliers.filter((item) => item.int_rfs_liquid !== null);

          setSupplierName(arr);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));
  };

  const handleAltNumberChange = (id, name, value) => {
    let currentValues = [...supplierName];
    const index = currentValues.findIndex((i) => i.id === id);
    currentValues[index]['int_rfs_impl_supplier'][name] = value;
    setSupplierName(currentValues);
  };

  //----CSV download-----------------------------
  const downloadExcel = (type) => {
    const dataToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };

    const materialsToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };

    const Headers = [
      'Pack Mat Number',
      'Pack Mat Description',
      'Existing Suppliers',
      'Add Supplier',
      'New Supplier Number',
      'New Supplier Name',
      'Status',
      'Step Deadline',
      'Step Finished',
    ];

    const liquids = supplierName.Liquids;
    const materials = supplierName.Materials;

    if ((liquids || []).length > 0) {
      dataToExport.data = getDataToExport(liquids, 'liquids');

      dataToExport.filename = 'Supplier_Name_Liquids' + moment().format('YYYY-MM-DD HH:mm:ss');
      dataToExport.headers = Headers;
      csvDownload(dataToExport);
    }

    if ((materials || []).length > 0) {
      materialsToExport.data = getDataToExport(materials);
      materialsToExport.filename =
        'Supplier_Name_Materials' + moment().format('YYYY-MM-DD HH:mm:ss');
      materialsToExport.headers = Headers;
      csvDownload(materialsToExport);
    }
  };

  const getDataToExport = (data, type) => {
    const csvArray = data.map((val) => {
      if (type === 'liquids') {
        val.mat_code = val?.int_rfs_impl_material_creation?.new_material_code || 'N/A';
        val.mat_desc = val?.int_rfs_impl_material_creation?.new_material_desc || 'N/A';
      }
      return dataToExport(val);
    });
    return csvArray;
  };

  const dataToExport = ({
    mat_code,
    mat_desc,
    supplier,
    int_rfs_impl_supplier,
    status,
    sla_date,
    scope_id,
    finished_date,
  }) => {
    return {
      'Pack Mat Number': mat_code ? mat_code : 'N/A',
      'Pack Mat Description': mat_desc ? mat_desc : 'N/A',
      'Existing Suppliers': supplier ? supplier : 'N/A',
      'Add Supplier': int_rfs_impl_supplier?.add_supplier
        ? int_rfs_impl_supplier.add_supplier
        : 'N/A',
      'New Supplier Number': int_rfs_impl_supplier?.new_supplier_number
        ? int_rfs_impl_supplier.new_supplier_number
        : 'N/A',
      'New Supplier Name': int_rfs_impl_supplier?.new_supplier_name
        ? int_rfs_impl_supplier.new_supplier_name
        : 'N/A',
      Status: status,
      'Step deadline': sla_date,
      'Step finish': finished_date === null ? status : finished_date,
    };
  };
  //----CSV download-----------------------------//

  if (loading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  return (
    <Row>
      <Box
        sx={(theme) => ({
          backgroundColor: 'black',
          textAlign: 'center',
          padding: theme.spacing.xs,
          borderRadius: theme.radius.md,
          cursor: 'pointer',

          color: theme.colors.yellow[5],
          fontSize: theme.fontSizes.lg,
          fontWeight: 1000,

          '&:hover': {
            backgroundColor: 'black',
          },
        })}
      >
        MATERIAL CODES
      </Box>
      {
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            marginRight: '4rem',
          }}
        >
          <Button
            leftIcon={<TableExport />}
            onClick={() => {
              downloadExcel();
            }}
            color='primary'
            style={{
              marginBottom: '1rem',
              marginTop: '2rem',
              background: 'black',
            }}
          >
            Export to CSV
          </Button>
        </div>
      }
      <Accordion value={value} onChange={setValue}>
        {Object.keys(supplierName).map((name, index) => {
          return (
            supplierName[name].length > 0 && (
              <Accordion.Item className='scope-label' key={`key_${index}`} value={`key_${index}`}>
                <Accordion.Control>{`${name}`}</Accordion.Control>
                <Accordion.Panel>
                  <SupplierTable
                    supplierData={Object.values(supplierName[name])}
                    onChange={handleAltNumberChange}
                    vendors={vendors}
                    rfs={rfs_number}
                    refresh={getData}
                    readOnly={readOnly}
                    type={name}
                  />
                </Accordion.Panel>
              </Accordion.Item>
            )
          );
        })}
      </Accordion>
    </Row>
  );
}

export default Supplier;
