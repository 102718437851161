import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Text, SimpleGrid, Grid, Accordion, Textarea, Checkbox } from '@mantine/core';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import MaterialTable from '../../../components/tables/materialTable/materialTable';
import ConfirmModal from '../../../components/modal/ConfirmModal';
import SubmitProjectModal from '../../../components/modal/SubmitProjectModal';
import { AppLoader } from '../../../components/common/loader/index';

import ProjectName from '../../../components/projectName/projectName';

import { minWordsValidation } from '../../../components/forms/rfsInitiationForm/rfsInitiationForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import { routes } from '../../../routes/index';
import { RfsContext } from '../../../contexts/RfsContext';
import ContentPaper from '../../../components/layouts/rfs/Content';
import { Pencil } from 'tabler-icons-react';
import { AuthContext } from '../../../contexts/AuthContext';
import { NpsIds, rfsStatus, roles } from '../../../constants';
import withConfirm from '../../../components/common/confirm-dialog';
import TechFeasibilityModal from '../../../components/modal/techFeasibilityModal/modal';
import { axios } from '../../../utils/axios';
import { NpsComponent } from '../../../common/nps';
import { SupplierContext, SupplierProvider } from '../../../contexts/SupplierContext';
import SupplierScope from '../scope';

function SupplierChangeValidate(props) {
  const location = useLocation();
  const { confirm, setConfirmLoading } = props;
  const [confirmModal, setConfirmModal] = useState(false);
  const [submitProjectModal, setSubmitProjectModal] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [tfModal, setTfModal] = useState(false);
  const [scopeErrors, setScopeErrors] = useState({
    errors: {},
    liquidErrors: {},
    materialErrors: {},
    productionSiteErrors: {},
  });
  const [artworkRequired, setArtworkRequired] = useState(false);
  const [accordionState, setAccordionState] = useState({});

  const [npsOpened, setNpsOpened] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { form, downloadFile, attachmentLoading, infoLoading, deleteRfs } = useContext(RfsContext);
  const { validateScope, supplierForm } = useContext(SupplierContext);
  const { userRole, userEmail } = useContext(AuthContext);

  const {
    initiator_email = null,
    project_name = '',
    initiator_position = '',
    initiator_team = '',
    backupUsers = [],
    allowedUsers = [],
    first_shipment = '',
    technicalLead = [],
    fast_track = '',
    capex_needed = '',
    capex_amount,
    gatekeeper = [],
    cost_center = '',
    project_purpose = '',
    project_details = '',
    comments = '',
    trials_needed = '',
    status,
    rfs_type,
    impactedMarkets = [],
    d2p_lead = [],
    color_settings_target_needed = null,
    agree_to_pay = false,
    confidential,
  } = form.rfsInformation;
  const { artworkBriefing, fastTrackApproval, emailAttachment } = form.attachments;

  const { scopeSelectedMaterials = [] } = form;

  const artworkRequiredRef = useRef(null);
  artworkRequiredRef.current = artworkRequired;

  useEffect(() => {
    if (scopeSelectedMaterials.length > 0) {
      const index = scopeSelectedMaterials.findIndex((material) => material.artwork_change);
      setArtworkRequired(index > -1);
    }
  }, [scopeSelectedMaterials]);

  useEffect(() => {
    if (submitted && !npsOpened && userRole === roles.initiator) navigate('/');
    if (submitted && userRole !== roles.initiator) navigate('/');
  }, [submitted, npsOpened]);

  const getUsers = (type) => {
    if (type === 'allowed') {
      let users = (allowedUsers || []).map((item) => item.name);
      return users?.join(', ');
    } else if (type === 'backup') {
      let users = (backupUsers || []).map((item) => item.name);
      return users?.join(', ');
    } else if (type === 'gatekeeper') {
      let users = (gatekeeper || []).map((item) => item.name);
      return users?.join(', ');
    } else if (type === 'technical') {
      let users = (technicalLead || []).map((item) => item.name);
      return users?.join(', ');
    } else if (type === 'd2p') {
      let users = (d2p_lead || []).map((item) => item.name);
      return users?.join(', ');
    } else {
      return null;
    }
  };

  const getImpactedMarkets = () => {
    let markets = (impactedMarkets || []).map((item) => item);
    return markets?.join(', ');
  };

  const FileList = ({ type, files = [] }) => {
    let { rfsNumber } = props;
    rfsNumber = rfsNumber.replace(/[^a-zA-Z0-9]/g, '');

    return (files || []).length > 0 ? (
      <ul style={{ fontSize: '0.8rem' }}>
        {files.map((file, index) => (
          <li
            style={{
              color: '#e5b611',
              cursor: 'pointer',
            }}
            key={index}
            onClick={() => downloadFile(file.name, rfsNumber.toLowerCase())}
          >
            {file.name}
          </li>
        ))}
      </ul>
    ) : (
      <p
        style={
          type === 'fastTrackApproval' && Boolean(fast_track) && fastTrackApproval.length === 0
            ? { fontSize: '0.8rem', color: 'red' }
            : { fontSize: '0.8rem' }
        }
      >
        {' '}
        No Files Attached{' '}
      </p>
    );
  };

  const isDisabled = () => {
    return (
      !initiator_position ||
      !initiator_team ||
      backupUsers.length === 0 ||
      !first_shipment ||
      !trials_needed ||
      gatekeeper?.length === 0 ||
      impactedMarkets.length === 0 ||
      minWordsValidation(project_purpose) ||
      minWordsValidation(project_details) ||
      !cost_center ||
      (trials_needed === 'Yes' && (!technicalLead || technicalLead.length === 0)) ||
      !color_settings_target_needed ||
      (color_settings_target_needed === 'Yes' && !d2p_lead) ||
      (color_settings_target_needed === 'Yes' && d2p_lead.length === 0) ||
      (confidential && (!allowedUsers || allowedUsers.length === 0)) ||
      (Boolean(fast_track) && fastTrackApproval.length === 0) ||
      !agree_to_pay
    );
  };

  const setSubmissionDateAndStatus = () => {
    setConfirmLoading(true);
    axios.post(`/supplier-changes/scope-selection/submission/${params.rfsNumber}`).then(
      (res) => {
        axios
          .put(
            `/rfs/submit/${params.rfsNumber}`,
            {},
            {
              headers: {
                rfsId: form.rfsInformation.id,
              },
            },
          )
          .then(
            (res) => {
              setConfirmLoading(false);
              setConfirmModal(true);
            },
            (error) => {
              setConfirmLoading(false);
            },
          );
      },
      (error) => {
        console.log(error);
        setConfirmLoading(false);
      },
    );
  };

  const handleSubmit = useCallback(() => {
    handleSubmit1();
  }, [confirm, supplierForm]);

  const handleSubmit1 = (type = form.rfsInformation.rfs_type) => {
    const VEAM = type === 'Article Modification/Value Engineering';

    const errors = validateScope(supplierForm, form?.selectedScope.materials, 'validate');
    setScopeErrors(errors);

    setTimeout(() => {
      if (Object.keys(errors.errors).length > 0 || Object.keys(errors.fieldErrors).length > 0) {
        return setSubmitProjectModal(true);
      } else if (isDisabled()) {
        return setSubmitProjectModal(true);
      } else {
        return confirm(
          () => {
            setSubmissionDateAndStatus();
          },
          {
            title: 'Confirm!',
            description: VEAM
              ? 'Are you sure you want to submit this project? Once submitted you will not be able to access it again!'
              : "Please confirm all legal /country specific mentions, required for the new markets on packaging of the SKU's, are correct and packaging does not need any artwork change.\n Once project is submitted you will not be able to access it again!",
            confirmationText: 'Submit',
          },
        );
      }
    }, 500);
  };

  const editScope = () => {
    let access = false;
    if (!props.disableButtons) {
      if (
        (initiator_email === userEmail && status === rfsStatus.draft) ||
        userRole === roles.superAdmin
      ) {
        access = true;
      }
    }

    return access;
  };

  const handleDelete = useCallback(() => {
    confirm(() => deleteRfs(() => navigate(routes.rfs.my)), {
      title: 'Confirm!',
      description:
        'Are you sure you want to delete this project? Once deleted you will not be able to access it again!',
      confirmationText: 'Delete',
    });
  }, [confirm]);

  const canDelete = () => {
    return userEmail?.toLowerCase() === initiator_email?.toLowerCase();
  };

  const Footer = () => (
    <Row className='justify-content-md-end' md={4}>
      <Col md='auto'>
        {canDelete() && !infoLoading && (
          <Button
            className='delete'
            color='red'
            radius='md'
            size='md'
            fullWidth
            style={{ marginRight: '1rem', color: 'white', width: '200px' }}
            onClick={() => handleDelete()}
          >
            Delete Project
          </Button>
        )}
      </Col>
      <Col md='auto'>
        <Button
          className='submit'
          color='primary'
          radius='md'
          variant='gradient'
          gradient={{ from: '#e3af32', to: '#f4e00f' }}
          size='md'
          fullWidth
          style={{
            marginRight: '1rem',
            width: '200px',
          }}
          onClick={handleSubmit}
        >
          Submit Project
        </Button>
      </Col>
    </Row>
  );

  if (
    form.rfsInformation.status &&
    ![
      rfsStatus.draft,
      rfsStatus.tech_feasible,
      rfsStatus.tech_feasible2,
      rfsStatus.supplier_pmo1_active,
      rfsStatus.supplier_d2p_active,
      rfsStatus.supplier_zitec_active,
      rfsStatus.supplier_pmo2_active,
    ].includes(form.rfsInformation.status) &&
    location.pathname.includes('/submission/review')
  ) {
    navigate(routes.submission(params.rfsNumber).overView);
  }

  if (
    form.rfsInformation.status &&
    form.rfsInformation.status === rfsStatus.draft &&
    location.pathname.includes('/project/overview')
  ) {
    navigate(routes.submission(params.rfsNumber).review);
  }

  return (
    <ContentPaper page='submission-review' footer={editScope() ? <Footer /> : null}>
      {infoLoading ? (
        <AppLoader size='lg' center />
      ) : (
        <div>
          <ProjectName Pnumber={project_name} initialItem={props.initialItems} />

          <Row>
            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>
            <Col
              md={{ span: 3 }}
              style={{
                marginTop: '1rem',
              }}
            >
              <h5 className='sectionHeadings'>
                GENERAL DETAILS{' '}
                {editScope() && (
                  <span className='editIcon'>
                    <Pencil
                      color='#e5b611'
                      onClick={() =>
                        navigate(routes.submission(params.rfsNumber).info, {
                          state: {
                            routeFromRfsOverview: true,
                          },
                        })
                      }
                    />
                  </span>
                )}
              </h5>
            </Col>
          </Row>

          <>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2}>
                  {!initiator_position ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text size='sm' weight={600} color={!initiator_position ? 'red' : ''}>
                        Initiator Position:
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600} color={!initiator_position ? 'red' : ''}>
                      Initiator Position:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {initiator_position}
                  </Text>
                </SimpleGrid>
              </Col>

              <Col md={{ span: 5 }}>
                <SimpleGrid cols={2}>
                  {!initiator_team ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text size='sm' weight={600} color={!initiator_team ? 'red' : ''}>
                        Initiator Team:
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600} color={!initiator_team ? 'red' : ''}>
                      Initiator Team:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {initiator_team}
                  </Text>
                </SimpleGrid>
              </Col>
            </Row>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  {(backupUsers || []).length === 0 ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text
                        size='sm'
                        weight={600}
                        color={(backupUsers || []).length === 0 ? 'red' : ''}
                      >
                        Selected Backup Users:
                      </Text>
                    </Link>
                  ) : (
                    <Text
                      size='sm'
                      weight={600}
                      color={(backupUsers || []).length === 0 ? 'red' : ''}
                    >
                      Selected Backup Users:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {getUsers('backup') === '' ? 'No users selected' : getUsers('backup')}
                  </Text>
                </SimpleGrid>
              </Col>
              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  <Text size='sm' weight={600}>
                    Request for Fast Track:
                  </Text>
                  <Text size='sm' weight={400}>
                    {fast_track ? 'Yes' : 'No'}
                  </Text>
                </SimpleGrid>
              </Col>
            </Row>

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  {(technicalLead || []).length === 0 && trials_needed === 'Yes' ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text
                        size='sm'
                        weight={600}
                        color={(technicalLead || []).length === 0 ? 'red' : ''}
                      >
                        Selected Technical Lead:
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600}>
                      Selected Technical Lead:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {getUsers('technical') === '' ? 'No users selected' : getUsers('technical')}
                  </Text>
                </SimpleGrid>
              </Col>
              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  <Text size='sm' weight={600}>
                    Selected Allowed Users:
                  </Text>
                  <Text size='sm' weight={400}>
                    {getUsers('allowed') === '' ? 'No users selected' : getUsers('allowed')}
                  </Text>
                </SimpleGrid>
              </Col>
            </Row>

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  {!first_shipment ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text size='sm' weight={600} color={!first_shipment ? 'red' : ''}>
                        First Shipment Date:
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600} color={!first_shipment ? 'red' : ''}>
                      First Shipment Date:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {first_shipment}
                  </Text>
                </SimpleGrid>
              </Col>
            </Row>

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  {!cost_center ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text size='sm' weight={600} color={!cost_center ? 'red' : ''}>
                        Cost Center:
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600} color={!cost_center ? 'red' : ''}>
                      Cost Center:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {cost_center}
                  </Text>
                </SimpleGrid>
              </Col>

              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  {gatekeeper?.length === 0 ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text size='sm' weight={600} color={gatekeeper?.length === 0 ? 'red' : ''}>
                        Selected Gatekeeper:
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600} color={gatekeeper?.length === 0 ? 'red' : ''}>
                      Selected Gatekeeper:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {getUsers('gatekeeper') === '' ? 'No users selected' : getUsers('gatekeeper')}
                  </Text>
                </SimpleGrid>
              </Col>
            </Row>

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  {!trials_needed ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text size='sm' weight={600} color={!trials_needed ? 'red' : ''}>
                        Are Trials needed:
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600} color={!trials_needed ? 'red' : ''}>
                      Are Trials needed:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {trials_needed}
                  </Text>
                </SimpleGrid>
              </Col>

              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  {!color_settings_target_needed ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text
                        size='sm'
                        weight={600}
                        color={!color_settings_target_needed ? 'red' : ''}
                      >
                        Is Colour Target Setting needed?
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600} color={!color_settings_target_needed ? 'red' : ''}>
                      Is Colour Target Setting needed?
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {color_settings_target_needed || ''}
                  </Text>
                </SimpleGrid>
              </Col>
            </Row>

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  {d2p_lead.length === 0 && color_settings_target_needed === 'Yes' ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text size='sm' weight={600} color={'red'}>
                        Design To Print Lead:
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600}>
                      Design To Print Lead:
                    </Text>
                  )}
                  <Text size='sm' weight={400}>
                    {d2p_lead.length > 0 ? getUsers('d2p') : 'N/A'}
                  </Text>
                </SimpleGrid>
              </Col>

              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  {!agree_to_pay ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text size='sm' weight={600} color={'red'}>
                        Agree to pay for artwork and print plates costs where relevant
                      </Text>
                    </Link>
                  ) : (
                    <Text size='sm' weight={600}>
                      Agree to pay for artwork and print plates costs where relevant
                    </Text>
                  )}
                  <Checkbox
                    size='sm'
                    label={''}
                    className='checkbox'
                    color='orange'
                    disabled={true}
                    defaultChecked={agree_to_pay}
                  />
                </SimpleGrid>
              </Col>
            </Row>

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                  <Text size='sm' weight={600} color={impactedMarkets?.length === 0 ? 'red' : ''}>
                    Impacted Markets
                  </Text>
                  <Text size='sm' weight={400}>
                    {['Production Site', 'Article Modification/Value Engineering'].includes(
                      rfs_type,
                    ) && getImpactedMarkets() === ''
                      ? 'No markets selected'
                      : getImpactedMarkets()}
                  </Text>
                </SimpleGrid>
              </Col>
            </Row>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Row style={{ marginTop: '0.5rem' }}>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>

                <Col md={{ span: 2 }}>
                  {!project_purpose || minWordsValidation(project_purpose) ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text
                        size='sm'
                        weight={600}
                        color={!project_purpose || minWordsValidation(project_purpose) ? 'red' : ''}
                      >
                        Project Purpose:
                      </Text>
                    </Link>
                  ) : (
                    <Text
                      size='sm'
                      weight={600}
                      color={!project_purpose || minWordsValidation(project_purpose) ? 'red' : ''}
                    >
                      Project Purpose:
                    </Text>
                  )}
                </Col>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>
                <Col md={{ span: 8 }}>
                  <Text size='sm' weight={400}>
                    {project_purpose}
                  </Text>
                </Col>
              </Row>

              <Row style={{ marginTop: '0.5rem' }}>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>

                <Col md={{ span: 2 }}>
                  {!project_details || minWordsValidation(project_details) ? (
                    <Link to={routes.submission(params.rfsNumber).info}>
                      <Text
                        size='sm'
                        weight={600}
                        color={!project_details || minWordsValidation(project_details) ? 'red' : ''}
                      >
                        Project Explanation:
                      </Text>
                    </Link>
                  ) : (
                    <Text
                      size='sm'
                      weight={600}
                      color={!project_details || minWordsValidation(project_details) ? 'red' : ''}
                    >
                      Project Explanation:
                    </Text>
                  )}
                </Col>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>
                <Col md={{ span: 8 }}>
                  <Text size='sm' weight={400}>
                    {project_details}
                  </Text>
                </Col>
              </Row>
            </Row>

            <Row style={{ marginTop: '0.5rem' }}>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Col md={{ span: 2 }}>
                <Text size='sm' weight={600}>
                  Any Additional Comments:
                </Text>
              </Col>
              <Col
                md={1}
                style={{
                  width: '3.8%',
                  flex: '0 0 3.8%',
                  maxWidth: ' 3.8%',
                }}
              ></Col>
              <Col md={{ span: 8 }}>
                <Text size='sm' weight={400}>
                  {comments}
                </Text>
              </Col>
            </Row>
          </>
          <Row style={{ marginTop: '1rem' }}>
            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>
            <Col
              md={{ span: 3 }}
              style={{
                marginTop: '1rem',
              }}
            >
              <h5 className='sectionHeadings'>
                SCOPE{' '}
                {editScope() && (
                  <span className='editIcon'>
                    <Pencil
                      color='#e5b611'
                      onClick={() =>
                        navigate(routes.submission(params.rfsNumber).scopeSelect, {
                          state: {
                            routeFromRfsOverview: true,
                          },
                        })
                      }
                    />
                  </span>
                )}
              </h5>
              {form.selectedScope.materials.length === 0 && (
                <span style={{ color: 'red', fontWeight: '400', fontSize: '0.8rem' }}>
                  Required Field
                </span>
              )}
            </Col>

            <Col
              md={{ span: 2, offset: 6 }}
              style={{
                marginTop: '1rem',
              }}
            >
              <Button
                color='primary'
                style={{ background: 'black', color: 'white' }}
                onClick={() => setTfModal(true)}
              >
                Other Projects in scope
              </Button>
            </Col>
          </Row>

          <Row>
            <Col
              md={1}
              style={{
                width: '4.1666%',
                flex: '0 0 4.1666%',
                maxWidth: ' 4.1666%',
              }}
            ></Col>
            <Col
              md={11}
              style={{
                marginTop: '0.5rem',
              }}
            >
              <SupplierScope {...props} />
            </Col>
          </Row>

          <Row style={{ marginTop: '1rem' }}>
            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>
            <Col
              md={{ span: 3 }}
              style={{
                marginTop: '1rem',
              }}
            >
              <h5 className='sectionHeadings'>
                ATTACHMENTS {}
                {editScope() && (
                  <span className='editIcon'>
                    <Pencil
                      color='#e5b611'
                      onClick={() =>
                        navigate(routes.submission(params.rfsNumber).attachment, {
                          state: {
                            routeFromRfsOverview: true,
                          },
                        })
                      }
                    />
                  </span>
                )}
              </h5>
            </Col>
          </Row>

          <Row>
            <Col
              md={1}
              style={{
                width: '4.1666%',
                flex: '0 0 4.1666%',
                maxWidth: ' 4.1666%',
              }}
            ></Col>

            <Col md={{ span: 5, offset: 0 }}>
              {emailAttachment.length === 0 ? (
                <Link to={routes.submission(params.rfsNumber).attachment}>
                  <Text size='sm' weight={600} color={'dark'}>
                    Additional Email Attachment:
                  </Text>
                </Link>
              ) : (
                <Text size='sm' weight={600}>
                  Additional Email Attachment:
                </Text>
              )}
              <Text size='sm' weight={400}>
                {attachmentLoading ? (
                  <AppLoader />
                ) : (
                  <FileList files={emailAttachment} type={'emailAttachment'} />
                )}
              </Text>
            </Col>
            <Col md={{ span: 5, offset: 0 }}>
              {fastTrackApproval.length === 0 ? (
                <Link to={routes.submission(params.rfsNumber).attachment}>
                  <Text size='sm' weight={600} color={'dark'}>
                    Fast Track Approval:
                  </Text>
                </Link>
              ) : (
                <Text size='sm' weight={600}>
                  Fast Track Approval:
                </Text>
              )}
              <Text size='sm' weight={400}>
                {attachmentLoading ? (
                  <AppLoader />
                ) : (
                  <FileList files={fastTrackApproval} type={'fastTrackApproval'} />
                )}
              </Text>
            </Col>
          </Row>

          <Row>
            <Col
              md={1}
              style={{
                width: '4.1666%',
                flex: '0 0 4.1666%',
                maxWidth: ' 4.1666%',
              }}
            ></Col>

            <Col md={{ span: 5, offset: 0 }}>
              {artworkBriefing.length === 0 ? (
                <Link to={routes.submission(params.rfsNumber).attachment}>
                  <Text size='sm' weight={600} color={'dark'}>
                    Artwork Briefing:
                  </Text>
                </Link>
              ) : (
                <Text size='sm' weight={600}>
                  Artwork Briefing:
                </Text>
              )}
              <Text size='sm' weight={400}>
                {attachmentLoading ? (
                  <AppLoader />
                ) : (
                  <FileList files={artworkBriefing} type={'artworkBriefing'} />
                )}
              </Text>
            </Col>
          </Row>

          {tfModal && <TechFeasibilityModal opened={tfModal} setOpened={setTfModal} />}

          {submitProjectModal && (
            <SubmitProjectModal opened={submitProjectModal} setOpened={setSubmitProjectModal} />
          )}

          {confirmModal && (
            <ConfirmModal
              opened={confirmModal}
              setOpened={setConfirmModal}
              pname={project_name}
              npsOpened={npsOpened}
              setNpsOpened={setNpsOpened}
              setSubmitted={setSubmitted}
            />
          )}

          {npsOpened && (
            <NpsComponent
              rfs_number={params.rfsNumber}
              opened={npsOpened}
              setOpened={setNpsOpened}
              callback={() => {
                navigate('/');
              }}
              id={NpsIds().submission}
            />
          )}
        </div>
      )}
    </ContentPaper>
  );
}

export default withConfirm(SupplierChangeValidate);
