import { useEffect, useMemo, useState } from 'react';
import { Button, Checkbox } from '@mantine/core';
import MantineTable from '../../../../common/table/mantine';
import styled from '@emotion/styled';

const CheckboxContainer = styled.div`
  margin: 16px 0;
`;

export const Table = ({
  skus = [],
  setBomsModal,
  selectedSkus,
  handleRowSelection,
  plantId,
  skuId,
  selectedSkusDetails,
  readOnly = false,
}) => {
  const [dualSkuSelection, setDualSkuSelection] = useState(false);
  const { selectedData = {} } = selectedSkusDetails || {};
  const [skusToDisplay, setSkusToDisplay] = useState([]);

  useEffect(() => {
    const data = skus.map((sku) => {
      const isDisabled =
        Object.keys(selectedData).length === 0
          ? false
          : ((dualSkuSelection && Object.keys(selectedData).length >= 2) ||
              (!dualSkuSelection && Object.keys(selectedData).length >= 1)) &&
            !selectedData?.[sku.unique];

      return {
        ...sku,
        disabled: isDisabled,
      };
    });
    setSkusToDisplay(data);
  }, [selectedSkus, dualSkuSelection]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'type_of_sku',
        header: 'Type of SKU',
        Cell: ({ row: { original } }) =>
          original.type === 'recommended' ? 'Recommended' : 'Reference',
      },
      {
        accessorKey: 'sku',
        header: 'SKU',
      },
      {
        accessorKey: 'sku_description',
        header: 'SKU Description',
      },
      {
        accessorKey: 'plant',
        header: 'Production Plant',
      },
      {
        accessorKey: 'brand',
        header: 'Brand',
      },
      {
        accessorKey: 'container',
        header: 'Pack Type',
      },
      {
        accessorKey: 'container_size',
        header: 'Pack Size',
      },

      {
        id: 'bom',
        header: 'Show BOM',
        Cell: ({ row }) => (
          <Button
            color='dark'
            onClick={() =>
              setBomsModal({
                open: true,
                sku: row.original.sku,
                plant: row.original.plant,
              })
            }
          >
            View BOM
          </Button>
        ),
      },
    ],
    [skus, Object.keys(selectedSkus).length],
  );

  const handleSelection = (data) => {
    handleRowSelection(data, plantId, skuId);
  };

  return (
    <>
      <MantineTable
        columns={columns}
        initialData={skusToDisplay}
        unique={'unique'}
        enableRowSelection={true}
        loading={false}
        handleRowSelection={handleSelection}
        activeRows={selectedData}
        showSelectedToggle={true}
        hideSelectColumn={false}
        showResetAll={false}
        enableExport={false}
        pageSize={10}
        enableSelectAll={false}
        positionToolbarAlertBanner={'none'}
      />

      <CheckboxContainer>
        <Checkbox
          label='Dual Reference Selection'
          value={dualSkuSelection}
          disabled={readOnly}
          onChange={(event) => {
            if (!event.target.checked) handleRowSelection({}, plantId, skuId);

            setDualSkuSelection(event.target.checked);
          }}
        />
      </CheckboxContainer>
    </>
  );
};
