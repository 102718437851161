import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '@mantine/core';
import { axios } from '../../../../utils/axios';
import MantineTable from '../../../../common/table/mantine';
import { useParams } from 'react-router-dom';
import { TextInput } from '@mantine/core';
import { IconRefresh, IconTrash } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';
import { Accordion, Grid, Space } from '@mantine/core';
import { Switch } from '@mantine/core';
import { CustomTextArea } from '../../../../common/inputs/CustomTextArea';
import { notifications } from '@mantine/notifications';

export const NormsCheck = ({ activeTab }) => {
  const { rfsNumber } = useParams();
  const [loading, setLoading] = useState(false);
  const [skus, setSkus] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [baseSku, setBaseSku] = useState([]);
  const [switchState, setSwitchState] = useState(false);
  const [searchParams] = useSearchParams();
  const [value, setValue] = useState('');
  const [uniqueID, setUniqueID] = useState([]);

  useEffect(() => {
    if (activeTab === 'normAdjustment') {
      fetch();
    }
  }, [activeTab]);

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/costing-request/step/norms/${rfsNumber}`);
      let apiData = response.data?.data;
      const uniqueIds = [
        ...new Map(
          apiData.map((item) => [
            item.pp_id,
            { ppID: item.pp_id, prod_plant: item.reference_bom?.[0]?.pp_id, ...item.sku_details },
          ]),
        ).values(),
      ];
      setUniqueID(uniqueIds);
      setBaseSku(apiData);

      console.log(uniqueID);
    } catch (err) {
      console.log('err : normsCheck.js', err);
    } finally {
      setLoading(false);
    }
  };
  const handleAccordionChange = (prodPlantId) => {
    const filteredSkus = baseSku.filter((item) => item.pp_id === prodPlantId);
    setSkus(filteredSkus);
    setDisplayData(filteredSkus);
  };
  const handleNotification = (title, message, color) => {
    notifications.show({
      color: color,
      title: title,
      message: message,
    })
  }

  const handleSubmit = () => {
    // currently all the changes are done either single click or auto adjustment >>>
    // in future bulk update API is needed for update
    // some kind of toast >> to give user clue whats going on
    handleNotification("Success", "Submitted the Request", "green");


  };
  const handleSwitch = (status) => {
    setSwitchState(status);

    if (status) {
      const mismatchedData = skus.filter((sku) => {
        if (sku.reference_bom?.[0]?.quantity) {
          const referenceQuantity = parseFloat(sku.reference_bom?.[0]?.quantity) || 0;
          const actualQuantity = parseFloat(sku.quantity) || 0;
          return actualQuantity !== referenceQuantity;
        }
      });
      setDisplayData(mismatchedData); // Show mismatched data
    } else {
      setDisplayData(skus); // Restore all data
    }
  };
  const handleNewData = (newData) => {
    setSkus((prevSkus) =>
      prevSkus.map((sku) => {
        const updatedInfo = newData.find((item) => item.Component === sku.Component);
        return updatedInfo ? { ...sku, ...updatedInfo } : sku;
      }),
    );
  };

  const handleRefresh = async () => {
    let url = `/costing-request/step/norms/refresh/${rfsNumber}`;
    try {
      const res = await axios.post(url, {});
      const resData = res?.data?.data?.[0]?.response?.data;
      handleNewData(resData);
      handleNotification("Success", "Refresh Successful", "green");
    } catch (e) {
      console.log('Refresh API', e);

    }
  };
  const handleNormRemoval = async (skuId, data) => {
    const payload = {
      component_id: data?.component_id,
      quantity: data?.quantity,
      comment: data?.comment,
    };
    const options = {
      data: payload,
    };

    try {
      const response = await axios.delete(`costing-request/step/norms/${rfsNumber}`, options);
      setSkus((prevData) => prevData.filter((ele) => ele.sku_id !== skuId));
      setDisplayData((prevData) => prevData.filter((ele) => ele.sku_id !== skuId));
    } catch {
      console.log('error deleting the data !!');
    }
  };

  const handleAutoAdj = async () => {
    // Update skus and baseSku by mapping over the data and adjusting the quantity field
    setSkus((prevData) =>
      prevData.map((ele) => {
        const updatedEle = { ...ele };
        if (ele.reference_bom?.[0]?.quantity) {
          updatedEle.quantity = ele.reference_bom[0].quantity;
        }
        return updatedEle;
      }),
    );

    setBaseSku((prevBaseSku) =>
      prevBaseSku.map((ele) => {
        const updatedEle = { ...ele };
        if (ele.reference_bom?.[0]?.quantity) {
          updatedEle.quantity = ele.reference_bom[0].quantity;
        }
        return updatedEle;
      }),
    );

    try {
      const promises = skus.map(async (sku) => {
        const payload = {
          component_id: sku.component_id ?? '',
          quantity: sku.quantity ?? '',
          comment: sku.comment ?? '',
        };
        const res = await axios.post(
          `/costing-request/step/norms/${sku.costing_request_id}`,
          payload,
        );
        return res;
      });

      const results = await Promise.all(promises);
      console.log('API requests completed:', results);
      handleNotification("Success", "Auto Adjustment Successful", "green");

    } catch (error) {
      console.error('An error occurred during the API requests:', error);
    }
  };

  const updateDisplayData = (skusData) => {
    if (switchState) {
      const mismatchedData = skusData.filter(
        (sku) => parseFloat(sku.quantity) !== parseFloat(sku.reference_bom?.[0]?.quantity),
      );
      setDisplayData(mismatchedData);
    } else {
      setDisplayData(skusData);
    }
  };
  const handleSaveChange = async (skuId) => {
    const data = skus.find((ele) => skuId === ele.sku_id);
    const payload = {
      component_id: data.component_id,
      quantity: data.quantity,
      comment: data.comment ?? '',
    };
    try {
      const res = await axios.post(
        `/costing-request/step/norms/${data?.costing_request_id}`,
        payload,
      );
    } catch (e) {
      console.log(e, 'err');
    }
  };
  const updateSkuData = (id, field, value, data) => {
    return data.map((sku) => (sku.component_id === id ? { ...sku, [field]: value } : sku));
  };

  const handleInputChange = useCallback(
    (id, field, value) => {
      // Update skus and displayData
      setSkus((prevSkus) => {
        const updatedSkus = updateSkuData(id, field, value, prevSkus);
        updateDisplayData(updatedSkus);
        return updatedSkus;
      });
      setBaseSku((prevBaseSku) => updateSkuData(id, field, value, prevBaseSku));
    },
    [updateDisplayData],
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'component_description',
        header: 'Norm Name',
      },
      {
        accessorKey: 'component',
        header: 'Norm Resource',
      },
      {
        accessorFn: (row) => row,
        header: 'Total Value (per HL)',
        Cell: ({ row }) => {
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span key={row?.original?.sku_id || 'key-1'} style={{ marginRight: '8px' }}>
                  Requested:{row.original?.scrap ?? ''}
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>
                  Reference:{row.original?.reference_bom?.[0]?.scrap ?? ''}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row,
        header: 'Quantity Value ',
        Cell: ({ row }) => {
          const hasMismatch =
            parseFloat(row.original?.quantity) !==
            parseFloat(row.original?.reference_bom?.[0]?.quantity);

          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span key={row.original?.sku_id || 'key-1'} style={{ marginRight: '8px' }}>
                  Requested:
                </span>
                <TextInput
                  value={parseFloat(row.original?.quantity ?? '')}
                  onChange={(e) =>
                    handleInputChange(row.original?.component_id ?? '', 'quantity', e.target.value)
                  }
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span key={row.original?.sku_id} style={{ marginRight: '8px' }}>
                  Reference:
                </span>
                <TextInput
                  value={parseFloat(row.original?.reference_bom?.[0]?.quantity ?? '')}
                  disabled
                  styles={(theme) => ({
                    input: {
                      borderColor: hasMismatch ? 'red' : theme.colors.gray[4],
                      borderWidth: '1px',
                    },
                  })}
                />
              </div>
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row,
        header: 'Quantity Unit',
        Cell: ({ row }) => {
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span key={row.original?.sku_id} style={{ marginRight: '8px' }}>
                  Requested: {row.original?.component_unit ?? ''}
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span key={row.original.primary?.sku_id} style={{ marginRight: '8px' }}>
                  Reference:{row.original?.reference_bom?.[0]?.component_unit ?? ''}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row,
        header: 'Price Value',
        Cell: ({ row }) => (
          <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <span key={row.original.requested?.sku_id} style={{ marginRight: '8px' }}>
                Requested: {row?.original?.planned_price_1}
              </span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <span style={{ marginRight: '8px' }}>
                Reference: {row?.original?.reference_bom?.planned_price_1}
              </span>
            </div>
          </div>
        ),
      },
      {
        accessorFn: (row) => row,
        header: 'Price Unit',
        Cell: ({ row }) => {
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span key={row.original?.sku_id} style={{ marginRight: '8px' }}>
                  Requested: {row.original?.currency}
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '8px' }}>
                  Reference: {row.original?.reference_bom?.currency || ' '}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row,
        header: 'Comment',
        Cell: ({ row }) => {
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <CustomTextArea
                  value={row.original?.comment}
                  placeholder='Comment here'
                  onChange={(value) =>
                    handleInputChange(row.original?.component_id ?? '', 'comment', value)
                  }
                />
              </div>
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row,
        header: 'Delete',
        Cell: ({ row }) => {
          return (
            <div className='flex'>
              <Button
                onClick={() => handleNormRemoval(row.original?.sku_id, row.original)}
                variant='subtle'
                color='red'
              >
                <IconTrash size={16} />
              </Button>
              <Button
                onClick={() => handleSaveChange(row.original?.sku_id, row.original)}
                variant='light'
                color='dark'
              >
                Save
              </Button>
            </div>
          );
        },
      },
    ],
    [handleInputChange],
  );

  return (
    <>
      <Space h='sm' />
      <div>
        <Accordion value={value} onChange={setValue}>
          {uniqueID.map((sku, index) => (
            <Accordion.Item
              className='scope-label'
              key={`${sku.component || index}`}
              value={`key_${index}`}
              onClick={() => handleAccordionChange(sku.ppID)}
            >
              <Accordion.Control>
                {`${sku?.sku} | ${sku?.sku_description} | ${sku.prod_plant}`}
              </Accordion.Control>
              <Accordion.Panel>
                <Grid>
                  <Grid.Col span={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '16px',
                        marginTop: '10px',
                      }}
                    >
                      <Switch
                        label='View Flagged Data Only'
                        size='md'
                        onChange={(e) => handleSwitch(e.target.checked)}
                      />
                      <div style={{ display: 'flex', gap: '8px' }}>
                        <Button
                          variant='light'
                          color='dark'
                          onClick={() => handleRefresh(sku.ppID)}
                        >
                          <IconRefresh size={16} />
                        </Button>
                        <Button variant='light' color='dark' onClick={() => handleAutoAdj()}>
                          Auto Adjustment of BOM
                        </Button>
                      </div>
                    </div>
                    <div className='flex' style={{ marginTop: '5px' }}>
                      <MantineTable
                        columns={columns}
                        initialData={displayData}
                        enableRowSelection={false}
                        showSelectedToggle={false}
                        hideSelectColumn={true}
                        showResetAll={false}
                        pageSize={10}
                        enableColumnFilter={false}
                        enableFilters={false}
                        columnFilterDisplayMode={false}
                        loading={false}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '5px',
                        marginTop: '10px',
                      }}
                    >
                      <Button variant='light' color='dark' onClick={() => handleSubmit()}>
                        Submit
                      </Button>
                    </div>
                  </Grid.Col>
                </Grid>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </>
  );
};
