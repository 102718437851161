import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../routes';
import MantineTable from '../../../common/table/mantine';
import { rfsStatus } from '../../../constants';

export const RfsTable = ({ loading, data, csvFilename }) => {
  let columns = useMemo(
    () => [
      {
        header: 'Req ID',
        accessorKey: 'costing_request_id',
        Cell: ({ row }) => {
          const { costing_request_id, status } = row.original;
          return (
            <Link
              to={
                ![rfsStatus.draft].includes(status.toLowerCase())
                  ? routes.costingRequests(costing_request_id).overview
                  : routes.costingRequests(costing_request_id).request
              }
            >
              {costing_request_id}
            </Link>
          );
        },
      },
      {
        header: 'RFS Number',
        accessorKey: 'rfs_number',
      },
      {
        header: 'Type of request',
        accessorKey: 'type_of_request',
      },
      {
        header: 'Urgency',
        accessorKey: 'urgency',
      },
      {
        header: 'FSD Date',
        accessorKey: 'fsd_date',
      },
      {
        header: 'Status',
        accessorKey: 'status',
      },
      {
        header: 'Steps In Progress',
        accessorKey: 'steps_in_progress',
      },
      {
        header: 'Producing Plant',
        accessorKey: 'producing_plant',
      },
      {
        header: 'Selling Country',
        accessorKey: 'selling_country',
      },
      {
        header: 'Buying Country',
        accessorKey: 'buying_country',
      },
      {
        header: 'SKU',
        accessorKey: 'sku',
      },
      {
        header: 'SKU Description',
        accessorKey: 'sku_description',
      },
      {
        header: 'SPOC from Pricing team',
        accessorKey: 'spoc_pricing_team',
      },
    ],
    [data],
  );

  return (
    <>
      <MantineTable
        columns={columns}
        initialData={data}
        unique={'costing_request_id'}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        enableExport={true}
        csvFilename={csvFilename}
        pageSize={10}
      />
    </>
  );
};
