const prefix = {
  home: 'home',
  submission: 'submission',
  rfs: 'rfs',
  product: 'product',
  tech: 'tech',
  implementation: 'imp',
  project: 'project',
  admin: 'admin',
  decomplexity: 'decomplexity',
  tasks: 'tasks',
  reports: 'reports',
  feedback: 'feedback',
  gantt: 'gantt',
  unauth: 'unauth',
  accf: 'accf',
  delisting: 'delisting',
  RaiseTicket: 'RaiseTicket',
  costingRequests: 'costingRequests',
  raiseticket: 'raiseticket',
  RaiseTicketPage: 'RaiseTicketPage',
};

export const routes = {
  home: {
    root: `/${prefix.home}`,
    register: `/register`,
  },
  submission: (rfsNumber = ':rfsNumber') => ({
    add: `/${prefix.submission}/add`,
    edit: `/${prefix.submission}/add/${rfsNumber}`,
    info: `/${prefix.submission}/info/${rfsNumber}`,
    scopeSelect: `/${prefix.submission}/scope/select/${rfsNumber}`,
    scopeDefine: `/${prefix.submission}/scope/define/${rfsNumber}`,
    attachment: `/${prefix.submission}/attachment/${rfsNumber}`,
    review: `/${prefix.submission}/review/${rfsNumber}`,
    overView: `/${prefix.project}/overview/${rfsNumber}`,
  }),
  delisting: (rfsNumber = ':rfsNumber') => ({
    root: `/${prefix.delisting}`,
    scopeDefine: `/${prefix.delisting}/scope/define/${rfsNumber}`,
    attachment: `/${prefix.delisting}/attachment/${rfsNumber}`,
    review: `/${prefix.delisting}/review/${rfsNumber}`,
  }),
  RaiseTicket: () => ({
    root: `/${prefix.RaiseTicket}`,
    Ticket: `/${prefix.RaiseTicket}/RaiseTicket`,
  }),
  rfs: {
    root: `/${prefix.rfs}`,
    my: `/${prefix.rfs}/my`,
  },
  product: (rfsNumber = ':rfsNumber') => ({
    development: `/${prefix.product}/development/${rfsNumber}`,
  }),
  tech: {
    root: `/${prefix.tech}/feasibility`,
  },
  scope: {
    root: `/${prefix.scope}`,
  },
  implementation: {
    root: `/${prefix.implementation}/feasibility`,
  },
  project: (rfsNumber = ':rfsNumber') => ({
    root: `/${prefix.project}/overview/${rfsNumber}`,
  }),
  admin: {
    root: `/${prefix.admin}`,
  },
  decomplexity: {
    root: `/${prefix.decomplexity}`,
  },
  feedback: {
    root: `/${prefix.feedback}`,
  },
  tasks: {
    root: `/${prefix.tasks}`,
  },
  gantt: {
    root: `/${prefix.gantt}`,
  },
  reports: {
    root: `/${prefix.reports}`,
    rawData: `/${prefix.reports}/rawData`,
    bottleneck: `/${prefix.reports}/bottleneck`,
    ftr: `/${prefix.reports}/ftr`,
    smd: `/${prefix.reports}/smd`,
    concordance: `/${prefix.reports}/concordance`,
    decomplexity: `/${prefix.reports}/decomplexity`,
    decomplexityUpdate: `/${prefix.reports}/decomplexity/update`,
    portfolio: `/${prefix.reports}/portfolio`,
    stepsTeams: `/${prefix.reports}/teamsandsteps`,
  },
  unauth: {
    root: `/${prefix.unauth}`,
  },
  noMatch: {
    root: `/${prefix.NoMatch}`,
  },
  accf: {
    root: `/${prefix.accf}`,
  },

  costingRequests: (rfsNumber = ':rfsNumber') => ({
    root: `/${prefix.costingRequests}/home`,
    raiseticket: {
      root: `/${prefix.costingRequests}/raiseticket`,
    },
    admin: {
      root: `/${prefix.costingRequests}/admin`,
     
    },
    
    profile: {
      root: `/${prefix.costingRequests}/profile`,
    },
    

    request: `/${prefix.costingRequests}/${rfsNumber}?`,
    create: `/${prefix.costingRequests}/create`,
    overview: `/${prefix.costingRequests}/overview/${rfsNumber}`,
  }),
};
