import { MIME_TYPES } from "@mantine/dropzone";

export const ACCEPTED_MIME_TYPE = [
    MIME_TYPES.png,
    MIME_TYPES.jpeg,
    MIME_TYPES.svg,
    MIME_TYPES.gif,
    MIME_TYPES.pdf,
    MIME_TYPES.csv,
    MIME_TYPES.doc,
    MIME_TYPES.docx,
    MIME_TYPES.xls,
    MIME_TYPES.xlsx,
    MIME_TYPES.pptx,
    MIME_TYPES.ppt,
    '.msg',
    '.eml',
  ];