import { useParams, useSearchParams } from 'react-router-dom';
import { tabStyles } from '../../../../utils/helpers';
import { Tabs } from '@mantine/core';
import { Packmat } from './packmat';
import { Mother } from './mother';
import { useEffect, useState } from 'react';
import { axios } from '../../../../utils/axios';
import moment from 'moment';

const tabs = [
  {
    name: 'PACKMAT',
    value: 'packmat',
    component: (props) => <Packmat {...props} />,
  },

  {
    name: 'MOTHER SKU',
    value: 'mother',
    component: (props) => <Mother {...props} />,
  },
];

export const BomComponentPrice = ({ activeTab }) => {
  const { rfsNumber } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState();
  const [skus, setSkus] = useState();

  const activeSubTab = searchParams.get('subtab') || 'packmat';

  useEffect(() => {
    if (activeTab === 'bomComponentPrice') fetch();
  }, [activeTab]);

  const fetch = async (refresh = false) => {
    try {
      setLoading(true);
      if (refresh) await axios.get(`costing-request/step/bom-component-refresh/${rfsNumber}`);
      const response = await axios.get(`/costing-request/step/bom-price/${rfsNumber}`);
      const data = response.data.data.map((item) => {
        const material = item.IntCrSKUPlantBOMs[0] || {};
        return {
          id: item.id,
          component_id: item.component_id,
          sku_number: item.int_cr_sku_selector.sku,
          sku_description: item.int_cr_sku_selector.sku_description,
          material_number: material.component,
          material_description: material.component_description,
          material_status: material.plant_specific_status,
          price_updated: material.price_submitted_on
            ? moment(material.price_submitted_on).format('DD/MM/YYYY')
            : 'N/A',
          not_procurement_material: material.not_procurement_material,
          urgency: item?.int_cr_record?.urgency || 'N/A',
          producing_plant: item.int_cr_producing_plant.producing_plant,
          base_unit: material.price_unit,
          uom: material.uom,
          currency: material.currency,
          cost_value: material.planned_price_1,
          fpna_analyst_responsible: item?.costing_ticket_assigned?.name || 'N/A',
          asm: material.asm,
          costing_request_id: item.costing_request_id,
          start_date: item.start_date,
          price_to_be_updated_by: item?.step_assigned?.name || 'N/A',
          mother_costing_request_id: item?.GeneralDetails?.costing_request_id || 'N/A',
          mother_costing_request_created_on: item?.GeneralDetails
            ? moment(item?.GeneralDetails?.costing_request_id.createdAt).format('DD/MM/YYYY')
            : 'N/A',
          pack_mat_price_requesting_date: item.start_date
            ? moment(item.start_date).format('DD/MM/YYYY')
            : 'N/A',
          pack_mat_price_requesting_date_plain: item.start_date,
          monther_cr_status: item?.GeneralDetails?.IntCrStatus?.status || 'N/A',
          comment: material.comment,
          production_plant: item?.int_cr_producing_plant?.producing_plant,
        };
      });
      setSkus(data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Tabs
        value={activeSubTab}
        onTabChange={(value) => setSearchParams({ tab: activeTab, subtab: value })}
        variant='unstyled'
        styles={tabStyles}
        className='prod-dev-tabs'
        color='#e5b611'
      >
        <Tabs.List>
          {tabs.map((tab) => (
            <Tabs.Tab value={tab.value} className='menu-tabs'>
              {tab.name}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {tabs.map((tab) => (
          <Tabs.Panel value={tab.value}>
            {tab.component({ activeSubTab, loading, skus, fetch })}
          </Tabs.Panel>
        ))}
      </Tabs>
    </>
  );
};
