import { Grid, Space, Text } from '@mantine/core';
import { getValueByKey } from '../../../../utils/helpers';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';
import { useContext } from 'react';
import { skuColumns } from '../../draft/table/skusTable';
import { plantColumns } from '../../draft/table/plantsTable';
import { PreviewTable } from '../../draft/table/previewTable';
import { StyledText } from '../../styles';

const infoItems = [
  {
    name: 'Requester Name',
    key: 'user.name',
  },
  // {
  //   name: 'Requester Team',
  //   key: 'user.name',
  //   offset: 2,
  // },
  {
    name: 'Urgency',
    key: 'urgency',
    offset: 2,
  },
  {
    name: 'Type of Goods',
    key: 'types_of_good',
  },

  {
    name: 'Type of SKU',
    key: 'types_of_sku',
    offset: 2,
  },
  {
    name: 'Type of Request',
    key: 'type_of_request',
  },
  {
    name: 'Sales District of the Buying Country',
    key: 'sales_district_of_buying_country',
    offset: 2,
  },
  {
    name: 'Stock Taking Order Type',
    key: 'stock_taking_order_type',
  },

  {
    name: 'Comment',
    key: 'comment',
    offset: 2,
  },
];

const Divider = () => (
  <Grid.Col span={12}>
    <Space h='sm' />
  </Grid.Col>
);

const Label = ({ children }) => (
  <>
    <StyledText fz='md' fw={500}>
      {children}{' '}
    </StyledText>
  </>
);

const SelectPlantButton = ({ label, offset, data }) => {
  const columns = label === 'Selected SKU' ? skuColumns : plantColumns(label.split(' ')[1]);

  return (
    <Grid.Col md={6} lg={5} offsetLg={offset}>
      <Grid align='center'>
        <Grid.Col span={4}>
          <Label>{label}</Label>
        </Grid.Col>

        <Grid.Col span={12}>
          <PreviewTable label={label} data={data} columns={columns} />
        </Grid.Col>
      </Grid>
    </Grid.Col>
  );
};

export const Overview = () => {
  const { info } = useContext(CostingRequestsContext);
  return (
    <>
      <Space h='md' />
      <Grid>
        {infoItems.map((item, index) => (
          <Grid.Col md={6} lg={5} offsetLg={item.offset}>
            <Grid>
              <Grid.Col span={6}>
                <Text fw={700} fz='sm'>
                  {item.name}
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text fz='sm'>{getValueByKey(info, item.key)}</Text>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        ))}

        <Divider />

        <SelectPlantButton label='Selected SKU' data={info?.['sku_data']} />
        <SelectPlantButton
          label='Select Producing Plant'
          offset={2}
          data={info?.['producing_plant_data']}
        />
        <Divider />
        <SelectPlantButton label='Selected Selling Plant' data={info?.['selling_plant_data']} />
        <SelectPlantButton
          label='Selected Buying Plant'
          offset={2}
          data={info?.['buying_plant_data']}
        />
      </Grid>
    </>
  );
};
