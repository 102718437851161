import React, { useMemo, useContext, useState, useEffect, useRef } from 'react';
import { axios } from '../../../utils/axios';
import { useTable, usePagination, useFilters } from 'react-table';
import { Select, MultiSelect, TextInput, Checkbox, Tabs, Switch } from '@mantine/core';
import './materialTable.css';

import { DatePickerInput } from '@mantine/dates';
import { CalendarIcon } from '@modulz/radix-icons';
import { RfsContext } from '../../../contexts/RfsContext';
import moment from 'moment';
import { MixoverChangeAlert } from './MixoverChangeAlert';
import {
  materialArtworkChangeOptions,
  materialTechnicalPackChangeOptions,
  materialTechnicalQualityChangeOptions,
  materialchangeOverOptions,
} from '../../../constants';
import withConfirm from '../../common/confirm-dialog';
import AffectedSkus from './affected-skus';
import { AppLoader } from '../../common/loader';
import { Table } from '../../../common/table/VeamScopeTable';
import { hasSpecialCharacters, removeSpecialCharacters } from '../../../utils/helpers';

const changeoverOptions = [
  {
    value: materialchangeOverOptions.hardChange,
    label: materialchangeOverOptions.hardChange,
  },
  {
    value: materialchangeOverOptions.softChange,
    label: materialchangeOverOptions.softChange,
  },
  {
    value: materialchangeOverOptions.mixChange,
    label: materialchangeOverOptions.mixChange,
  },
  {
    value: materialchangeOverOptions.notSoonerThan,
    label: materialchangeOverOptions.notSoonerThan,
  },
  {
    value: materialchangeOverOptions.notLaterThan,
    label: materialchangeOverOptions.notLaterThan,
  },
];

const materialModel = {
  material_number: null,
  technical_pack_change: null,
  technical_quality_change: null,
  artwork_change: null,
  changeover_type: null,
  mix_changeover: null,
  changeover_date: null,
  comment: null,
};

const MaterialTable = ({
  index,
  material,
  isSubmissionReview,
  errors = {},
  setFirstShipment,
  materialDropDown,
  confirm,
  materialsLabel,
  accordionState,
  materialAccordionIndex,
}) => {
  const { setForm, form } = useContext(RfsContext);
  const [materialsSearch, setMaterialsSearch] = useState({
    str: '',
    plant: '',
  });
  const [mixChangeOverAlert, setMixChangeOverAlert] = useState({
    opened: false,
  });
  const [rowChangeIndex, setRowChangeIndex] = useState(-1);
  const [materialPlants, setMaterialPlants] = useState([]);
  const [defaultSelections, setDefaultSelections] = useState({});
  const [plantsLoading, setPlantsLoading] = useState(false);
  const [showOnlySelected, setOnlyShowSelected] = useState(false);
  const [affectedSkus, setAffectedSkus] = useState({});

  const ref = useRef(null);
  ref.current = materialPlants;
  const search = useRef(null);
  search.current = materialsSearch;
  const defaultSelectionsRef = useRef(null);
  defaultSelectionsRef.current = defaultSelections;
  const materialsRef = useRef(null);
  materialsRef.current = materialDropDown;

  useEffect(() => {
    if (accordionState && accordionState[materialAccordionIndex]) {
      setDefaultSelections({});
      let plants = [...ref.current];
      let { scopeSelectedMaterials } = { ...form };

      const index = scopeSelectedMaterials.findIndex(
        (val) => val.changeover_type === materialchangeOverOptions.mixChange,
      );

      plants = plants.map((res) => {
        if (res.active) {
          const val = scopeSelectedMaterials.find((mat) => res.id === mat.id);

          return {
            ...res,
            changeover_type: index > -1 ? scopeSelectedMaterials[index].changeover_type : null,
            tech_spec_matrix: val.tech_spec_matrix,
            mix_changeover:
              index > -1
                ? val
                  ? val.mix_changeover
                  : scopeSelectedMaterials[index].changeover_type
                : null,
          };
        } else {
          return res;
        }
      });

      setMaterialPlants(plants);
    }
  }, [accordionState]);

  useEffect(() => {
    if (rowChangeIndex > -1) {
      let values = { ...form };
      if (ref.current[rowChangeIndex].id) {
        const index = values.scopeSelectedMaterials.findIndex(
          (val) => val.id === ref.current[rowChangeIndex].id,
        );

        if (index > -1) {
          values.scopeSelectedMaterials[index] = {
            ...values.scopeSelectedMaterials[index],
            ...ref.current[rowChangeIndex],
          };
        } else {
          values.scopeSelectedMaterials.push({
            ...ref.current[rowChangeIndex],
          });
        }
      } else {
        const isExist = values.scopeSelectedMaterials.findIndex(
          (val) => ref.current[rowChangeIndex].plant === val.plant,
        );
        if (isExist === -1)
          values.scopeSelectedMaterials.push({
            ...ref.current[rowChangeIndex],
          });
      }

      setRowChangeIndex(-1);
      setForm(values);
    }
  }, [rowChangeIndex]);

  useEffect(() => {
    let { scopeSelectedMaterials } = { ...form };
    const fetchPlants = (code) => {
      setPlantsLoading(true);
      axios
        .get(`/materials/plants/${code}`, {
          headers: {
            rfsId: form.rfsInformation.id,
          },
        })
        .then(
          (res) => {
            let plants = res.data.data.materials;

            plants = plants.map((plantInfo, key) => {
              const index = scopeSelectedMaterials.findIndex(
                (val) =>
                  val.plant === plantInfo.plant &&
                  val.material_code === material.selection_number &&
                  (!val.hasOwnProperty('active') || val.active),
              );

              const response =
                index > -1
                  ? {
                      ...scopeSelectedMaterials[index],
                      ...plantInfo,
                      active: true,
                      tech_spec_matrix: scopeSelectedMaterials[index].tech_spec_matrix,
                    }
                  : {
                      ...materialModel,
                      ...plantInfo,
                      scope_id: material.id,
                      id: `temp_${key}_${code}`,
                    };
              return response;
            });
            setMaterialPlants(plants);
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => setPlantsLoading(false));
    };

    if (accordionState && accordionState[materialAccordionIndex])
      fetchPlants(material.selection_number);
  }, [accordionState]);

  const getMinChangeOverDate = () => {
    const { first_shipment } = { ...form.rfsInformation };

    return moment(first_shipment, 'YYYY-MM-DD').isValid()
      ? moment(first_shipment, 'YYYY-MM-DD').toDate()
      : moment().add('days', 1).toDate();
  };

  const isDateValid = (data) => {
    return moment(data, 'YYYY-MM-DD').isValid();
  };

  const getFilteredIds = (filteredRows) => {
    const filteredIds = {};
    filteredRows.forEach((row) => {
      filteredIds[row.original.id] = true;
    });

    return filteredIds;
  };

  const handleDefaultSet = (event, type, filteredRows) => {
    const filteredIds = getFilteredIds([...filteredRows]);

    const value =
      event?.target?.type === 'checkbox'
        ? event.target.checked
        : ['comment', 'new_quantity', 'tech_spec_matrix'].includes(type)
        ? (event.target.value || '').trim().length === 0
          ? event.target.value.trim()
          : event.target.value
        : type === 'changeover_date'
        ? moment(event).isValid()
          ? moment(event).format('YYYY-MM-DD')
          : null
        : event;

    // default value set for headers only
    const defaultValues = { ...defaultSelectionsRef.current };
    defaultValues[type] = type === 'tech_spec_matrix' ? removeSpecialCharacters(value) : value;
    setDefaultSelections(defaultValues);

    let currentValues = [...ref.current];

    let values = { ...form };

    currentValues.forEach((val, key) => {
      if (!filteredIds[val.id]) return val[key];

      if (val.active || type === 'active') {
        if (type === 'tech_spec_matrix') {
          currentValues[key][type] = removeSpecialCharacters(value);
        } else {
          currentValues[key][type] = value;
        }

        if (
          type === 'changeover_type' &&
          [materialchangeOverOptions.softChange, materialchangeOverOptions.mixChange].includes(
            value,
          )
        )
          currentValues[key]['changeover_date'] = null;

        if (
          type === 'changeover_date' &&
          [materialchangeOverOptions.softChange, materialchangeOverOptions.mixChange].includes(
            currentValues[key]['changeover_type'],
          )
        )
          currentValues[key]['changeover_date'] = null;

        if (type === 'artwork_change') {
          currentValues[key]['artwork_change'] = isDisabled(currentValues[key]) ? null : value;
        }

        if (type === 'tech_spec_matrix') {
          currentValues[key]['tech_spec_matrix'] = isDisabled(currentValues[key]) ? null : value;
        }

        if (type === 'material_number') {
          const isRemoval =
            currentValues[key].technical_pack_change &&
            currentValues[key].technical_pack_change.includes(
              materialTechnicalPackChangeOptions.removal,
            );
          currentValues[key]['material_number'] = isRemoval ? null : value;
        }

        if (type === 'technical_pack_change') {
          const isRemoval =
            currentValues[key].technical_pack_change &&
            currentValues[key].technical_pack_change.includes(
              materialTechnicalPackChangeOptions.removal,
            );
          if (isRemoval) {
            currentValues[key]['material_number'] = null;
            currentValues[key]['material_type'] = null;
            currentValues[key]['artwork_change'] = null;
            currentValues[key]['tech_spec_matrix'] = null;
          }
        }

        if (type === 'active') {
          const index = values.scopeSelectedMaterials.findIndex(
            (mat) =>
              mat.material_code === val.material_code &&
              mat.plant === val.plant &&
              parseInt(mat.scope_id) === parseInt(val.scope_id),
          );

          const mixChangeover =
            values.scopeSelectedMaterials.length > 0 &&
            values.scopeSelectedMaterials[0].mix_changeover
              ? values.scopeSelectedMaterials[0].mix_changeover
              : null;

          if (index > -1) {
            values.scopeSelectedMaterials[index]['active'] = value;
            if (mixChangeover) {
              values.scopeSelectedMaterials[index]['mix_changeover'] = value ? mixChangeover : null;
              values.scopeSelectedMaterials[index]['changeover_type'] = value
                ? materialchangeOverOptions.mixChange
                : null;

              currentValues[key]['mix_changeover'] = value ? mixChangeover : null;

              currentValues[key]['changeover_type'] = value
                ? materialchangeOverOptions.mixChange
                : null;
            }
          } else {
            values.scopeSelectedMaterials.push({
              ...val,
              active: value,
              mix_changeover: mixChangeover ? mixChangeover : null,
              changeover_type: mixChangeover ? materialchangeOverOptions.mixChange : null,
            });

            currentValues[key]['mix_changeover'] = mixChangeover ? mixChangeover : null;

            currentValues[key]['changeover_type'] = mixChangeover
              ? materialchangeOverOptions.mixChange
              : null;
          }

          setForm(values);
        }
      }

      if (value === materialchangeOverOptions.mixChange) {
        let values = { ...form };
        values.scopeSelectedMaterials.forEach((val, key) => {
          if (values.scopeSelectedMaterials[key].active) {
            values.scopeSelectedMaterials[key]['changeover_type'] = value;
          }
        });

        setForm(values);
      }
      return currentValues[key];
    });

    // setMaterialPlants(currentValues);

    if (type !== 'active') {
      values.scopeSelectedMaterials.map((val, key) => {
        if (!filteredIds[val.id]) return values.scopeSelectedMaterials[key];

        if ((val.active || type === 'active') && parseInt(val.scope_id) === material.id) {
          values.scopeSelectedMaterials[key][type] = value;

          if (type === 'tech_spec_matrix') {
            values.scopeSelectedMaterials[key][type] = removeSpecialCharacters(value);
          } else {
            values.scopeSelectedMaterials[key][type] = value;
          }

          if (
            type === 'changeover_type' &&
            [materialchangeOverOptions.softChange, materialchangeOverOptions.mixChange].includes(
              value,
            )
          )
            values.scopeSelectedMaterials[key]['changeover_date'] = null;

          if (
            type === 'changeover_date' &&
            [materialchangeOverOptions.softChange, materialchangeOverOptions.mixChange].includes(
              values.scopeSelectedMaterials[key]['changeover_type'],
            )
          )
            values.scopeSelectedMaterials[key]['changeover_date'] = null;

          if (type === 'artwork_change') {
            values.scopeSelectedMaterials[key]['artwork_change'] = isDisabled(
              values.scopeSelectedMaterials[key],
            )
              ? null
              : value;
          }

          if (type === 'tech_spec_matrix') {
            values.scopeSelectedMaterials[key]['tech_spec_matrix'] = isDisabled(
              values.scopeSelectedMaterials[key],
            )
              ? null
              : value;
          }

          if (type === 'material_number') {
            const isRemoval =
              values.scopeSelectedMaterials[key].technical_pack_change &&
              values.scopeSelectedMaterials[key].technical_pack_change.includes(
                materialTechnicalPackChangeOptions.removal,
              );
            values.scopeSelectedMaterials[key]['material_number'] = isRemoval ? null : value;
          }

          if (type === 'technical_pack_change') {
            const isRemoval =
              values.scopeSelectedMaterials[key].technical_pack_change &&
              values.scopeSelectedMaterials[key].technical_pack_change.includes(
                materialTechnicalPackChangeOptions.removal,
              );
            if (isRemoval) {
              values.scopeSelectedMaterials[key]['material_number'] = null;
              values.scopeSelectedMaterials[key]['material_type'] = null;
              values.scopeSelectedMaterials[key]['artwork_change'] = null;
              values.scopeSelectedMaterials[key]['tech_spec_matrix'] = null;
            }
          }
        }

        return values.scopeSelectedMaterials[key];
      });

      setForm(values);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: ({ filteredRows }) => {
          return !isSubmissionReview ? (
            <Checkbox
              disabled={isSubmissionReview}
              onChange={(event) => handleDefaultSet(event, 'active', filteredRows)}
            />
          ) : (
            ''
          );
        },
        disableSortBy: true,
        id: 'checkbox',
        Cell: ({ row }) => (
          <Checkbox
            disabled={isSubmissionReview}
            checked={row.original.active}
            onChange={(event) => {
              if (!event.target.checked) {
                confirm(() => handleChange({ target: { checked: false, type: 'checkbox' } }, row), {
                  title: 'Confirm!',
                  description: 'This will remove all values',
                  confirmationText: 'Ok',
                });
              } else {
                handleChange(event, row);
              }
            }}
          />
        ),
      },
      {
        Header: 'Plant',
        accessor: 'plant',
        canFilter: true,
        minWidth: '100px',
        maxWidth: '100px',
      },
      {
        Header: 'Plant Desc',
        accessor: 'PlantDescription',
        canFilter: true,
      },
      {
        Header: 'Status',
        accessor: 'plant_specific_status',
        canFilter: true,
        minWidth: '100px',
        maxWidth: '100px',
      },
      {
        Header: ({ filteredRows }) => {
          return (
            <div style={{ minWidth: '350px' }}>
              <span>Materials List</span>
              {!isSubmissionReview && (
                <Select
                  size='md'
                  radius='md'
                  onChange={(event) => handleDefaultSet(event, 'material_number', filteredRows)}
                  placeholder='Select'
                  searchable
                  clearable
                  limit={100}
                  data={materialsRef.current}
                  onSearchChange={(query) =>
                    setMaterialsSearch({
                      str: query,
                      plant: null,
                    })
                  }
                />
              )}
            </div>
          );
        },
        disableSortBy: true,
        canFilter: false,
        minWidth: '300px',
        maxWidth: '300px',
        id: 'material_number',
        Cell: ({ row }) => {
          return isSubmissionReview ? (
            <TextInput
              size='sm'
              disabled={true}
              error={row.original.active && errors[row.original.id]?.material_number}
              value={
                !row.original.active
                  ? null
                  : row.original.material_number
                  ? row.original.material_number
                  : row.original.material_type
              }
            />
          ) : (
            <Select
              size='md'
              radius='md'
              error={row.original.active && errors[row.original.id]?.material_number}
              value={
                !row.original.active
                  ? null
                  : row.original.material_number
                  ? row.original.material_number
                  : row.original.material_type
              }
              nothingFound='Nothing found'
              onChange={(event) => handleMaterialChange(row, event)}
              disabled={
                (row.original.technical_pack_change &&
                  row.original.technical_pack_change.includes(
                    materialTechnicalPackChangeOptions.removal,
                  )) ||
                isSubmissionReview ||
                !row.original.active
              }
              placeholder='Select'
              searchable
              clearable
              limit={100}
              data={materialsRef.current}
              onSearchChange={(query) =>
                setMaterialsSearch({
                  str: query,
                  plant: row.original.plant,
                })
              }
            />
          );
        },
      },
      {
        Header: ({ filteredRows }) => {
          return (
            <div>
              <span>Technical Pack Change</span>
              {!isSubmissionReview && (
                <MultiSelect
                  radius='md'
                  onChange={(value) =>
                    handleDefaultSet(value, 'technical_pack_change', filteredRows)
                  }
                  data={[
                    {
                      value: materialTechnicalPackChangeOptions.thickness,
                      label: materialTechnicalPackChangeOptions.thickness,
                      disabled:
                        isDisabled(defaultSelectionsRef.current) ||
                        isNaSelected(defaultSelectionsRef.current),
                    },
                    {
                      value: materialTechnicalPackChangeOptions.drawing,
                      label: materialTechnicalPackChangeOptions.drawing,
                      disabled:
                        isDisabled(defaultSelectionsRef.current) ||
                        isNaSelected(defaultSelectionsRef.current),
                    },
                    {
                      value: materialTechnicalPackChangeOptions.removal,
                      label:
                        materialTechnicalPackChangeOptions.removal ||
                        isNaSelected(defaultSelectionsRef.current),
                    },
                    {
                      value: 'N/A',
                      label: 'N/A',
                      disabled: isDisabled(defaultSelectionsRef.current),
                    },
                  ]}
                  searchable
                  nothingFound='Nothing found'
                />
              )}
            </div>
          );
        },
        disableSortBy: true,
        canFilter: false,
        maxWidth: '100px',
        id: 'technical_pack_change', // accessor is the "key" in the data
        Cell: ({ row }) => (
          <div className='custom-multi-select'>
            <MultiSelect
              radius='md'
              value={
                !row.original.active
                  ? []
                  : typeof row.original.technical_pack_change === 'object'
                  ? row.original.technical_pack_change
                  : row.original.technical_pack_change.split(',')
              }
              onChange={(value) => handleTechnicalPackChange(row, value)}
              disabled={
                isPackChangeDisabled(row.original) || isSubmissionReview || !row.original.active
              }
              error={row.original.active && errors[row.original.id]?.technical_pack_change}
              data={[
                {
                  value: materialTechnicalPackChangeOptions.thickness,
                  label: materialTechnicalPackChangeOptions.thickness,
                  disabled: isDisabled(row.original) || isNaSelected(row.original),
                },
                {
                  value: materialTechnicalPackChangeOptions.drawing,
                  label: materialTechnicalPackChangeOptions.drawing,
                  disabled: isDisabled(row.original) || isNaSelected(row.original),
                },
                {
                  value: materialTechnicalPackChangeOptions.removal,
                  label: materialTechnicalPackChangeOptions.removal,
                  disabled: isNaSelected(row.original),
                },
                {
                  value: 'N/A',
                  label: 'N/A',
                  disabled:
                    isDisabled(row.original) ||
                    isNaSelected(row.original, 'technical_quality_change'),
                },
              ]}
              searchable
              nothingFound='Nothing found'
            />
          </div>
        ),
      },
      {
        Header: ({ filteredRows }) => {
          return (
            <div>
              <span>Technical Quality Change</span>
              {!isSubmissionReview && (
                <MultiSelect
                  radius='md'
                  onChange={(event) =>
                    handleDefaultSet(event, 'technical_quality_change', filteredRows)
                  }
                  data={[
                    {
                      value: materialTechnicalQualityChangeOptions.substrate,
                      label: materialTechnicalQualityChangeOptions.substrate,
                      disabled:
                        isDisabled(defaultSelectionsRef.current) ||
                        isNaSelected(defaultSelectionsRef.current, 'technical_quality_change'),
                    },
                    {
                      value: materialTechnicalQualityChangeOptions.coating,
                      label: materialTechnicalQualityChangeOptions.coating,
                      disabled:
                        isDisabled(defaultSelectionsRef.current) ||
                        isNaSelected(defaultSelectionsRef.current, 'technical_quality_change'),
                    },
                    {
                      value: materialTechnicalQualityChangeOptions.prodTech,
                      label: materialTechnicalQualityChangeOptions.prodTech,
                      disabled:
                        isDisabled(defaultSelectionsRef.current) ||
                        isNaSelected(defaultSelectionsRef.current, 'technical_quality_change'),
                    },
                    {
                      value: materialTechnicalQualityChangeOptions.compQuality,
                      label: materialTechnicalQualityChangeOptions.compQuality,
                      disabled:
                        isDisabled(defaultSelectionsRef.current) ||
                        isNaSelected(defaultSelectionsRef.current, 'technical_quality_change'),
                    },
                    {
                      value: 'N/A',
                      label: 'N/A',
                      disabled: isNaSelected(defaultSelectionsRef.current),
                    },
                  ]}
                  searchable
                  nothingFound='Nothing found'
                />
              )}
            </div>
          );
        },
        disableSortBy: true,
        canFilter: false,
        minWidth: '100px',
        maxWidth: '100px',
        id: 'technical_quality_change',
        Cell: ({ row }) => (
          <div className='custom-multi-select'>
            <MultiSelect
              radius='md'
              value={
                !row.original.active
                  ? []
                  : typeof row.original.technical_quality_change === 'object'
                  ? row.original.technical_quality_change
                  : row.original.technical_quality_change.split(',')
              }
              disabled={isDisabled(row.original) || isSubmissionReview || !row.original.active}
              error={row.original.active && errors[row.original.id]?.technical_quality_change}
              onChange={(event) => handleQualityChange(row, event)}
              data={[
                {
                  value: materialTechnicalQualityChangeOptions.substrate,
                  label: materialTechnicalQualityChangeOptions.substrate,

                  disabled:
                    isDisabled(row.original) ||
                    isNaSelected(row.original, 'technical_quality_change'),
                },
                {
                  value: materialTechnicalQualityChangeOptions.coating,
                  label: materialTechnicalQualityChangeOptions.coating,
                  disabled:
                    isDisabled(row.original) ||
                    isNaSelected(row.original, 'technical_quality_change'),
                },
                {
                  value: materialTechnicalQualityChangeOptions.prodTech,
                  label: materialTechnicalQualityChangeOptions.prodTech,
                  disabled:
                    isDisabled(row.original) ||
                    isNaSelected(row.original, 'technical_quality_change'),
                },
                {
                  value: materialTechnicalQualityChangeOptions.compQuality,
                  label: materialTechnicalQualityChangeOptions.compQuality,
                  disabled:
                    isDisabled(row.original) ||
                    isNaSelected(row.original, 'technical_quality_change'),
                },
                {
                  value: 'N/A',
                  label: 'N/A',
                  disabled: isDisabled(row.original) || isNaSelected(row.original),
                },
              ]}
              searchable
              nothingFound='Nothing found'
            />
          </div>
        ),
      },
      {
        Header: ({ filteredRows }) => {
          return (
            <div style={{ minWidth: '300px' }}>
              <span>Artwork Management</span>
              {!isSubmissionReview && (
                <Select
                  size='md'
                  radius='md'
                  onChange={(event) => handleDefaultSet(event, 'artwork_change', filteredRows)}
                  placeholder='Select'
                  searchable
                  data={[
                    {
                      value: materialArtworkChangeOptions.text,
                      label: materialArtworkChangeOptions.text,
                    },
                    {
                      value: materialArtworkChangeOptions.newColours,
                      label: materialArtworkChangeOptions.newColours,
                    },
                    {
                      value: materialArtworkChangeOptions.existingColours,
                      label: materialArtworkChangeOptions.existingColours,
                    },
                    {
                      value: materialArtworkChangeOptions.others,
                      label: materialArtworkChangeOptions.others,
                    },
                  ]}
                />
              )}
            </div>
          );
        },
        disableSortBy: true,
        canFilter: false,
        minWidth: '300px',
        maxWidth: '300px',
        id: 'artwork_change', // accessor is the "key" in the data
        Cell: ({ row }) => (
          <Select
            size='md'
            radius='md'
            value={!row.original.active ? null : row.original.artwork_change}
            error={row.original.active && errors[row.original.id]?.artwork_change}
            onChange={(event) => handleArtworkChange(row, event)}
            disabled={isDisabled(row.original) || isSubmissionReview || !row.original.active}
            placeholder='Select'
            searchable
            clearable
            data={[
              {
                value: materialArtworkChangeOptions.text,
                label: materialArtworkChangeOptions.text,
              },
              {
                value: materialArtworkChangeOptions.newColours,
                label: materialArtworkChangeOptions.newColours,
              },
              {
                value: materialArtworkChangeOptions.existingColours,
                label: materialArtworkChangeOptions.existingColours,
              },
              {
                value: materialArtworkChangeOptions.others,
                label: materialArtworkChangeOptions.others,
              },
            ]}
          />
        ),
      },

      // {
      //   Header: 'Tech Spec Matrix reference',
      //   accessor: 'tech_spec_matrix', // accessor is the "key" in the data
      //   canFilter: false,
      //   disableSortBy: true,
      //   Cell: ({ row }) => {
      //     return row.original.tech_spec_matrix
      //       ? row.original.tech_spec_matrix
      //       : 'No data available';
      //   },
      // },
      {
        Header: ({ filteredRows }) => (
          <div style={{ marginBottom: '2px' }}>
            <span>Technical Specification</span>
            {isSubmissionReview ? null : (
              <TextInput
                style={{ width: '400px' }}
                placeholder='Specify tech. spec. code or reference material (ERP code), if exact copy.'
                maxLength={20}
                value={defaultSelectionsRef.current['tech_spec_matrix'] || ''}
                onChange={(event) => handleDefaultSet(event, 'tech_spec_matrix', filteredRows)}
              />
            )}
          </div>
        ),
        id: 'tech_spec_matrix',
        paddingBottom: isSubmissionReview ? '0px' : '40px',
        canFilter: false,
        disableSortBy: true,
        Cell: ({ row }) => (
          <TextInput
            name='tech_spec_matrix'
            placeholder='Specify tech. spec. code or reference material (ERP code), if exact copy.'
            maxLength={20}
            error={
              errors[row.original.id]?.tech_spec_matrix ||
              errors[row.original.id]?.max_tech_spec_value_exceeded
            }
            value={row.original.tech_spec_matrix || ''}
            onChange={(event) => handleChange(event, row)}
            disabled={isDisabled(row.original) || isSubmissionReview || !row.original.active}
          />
        ),
      },
      {
        Header: ({ filteredRows }) => {
          return (
            <div>
              <span>Changeover Type</span>
              {!isSubmissionReview && (
                <Select
                  size='md'
                  radius='md'
                  onChange={(event) => handleDefaultSet(event, 'changeover_type', filteredRows)}
                  placeholder='Select'
                  searchable
                  clearable
                  data={changeoverOptions.filter((val) => {
                    if (
                      !mixChangeOverEligibility() &&
                      val.label === materialchangeOverOptions.mixChange
                    ) {
                      return false;
                    }
                    return val;
                  })}
                />
              )}
            </div>
          );
        },
        disableSortBy: true,
        canFilter: false,
        minWidth: '200px',
        maxWidth: '200px',
        id: 'changeover_type', // accessor is the "key" in the data
        Cell: ({ row }) => (
          <Select
            size='md'
            radius='md'
            value={!row.original.active ? null : row.original.changeover_type}
            error={row.original.active && errors[row.original.id]?.changeover_type}
            onChange={(event) => handleChangeoverType(event, row, 'changeover_type')}
            disabled={isSubmissionReview || !row.original.active}
            placeholder='Select'
            searchable
            data={changeoverOptions.filter((val) => {
              if (
                !mixChangeOverEligibility() &&
                val.label === materialchangeOverOptions.mixChange
              ) {
                return false;
              }
              return val;
            })}
          />
        ),
      },
      {
        Header: ({ filteredRows }) => {
          return (
            <div style={{ display: 'flex', height: '100%', marginTop: '5px' }}>
              <span>Leading Material</span>
            </div>
          );
        },
        id: 'mix_changeover',
        disableSortBy: true,
        Cell: ({ row }) =>
          row.original.changeover_type === materialchangeOverOptions.mixChange &&
          form.scopeSelectedMaterials.length > 1 ? (
            <Select
              size='md'
              radius='md'
              value={!row.original.active ? null : row.original.mix_changeover}
              onChange={(event) => handleMixOverChange(event, row, 'mix_changeover')}
              placeholder='Select'
              searchable
              disabled={!row.original.active || isSubmissionReview}
              data={getMixChangeOverOptions() || []}
            />
          ) : (
            ''
          ),
      },
      {
        Header: ({ filteredRows }) => {
          return (
            <div>
              <span>Changeover Date</span>
              {!isSubmissionReview && (
                <DatePickerInput
                  styles={{
                    arrow: {
                      display: !isDateValid(form.rfsInformation.first_shipment) ? 'none' : 'block',
                    },
                    dropdown: {
                      display: !isDateValid(form.rfsInformation.first_shipment) ? 'none' : 'block',
                    },
                  }}
                  onChange={(event) => handleDefaultSet(event, 'changeover_date', filteredRows)}
                  onClick={(event) => {
                    if (!isDateValid(form.rfsInformation.first_shipment)) setFirstShipment(true);
                  }}
                  minDate={getMinChangeOverDate()}
                  initialMonth={getMinChangeOverDate()}
                  numberOfColumns={2}
                  radius='md'
                  icon={<CalendarIcon color='#e5b611' />}
                />
              )}
            </div>
          );
        },
        disableSortBy: true,
        minWidth: '50px',
        id: 'changeover_date',
        Cell: ({ row }) => (
          <DatePickerInput
            styles={{
              arrow: {
                display: !isDateValid(form.rfsInformation.first_shipment) ? 'none' : 'block',
              },
              dropdown: {
                display: !isDateValid(form.rfsInformation.first_shipment) ? 'none' : 'block',
              },
            }}
            value={
              !row.original.active
                ? null
                : row.original.changeover_date
                ? moment(row.original.changeover_date, 'YYYY-MM-DD').toDate()
                : null
            }
            error={row.original.active && errors[row.original.id]?.changeover_date}
            onChange={(event) => handleChangeOverDate(row, event)}
            disabled={
              isChangeOverDateDisabled(row.original.changeover_type) ||
              isSubmissionReview ||
              !row.original.active
            }
            onClick={(event) => {
              if (!isDateValid(form.rfsInformation.first_shipment)) setFirstShipment(true);
            }}
            minDate={getMinChangeOverDate()}
            initialMonth={getMinChangeOverDate()}
            numberOfColumns={2}
            radius='md'
            icon={<CalendarIcon color='#e5b611' />}
          />
        ),
      },
      {
        Header: ({ filteredRows }) => {
          return (
            <div>
              <span>Comment Box</span>
              {!isSubmissionReview && (
                <TextInput
                  radius='md'
                  name='comment'
                  placeholder='Detailed Description of the changes'
                  onChange={(event) => handleDefaultSet(event, 'comment', filteredRows)}
                  style={{
                    input: { width: '50px', height: '1rem' },
                    width: '20rem',
                  }}
                />
              )}
            </div>
          );
        },
        disableSortBy: true,
        minWidth: '200px',
        id: 'comment',
        canFilter: false,
        Cell: ({ row }) => {
          return (
            <TextInput
              radius='md'
              error={row.original.active && errors[row.original.id]?.comment}
              value={!row.original.active ? '' : row.original.comment}
              name='comment'
              onChange={(event) => handleChange(event, row)}
              disabled={isSubmissionReview || !row.original.active}
              style={{
                input: { width: '50px', height: '1rem' },
              }}
            />
          );
        },
      },
    ],
    [
      errors,
      form.scopeSelectedMaterials,
      form.rfsInformation.first_shipment,
      materialsLabel,
      showOnlySelected,
    ],
  );

  const isDisabled = (data) => {
    return (
      (data.technical_pack_change &&
        data.technical_pack_change.includes(materialTechnicalPackChangeOptions.removal)) ||
      (data.material_number && data.material_number.toLowerCase() !== 'new material')
    );
  };

  const isNaSelected = (data, type = 'technical_pack_change') => {
    return data[type] && data[type].includes('N/A');
  };

  const isPackChangeDisabled = (data) => {
    return data.material_number && data.material_number.toLowerCase() !== 'new material';
  };

  const isChangeOverDateDisabled = (data) => {
    return (
      (data && data === materialchangeOverOptions.softChange) ||
      data === materialchangeOverOptions.mixChange
    );
  };

  const handleChange = (event, row) => {
    let currentValues = [...ref.current];
    const rowIndex = currentValues.findIndex(
      (val) => val.id === row.original.id && val.scope_id === row.original.scope_id,
    );

    if (event.target.type === 'checkbox') {
      const mixChangeover =
        form.scopeSelectedMaterials.length > 0 && form.scopeSelectedMaterials[0].mix_changeover
          ? form.scopeSelectedMaterials[0].mix_changeover
          : null;
      const mixChangeOverType = (form.scopeSelectedMaterials || []).find(
        (l) => l.active && l.changeover_type === materialchangeOverOptions.mixChange,
      );
      currentValues[rowIndex]['active'] = event.target.checked;
      currentValues[rowIndex]['material_number'] = null;
      currentValues[rowIndex]['material_type'] = null;
      currentValues[rowIndex]['technical_pack_change'] = null;
      currentValues[rowIndex]['technical_quality_change'] = null;
      currentValues[rowIndex]['artwork_change'] = null;
      currentValues[rowIndex]['changeover_type'] = mixChangeOverType
        ? materialchangeOverOptions.mixChange
        : null;
      currentValues[rowIndex]['mix_changeover'] = null;
      currentValues[rowIndex]['changeover_date'] = null;
      currentValues[rowIndex]['comment'] = null;
      currentValues[rowIndex]['tech_spec_matrix'] = null;

      if (mixChangeover) {
        currentValues[rowIndex]['mix_changeover'] = event.target.checked ? mixChangeover : null;
      }
    } else {
      const value =
        (event.target.value || '').trim().length === 0
          ? event.target.value.trim()
          : event.target.value;
      if (event.target.name === 'tech_spec_matrix') {
        currentValues[rowIndex][event.target.name] = !hasSpecialCharacters(value)
          ? value
          : currentValues[rowIndex][event.target.name];
      } else {
        currentValues[rowIndex][event.target.name] = value;
      }
    }

    ref.current = currentValues;
    setRowChangeIndex(rowIndex);
  };

  const mixChangeOverEligibility = () => {
    const scopeIds = { ...form.selectedMaterials };
    let activeCount = 0;
    for (let id of Object.keys(scopeIds)) {
      const materials = ([...form.scopeSelectedMaterials] || []).filter(
        (mat) => mat.active && mat.material_code === id,
      );

      if (materials.length > 0) activeCount = activeCount + 1;

      if (activeCount > 1) break;
    }
    return activeCount > 1;
  };

  const setChangeOverType = (value, row, name) => {
    let currentValues = [...ref.current];
    const rowIndex = currentValues.findIndex(
      (val) => val.id === row.original.id && val.scope_id === row.original.scope_id,
    );
    currentValues[rowIndex][name] = value;
    if ([materialchangeOverOptions.softChange, materialchangeOverOptions.mixChange].includes(value))
      currentValues[rowIndex]['changeover_date'] = null;

    setRowChangeIndex(rowIndex);

    // if (value === materialchangeOverOptions.softChange) handleChangeOverDate(row);
  };

  const handleChangeoverType = (value, row, name) => {
    if (row.original.changeover_type === materialchangeOverOptions.mixChange) {
      setMixChangeOverAlert({
        opened: true,
        callback: () => {
          handleMixOverChange(null, row, 'changeover_type');
          handleMixOverChange(null, row, 'mix_changeover');
          // handleSelectChange(value, row, name, 'resetAllOthers');
        },
      });
    } else {
      if (value === materialchangeOverOptions.mixChange) {
        handleMixOverChange(value, row, name);
      } else {
        setChangeOverType(value, row, name);
      }
    }
  };

  const handleMixOverChange = (value, row, name) => {
    let currentValues = [...ref.current];

    currentValues.forEach((val, key) => {
      if (currentValues[key].active) {
        if (!value) {
          currentValues[key]['changeover_type'] = value;
          currentValues[key]['mix_changeover'] = value;
        } else {
          currentValues[key][name] = value;
          currentValues[row.index]['changeover_date'] = null;
        }
      }
    });
    setMaterialPlants(currentValues);

    let values = { ...form };
    values.scopeSelectedMaterials.forEach((val, key) => {
      if (values.scopeSelectedMaterials[key].active) {
        if (!value) {
          values.scopeSelectedMaterials[key]['changeover_type'] = value;
          values.scopeSelectedMaterials[key]['mix_changeover'] = value;
        } else {
          values.scopeSelectedMaterials[key][name] = value;
        }
      }
    });

    setForm(values);
  };

  const getMixChangeOverOptions = () => {
    let materials = [];
    (form.scopeSelectedMaterials || []).forEach((element) => {
      materials.push(element.material_code);
    });

    materials = [...new Set(materials)];

    return materials;
  };

  const resetFields = (fields, row) => {
    if (fields.includes('material_number')) handleMaterialChange(row);
    if (fields.includes('technical_pack_change')) handleTechnicalPackChange(row);
    if (fields.includes('technical_quality_change')) handleQualityChange(row);
    if (fields.includes('artwork_change')) handleArtworkChange(row);
  };

  const handleMaterialChange = (row, value = '') => {
    let currentValues = [...ref.current];
    const rowIndex = currentValues.findIndex(
      (val) => val.id === row.original.id && val.scope_id === row.original.scope_id,
    );
    const currentMaterialNumber = currentValues[rowIndex]['material_number'];
    const resetRequired =
      (currentMaterialNumber === 'New Material' ||
        currentMaterialNumber === '' ||
        !currentMaterialNumber) &&
      value !== 'New Material' &&
      value !== '';

    currentValues[rowIndex]['material_number'] = value;

    if (resetRequired) {
      resetFields(['technical_pack_change', 'technical_quality_change', 'artwork_change'], row);
    }

    setRowChangeIndex(rowIndex);
  };

  const handleTechnicalPackChange = (row, value = '') => {
    let selectedValue = [];
    if (value.includes(materialTechnicalPackChangeOptions.removal)) {
      selectedValue = [materialTechnicalPackChangeOptions.removal];
    } else {
      selectedValue = value;
    }

    let currentValues = [...ref.current];
    const rowIndex = currentValues.findIndex(
      (val) => val.id === row.original.id && val.scope_id === row.original.scope_id,
    );
    currentValues[rowIndex]['technical_pack_change'] = selectedValue;

    if (value.includes(materialTechnicalPackChangeOptions.removal)) {
      resetFields(['material_number', 'technical_quality_change', 'artwork_change'], row);
      currentValues[rowIndex]['mix_changeover'] = '';
    }

    setRowChangeIndex(rowIndex);
  };

  const handleQualityChange = (row, value = '') => {
    let currentValues = [...ref.current];
    const rowIndex = currentValues.findIndex(
      (val) => val.id === row.original.id && val.scope_id === row.original.scope_id,
    );
    currentValues[rowIndex]['technical_quality_change'] = value;
    setRowChangeIndex(rowIndex);
  };

  const handleArtworkChange = (row, value = '') => {
    let currentValues = [...ref.current];
    const rowIndex = currentValues.findIndex(
      (val) => val.id === row.original.id && val.scope_id === row.original.scope_id,
    );
    currentValues[rowIndex]['artwork_change'] = value;
    setRowChangeIndex(rowIndex);
  };

  const handleChangeOverDate = (row, value = '') => {
    let currentValues = [...ref.current];
    const rowIndex = currentValues.findIndex(
      (val) => val.id === row.original.id && val.scope_id === row.original.scope_id,
    );
    currentValues[rowIndex]['changeover_date'] = moment(value).isValid()
      ? moment(value).format('YYYY-MM-DD')
      : null;
    setRowChangeIndex(rowIndex);
  };

  const filterSelectedRows = useMemo(() => {
    let data = [...ref.current];
    data = data.filter((res) => res.active);
    return data;
  }, [showOnlySelected]);

  if (plantsLoading) return <AppLoader size='md' center />;

  // console.log(ref.current);

  return (
    <>
      <Table
        columns={columns}
        data={showOnlySelected ? filterSelectedRows : ref.current}
        showOnlySelected={showOnlySelected}
        setOnlyShowSelected={setOnlyShowSelected}
        displaySizeSelect={true}
      />

      <AffectedSkus
        plants={materialPlants.filter((material) => material.active)}
        isSubmissionReview={isSubmissionReview}
        errors={errors}
        scope_id={material.id}
        affectedSkus={affectedSkus}
        setAffectedSkus={setAffectedSkus}
      />

      <MixoverChangeAlert
        mixChangeOverAlert={mixChangeOverAlert}
        setMixChangeOverAlert={setMixChangeOverAlert}
      />
    </>
  );
};

export default withConfirm(MaterialTable);
