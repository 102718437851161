import { useContext, useEffect, useMemo, useState } from 'react';
import MantineTable from '../../../common/table/mantine';
import { AdminContext } from '../../../contexts/AdminContext';
import { roles } from '../../../constants';
import { Tooltip, ActionIcon } from '@mantine/core';
import EditModal from './editModal';
import { IconEdit } from '@tabler/icons-react';

const rolesData = [
  { value: roles.scheduling, label: 'Scheduling' },
  { value: roles.req, label: 'Requestor' },
  { value: roles.standard_transfer_pricing, label: 'GCC FP&A' },
  { value: roles.user_admin, label: 'User Admin' },
  { value: roles.masterdata, label: 'Supply Master Data' },
];

export const Users = ({ activeTab }) => {
  const [editModalState, setModalState] = useState({ open: false, user: null });
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { fetchUsersFromBackend } = useContext(AdminContext);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const transformedData = await fetchUsersFromBackend();
        console.log(transformedData)
        setUsers(transformedData);
      } catch (e) {
        console.error('Error fetching data:', e);
      } finally {
        setLoading(false);
      }
    };

    if (activeTab === 'existing') fetch();
  }, [activeTab]);

  const existingUsersColumns = useMemo(
    () => [
      {
        header: 'Actions',
        id: 'actions',
        enableColumnFilter: false,
        enableColumnSorting: false,
        enableColumnActions: false,
        size: 80,
        minSize: 80,
        maxSize: 120,
        Cell: ({ row }) => (
          <Tooltip label='Edit'>
            <ActionIcon onClick={() => setModalState({ open: true, user: row.original })}>
              <IconEdit />
            </ActionIcon>
          </Tooltip>
        ),
      },
      {
        header: 'Name',
        accessorKey: 'name',
        enableEditing: false,
      },
      {
        header: 'Email',
        accessorKey: 'email',
        enableEditing: false,
      },
      {
        header: 'Roles',
        accessorKey: 'roles',
        enableEditing: true,
      },
      {
        header: 'Country',
        accessorKey: 'countries',
        enableEditing: false,
      },
      {
        header: 'Created on',
        accessorKey: 'created_on',
        enableEditing: false,
      },
      {
        header: 'Created by',
        accessorKey: 'createdBy',
        enableEditing: false,
      },
      {
        header: 'Approved by',
        accessorKey: 'approvedBy',
        enableEditing: false,
      },
    ],
    [users],
  );

  return (
    <>
      {' '}
      <MantineTable
        columns={existingUsersColumns}
        initialData={users}
        unique={'id'}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        csvFilename={`existing-users`}
        pageSize={10}
        enableExport={true}
        mantinePaperProps={{
          sx: {
            border: 'none !important',
            boxShadow: 'none',
          },
        }}
      />
      {editModalState.open && (
        <EditModal
          state={editModalState}
          close={() => setModalState({ open: false, user: null })}
        />
      )}
    </>
  );
};
