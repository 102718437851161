import { Select, TextInput } from '@mantine/core';
import { CalendarIcon } from '@modulz/radix-icons';
import { DatePickerInput } from '@mantine/dates';
import moment from 'moment';
import { CustomTextInput } from '../../../common/inputs/CustomTextInput';

export const Artwork = ({
  onChange,
  searchable = false,
  data = ['Yes', 'No'],
  disabled = false,
  value,
  error,
}) => {
  return (
    <Select
      size='sm'
      radius='md'
      onChange={onChange}
      searchable={searchable}
      clearable
      data={data}
      disabled={disabled}
      value={value}
      error={error}
      withinPortal
    />
  );
};

export const ArtworkAdaptation = ({
  onChange,
  searchable = false,
  data = ['Yes', 'No'],
  disabled = false,
  value,
  error,
}) => {
  return (
    <Select
      size='sm'
      radius='md'
      onChange={onChange}
      searchable={searchable}
      clearable
      data={data}
      disabled={disabled}
      value={value}
      error={error}
      withinPortal
    />
  );
};

export const ArtworkManagement = ({
  onChange,
  searchable = false,
  data = [
    'Text change only',
    'Design or technical specs change, with new colors',
    'Design or technical specs change, with existing colors',
    'Others',
  ],
  disabled = false,
  value,
  error,
}) => {
  return (
    <Select
      size='sm'
      radius='md'
      onChange={onChange}
      searchable={searchable}
      clearable
      data={data}
      disabled={disabled}
      value={value}
      error={error}
      withinPortal
    />
  );
};

export const SupplierChange = ({
  onChange,
  searchable = false,
  data = ['Supplier Switch', 'Dual Sourcing'],
  disabled = false,
  value,
  error,
}) => {
  return (
    <Select
      size='sm'
      radius='md'
      onChange={onChange}
      searchable={searchable}
      clearable
      data={data}
      disabled={disabled}
      value={value}
      error={error}
      withinPortal
    />
  );
};

export const Changeover = ({ onChange, searchable = false, data, disabled = false, value }) => {
  return (
    <Select
      size='sm'
      radius='md'
      onChange={onChange}
      searchable={searchable}
      clearable
      data={data}
      disabled={disabled}
      value={value}
      withinPortal
    />
  );
};

export const ChangeoverDate = ({
  minDate,
  initialMonth,
  onChange,
  onClick,
  isDateValid,
  disabled = false,
  value = null,
  error,
}) => {
  return (
    <DatePickerInput
      styles={{
        arrow: {
          display: !moment(value).isValid() ? 'none' : 'block',
        },
        dropdown: {
          display: !moment(value).isValid() ? 'none' : 'block',
        },
      }}
      onChange={onChange}
      onClick={onClick}
      minDate={minDate}
      initialMonth={initialMonth}
      numberOfColumns={2}
      radius='md'
      disabled={disabled}
      icon={<CalendarIcon color='#e5b611' />}
      error={error}
      value={value ? moment(value, 'YYYY-MM-DD').toDate() : null}
      popoverProps={{ withinPortal: true }}
    />
  );
};

export const Comment = ({ onChange, disabled, value, error }) => {
  return (
    <>
      <CustomTextInput
        radius='md'
        name='comment'
        onChange={onChange}
        disabled={disabled}
        value={value}
        error={error}
        width={'20rem'}
      />
    </>
  );
};
