import MantineTable from '../../../../common/table/mantine';
import { useMemo, useState } from 'react';
import moment from 'moment';
import { Button, Checkbox, NumberInput, Skeleton } from '@mantine/core';
import { CustomTextArea } from '../../../../common/inputs/CustomTextArea';
import { IconRefresh } from '@tabler/icons-react';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { AppLoader } from '../../../../components/common/loader';

export const Packmat = ({ activeSubTab, loading, skus, fetch }) => {
  const { rfsNumber } = useParams();
  const [saveLoader, setSaveLoader] = useState({});
  const [form, setForm] = useState({});

  const handleSubmit = async (data) => {
    try {
      const {
        id,
        cost_value,
        material_number,
        production_plant,
        not_procurement_material,
        planned_price_date_1,
      } = data;
      const payload = {
        ...form[id],
        not_procurement_material: form[id].hasOwnProperty('not_procurement_material')
          ? form[id].not_procurement_material
          : !not_procurement_material,
        planned_price_1: form[id].planned_price_1 ? form[id].planned_price_1 : cost_value,
        planned_price_date_1: planned_price_date_1,
        material: material_number,
        plant: production_plant,
      };

      setSaveLoader({ ...saveLoader, [id]: true });
      setForm({ ...form, [id]: null });
      await axios.post('costing-request/step/bom-component-price', payload);
    } catch (e) {
    } finally {
      setSaveLoader({ ...saveLoader, [data.id]: false });
    }
  };

  const handleChange = (value, name, id, component_id) => {
    const values = { ...form };
    if (!values[id])
      values[id] = {
        costing_request_id: rfsNumber,
        id: id,
        component_id: component_id,
      };
    values[id][name] = value;

    setForm(values);
  };

  const getNumberInputValue = (costValue, formComment) => {
    return parseFloat(costValue || 0) || parseFloat(formComment || 0) || 0;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'sku_number',
        header: 'SKU Number',
      },
      {
        accessorKey: 'sku_description',
        header: 'SKU Description',
      },
      {
        accessorKey: 'material_number',
        header: 'Material Number',
      },
      {
        accessorKey: 'material_description',
        header: 'Material Description',
      },
      {
        accessorKey: 'material_status',
        header: 'Material Status',
      },

      {
        accessorKey: 'not_procurement_material',
        header: 'Not a Procurement Material',
        Cell: ({ row }) => {
          const { not_procurement_material, component_id, id } = row.original;
          return (
            <Skeleton visible={saveLoader[id] || false}>
              <Checkbox
                label=''
                defaultChecked={
                  form[id] ? form[id].not_procurement_material : not_procurement_material
                }
                onChange={(event) =>
                  handleChange(event.target.checked, 'not_procurement_material', id, component_id)
                }
              />
            </Skeleton>
          );
        },
      },
      {
        accessorKey: 'price_to_be_updated_by',
        header: 'Price to be Updated by',
      },
      {
        accessorKey: 'urgency',
        header: 'Urgency',
      },
      {
        accessorKey: 'producing_plant',
        header: 'Producing Plant',
      },
      {
        accessorKey: 'base_unit',
        header: 'Base Unit',
      },
      {
        accessorKey: 'uom',
        header: 'UOM',
      },
      {
        accessorKey: 'currency',
        header: 'Currency',
      },
      {
        accessorKey: 'cost_value',
        header: 'Cost Value',
        Cell: ({ row }) => {
          const { cost_value, component_id, id } = row.original;
          return (
            <Skeleton visible={saveLoader[id] || false}>
              <NumberInput
                value={getNumberInputValue(form[id]?.planned_price_1, cost_value)}
                precision={2}
                min={-1}
                step={0.01}
                disabled={cost_value && cost_value > 0}
                onChange={(value) => handleChange(value, 'planned_price_1', id, component_id)}
              />
            </Skeleton>
          );
        },
      },
      {
        accessorKey: 'pack_mat_price_requesting_date',
        header: 'Requested On',
      },
      {
        accessorKey: 'price_updated',
        header: 'Submitted On',
      },

      {
        accessorKey: 'fpna_analyst_responsible',
        header: 'FPNA Analyst responsible',
      },
      {
        accessorKey: 'comments',
        header: 'Comments',
        Cell: ({ row }) => {
          const { comment, component_id, id } = row.original;

          return (
            <Skeleton visible={saveLoader[id] || false}>
              <CustomTextArea
                radius='md'
                value={form[id] ? form[id]?.comment : comment || ''}
                name='comment'
                onChange={(value) => handleChange(value, 'comment', id, component_id)}
              />
            </Skeleton>
          );
        },
      },
      {
        id: 'action',
        header: 'Action',
        Cell: ({ row }) => (
          <Button
            disabled={!form[row.original.id] || saveLoader[row.original.id]}
            color='dark'
            fullWidth
            size='sm'
            radius='sm'
            className='add-supplier-button'
            onClick={() => handleSubmit(row.original)}
          >
            {saveLoader[row.original.id] ? <AppLoader size='sm' /> : 'Submit'}
          </Button>
        ),
      },
    ],
    [skus, form, saveLoader],
  );

  const rightButtons = [{ label: <IconRefresh size={16} />, onClick: () => fetch(true) }];

  return (
    <>
      <MantineTable
        columns={columns}
        initialData={(skus || []).filter((sku) => !sku.asm)}
        unique={'sku'}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        rightButtons={rightButtons}
        enableExport={true}
        csvFilename={`bom_component_price_packmat_${moment().format('YYYY-MM-DD')}`}
        pageSize={10}
      />
    </>
  );
};
