import React, { createContext, useCallback, useEffect, useState } from 'react';
import { axios } from '../utils/axios';
import pureAxios from 'axios';
import { useMsal } from '@azure/msal-react';
import {
  loginRequest,
  graphConfig,
  ProductNPSConfig,
  snowBackendRequest,
} from '../utils/authConfig';
import { decodeToken } from 'react-jwt';
import { routes } from '../routes';
import { deleteCookie } from '../utils/helpers';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userName, setUserName] = useState('');
  const [users, setUsers] = useState([]);
  const [techLeads, setTechleads] = useState([]);
  const [gateKeepers, setGatekeepers] = useState([]);
  const [d2pUsers, setD2pUsers] = useState([]);
  const [user, setUser] = useState(null);

  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'));

  const { instance, accounts } = useMsal();
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [tokenLoader, setTokenLoader] = useState(false);
  const [crInfoLoader, setCrInfoLoader] = useState(false);
  const [crUserRoles, setCrUserRoles] = useState([]);

  useEffect(() => {
    if (accounts.length > 0) {
      initializeTokens();
    }
  }, [accounts, instance]);

  useEffect(() => {
    setInfoFromToken();
  }, []);

  useEffect(() => {
    const token = getToken();
    if (token) {
      const { rfs_registered, cr_registered } = user ? user : decodeToken(token);
      if (loggedIn && rfs_registered) initializeApp();
      if (loggedIn && cr_registered) initializeCrApp();
    }
  }, [loggedIn]);

  const initializeMsal = async () => {
    await instance.initialize();
  };

  const initializeTokens = async () => {
    try {
      setTokenLoader(true);
      const name = accounts[0] ? accounts[0]['name'].split(', ').reverse().join(' ') : '';
      setUserName(name);
      await initializeMsal();
      await fetchProfilePhoto();
      await setNpsToken();
      await setSnowToken();
    } catch (e) {
    } finally {
      setTokenLoader(false);
    }
  };

  const initializeApp = async () => {
    try {
      setLoading(true);
      await info();
      await fetchUsers();
      await fetchTechleads();
      await fetchGatekeepers();
      await fetchD2pUsers();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const initializeCrApp = async () => {
    try {
      setCrInfoLoader(true);
      await crUserInfo();
    } catch (e) {
    } finally {
      setCrInfoLoader(false);
    }
  };

  const setToken = (token) => {
    localStorage.setItem('token', token);
  };

  const getToken = () => {
    return localStorage.getItem('token');
  };

  const setAuthRequestType = (type) => {
    sessionStorage.setItem('requestType', type);
  };

  const getAuthRequestType = () => {
    return sessionStorage.getItem('requestType');
  };

  const clearStorage = () => {
    localStorage.clear();
    deleteCookie('token');
  };

  const setInfoFromToken = (token = getToken()) => {
    if (!token) return;
    const data = user ? user : decodeToken(token);
    setUser(data);

    return data;
  };

  const login = async (payload, callback) => {
    try {
      setLoading(true);

      const response = await axios.post('/user/v2/login', {
        token: payload.token,
        email: payload.email,
      });

      deleteCookie('token');

      const token = response.data.data;
      if (!token) {
        return callback(routes.home.register);
      }

      axios.defaults.headers.common['Authorization'] = token;

      const { rfs_registered, cr_registered } = setInfoFromToken(token);

      const requestType = getAuthRequestType();

      if (rfs_registered && cr_registered) {
        setToken(token);
        setLoggedIn(true);
        callback(`${routes.rfs.root}`);
        return;
      }

      if (requestType !== 'register') {
        setToken(token);
        setLoggedIn(true);
        return callback(cr_registered ? routes.costingRequests().root : routes.rfs.root);
      }
      sessionStorage.clear();
      return callback(routes.home.register);
    } catch (error) {
      if (error?.response?.status === 404) {
        return callback(routes.home.register);
      }
      sessionStorage.clear();
      localStorage.clear();
    } finally {
      setLoading(false);
    }
  };

  const info = useCallback(async () => {
    try {
      const response = await axios.get('/user/info');
      setUserRole(response.data.role);
      setUserEmail(response.data.email);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get('/user/all');
      let allUsers = [];
      let token = response.data.users;
      const myDecodedToken = decodeToken(token);

      allUsers = myDecodedToken.users.map((user) => {
        return { name: user.label, email: user.value };
      });

      setUsers(allUsers);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchGatekeepers = useCallback(async () => {
    try {
      const response = await axios.get('/user/get', { params: { role: 'pmo' } });
      let allgatekeepers = [];
      let token = response.data.users;
      const myDecodedToken = decodeToken(token);

      allgatekeepers = myDecodedToken.users.map((user) => {
        return { name: user.label, email: user.value };
      });

      setGatekeepers(allgatekeepers);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchTechleads = useCallback(async () => {
    try {
      const response = await axios.get('/user/get', { params: { role: 'zitec' } });

      let alltechleads = [];
      let token = response.data.users;
      const myDecodedToken = decodeToken(token);

      alltechleads = myDecodedToken.users.map((user) => {
        return { name: user.label, email: user.value };
      });

      setTechleads(alltechleads);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchD2pUsers = useCallback(async () => {
    try {
      const response = await axios.get('/user/get', { params: { role: 'd2p' } });
      let allUsers = [];
      let token = response.data.users;
      const myDecodedToken = decodeToken(token);

      allUsers = myDecodedToken.users.map((user) => {
        return { name: user.label, email: user.value };
      });

      setD2pUsers(allUsers);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchProfilePhoto = async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      const profileResponse = await pureAxios.get(graphConfig.graphProfilePhotoEndpoint, {
        headers: {
          Authorization: `Bearer ${response.accessToken}`,
        },
        responseType: 'blob',
      });

      const picture = profileResponse.data;
      setProfilePhoto(picture);
      return picture;
    } catch (e) {
      console.log(e);
    }
  };

  const setNpsToken = async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...ProductNPSConfig,
        account: accounts[0],
      });
      localStorage.setItem('token-customscope', response.accessToken);
      return response.accessToken;
    } catch (e) {
      console.log(e);
    }
  };

  const setSnowToken = async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...snowBackendRequest,
        account: accounts[0],
      });
      localStorage.setItem('snow_api_access_token', response?.accessToken);
      return response?.accessToken;
    } catch (e) {
      console.log(e);
    }
  };

  const fetchNpsToken = () => {
    return new Promise((resolve, reject) => {
      instance
        .acquireTokenSilent({
          ...ProductNPSConfig,
          account: accounts[0],
        })
        .then((response) => {
          if (response) {
            resolve(response.accessToken);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const crUserInfo = async () => {
    const response = await axios.get('user/v2/fetch/');
    const info = response.data.data[0];

    let roles = info.int_user_role_mappings
      .filter((role) => role.is_active && role.int_role?.name)
      .map((role) => role.int_role.name);

    roles = [...new Set(roles)];
    setCrUserRoles(roles);
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        setLoading,
        login,
        loggedIn,
        setLoggedIn,
        setToken,
        clearStorage,
        userRole,
        setUserRole,
        users,
        gateKeepers,
        techLeads,
        setUsers,
        setTechleads,
        setGatekeepers,
        fetchUsers,
        fetchTechleads,
        fetchGatekeepers,
        userEmail,
        userName,
        profilePhoto,
        fetchD2pUsers,
        d2pUsers,
        fetchNpsToken,
        setAuthRequestType,
        getAuthRequestType,
        getToken,
        user,
        setUserEmail,
        tokenLoader,
        crInfoLoader,
        crUserRoles,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
