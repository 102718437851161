import React, { createContext, useCallback, useState } from 'react';
import { axios } from '../utils/axios';

const initialLoaders = {
  info: false,
  skus: false,
  plants: false,
};

export const CostingRequestsContext = createContext();

export const CostingRequestsProvider = ({ children }) => {
  const [loaders, setLoaders] = useState(initialLoaders);
  const [info, setInfo] = useState({});
  const [skus, setSkus] = useState([]);
  const [plants, setPlants] = useState([]);
  const [countries, setCountries] = useState([]);

  const getRequestDetails = useCallback(
    (id) => {
      setLoaders({ ...loaders, info: true });
      if (id) {
        axios
          .get(`/costing-request/record/${id}`)
          .then(
            (res) => {
              setInfo(res.data.data);
            },
            (error) => {
              console.log(error);
            },
          )
          .finally(() => setLoaders({ ...loaders, info: false }));
      } else {
        setInfo({});
      }
    },
    [setInfo],
  );

  const getSkus = useCallback(() => {
    if (skus.length === 0) {
      setLoaders({ ...loaders, skus: true });
      axios
        .get('/costing-request/sku-data')
        .then(
          (res) => {
            setSkus(res.data.data[0]);
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => setLoaders({ ...loaders, skus: true }));
    }
  }, [setSkus]);

  const getPlants = useCallback(() => {
    if (plants.length === 0) {
      setLoaders({ ...loaders, plants: true });
      axios
        .get('/costing-request/plant-data')
        .then(
          (res) => {
            setPlants(res.data);

            const uniqueCountries = [...new Set(res.data.map((info) => info.Country))];
            setCountries(uniqueCountries);
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => setLoaders({ ...loaders, plants: true }));
    }
  }, [setPlants]);

  return (
    <CostingRequestsContext.Provider
      value={{ loaders, info, skus, plants, getRequestDetails, getSkus, getPlants, countries }}
    >
      {children}
    </CostingRequestsContext.Provider>
  );
};
