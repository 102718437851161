import { Textarea } from '@mantine/core';

export const CustomTextArea = ({
  label,
  name,
  onChange,
  disabled = false,
  value,
  error = null,
  maxLength = 1000,
  width = 'unset',
}) => {
  return (
    <>
      <Textarea
        label={label}
        autosize
        minRows={2}
        radius='md'
        name={name}
        onChange={(event) => {
          const value =
            (event?.target?.value || '').trim().length === 0
              ? event.target.value.trim()
              : event.target.value.substring(0, maxLength);
          onChange(value);
        }}
        style={{
          input: { width: '50px', height: '1rem' },
          width,
        }}
        disabled={disabled}
        value={value}
        error={error}
      />
      <div style={{ fontSize: '10px', float: 'right' }}>
        <span>
          {maxLength - (value || '').length}/{maxLength}
        </span>
      </div>
    </>
  );
};
