import { Button } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { Table } from './table';
import { IconRefresh } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { axios } from '../../../../../utils/axios';
import { AttachmentsModal } from '../../../../../common/attachments';
import { attachmentChunks } from '../../../../../utils/helpers';

const MAX_ATTACHMENTS = 5;

export const CostComponent = ({ activeTab }) => {
  const { rfsNumber } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [attachmentModal, setAttachmentModal] = useState({
    open: false,
    artwork_dev: null,
    status_id: null,
    attachments: [],
  });

  useEffect(() => {
    if (activeTab === 'vicSubmission') fetch();
  }, [activeTab]);

  const fetch = async (refresh = false) => {
    try {
      setLoading(true);
      const response = await axios.get(`costing-request/step/vic-submission/${rfsNumber}`, {
        params: {
          refresh_vic_cc: refresh,
        },
      });
      setData(response.data.data.cc_steps);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const openUpladModal = () => {
    setAttachmentModal({ open: true, artwork_dev: null, status_id: null, attachments: [] });
  };

  const handleRefresh = () => {
    fetch(true);
  };
  const handleSubmit = () => {};

  const handleFileSelect = (files) => {
    const currentValues = { ...attachmentModal };
    const attachments = currentValues.attachments || [];
    const filesLength = attachments.length + files.length;

    if (filesLength <= MAX_ATTACHMENTS) {
      files.forEach((file) => attachments.push(file));
      currentValues.attachments = attachments;
      setAttachmentModal(currentValues);
    }
  };

  const handleFileRemove = (index) => {
    const currentValues = { ...attachmentModal };
    currentValues.attachments = currentValues.attachments.filter((i, k) => k !== index);

    setAttachmentModal(currentValues);
  };

  const handleAttachmentSubmit = async () => {
    const { artwork_dev, attachments } = attachmentModal;
    const oldAttachments =
      (artwork_dev.gfp_attachment || '').length > 0 ? artwork_dev.gfp_attachment.split(',') : [];

    const attachmentNames = new Set(attachments.map((file) => file.name));
    const deletedAttachments = oldAttachments.filter((name) => !attachmentNames.has(name));

    const formData = new FormData();
    formData.append('rfs_number', rfsNumber);
    formData.append('id', attachmentModal.artwork_dev.id);

    for (const item of deletedAttachments) {
      formData.append('deletedAttachments[]', item);
    }

    const chunks = attachmentChunks(attachments.filter((file) => file.size));
    if (Object.keys(chunks).length > 0) {
      for (const chunk of Object.values(chunks)) {
        for (const file of chunk) {
          formData.append('attachments', file);
        }
        await uploadAttachment(formData);
      }
    } else {
      await uploadAttachment(formData);
    }

    setAttachmentModal({ ...attachmentModal, open: false });
    fetch(false);
  };

  const uploadAttachment = async (formData) => {
    try {
      const res = await axios.post(`/rfs/artwork/attachment`, formData);
      return res;
    } catch (error) {
      console.error('Error while handling attachment submission:', error);
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          marginBottom: '16px',
        }}
      >
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button variant='light' color='dark' onClick={() => handleRefresh()}>
            <IconRefresh size={16} />
          </Button>
          <Button variant='light' color='dark' onClick={() => openUpladModal()}>
            UPLOAD ATTACHMENT
          </Button>
        </div>
      </div>

      <div>
        <Table data={data} loading={loading} />
      </div>

      {attachmentModal.open && (
        <AttachmentsModal
          modal={attachmentModal}
          setModal={setAttachmentModal}
          onChange={handleFileSelect}
          onSubmit={handleAttachmentSubmit}
          onRemove={handleFileRemove}
          readOnly={false}
        />
      )}
    </>
  );
};
