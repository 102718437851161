import { Divider, Flex, Grid, Loader, Modal, Text } from '@mantine/core';
import { saveAs } from 'file-saver';
import { CustomTextInput } from '../styles';
import { CustomTextArea } from '../../../common/inputs/CustomTextArea';
import styled from '@emotion/styled';
import { axios } from '../../../utils/axios';
import { EXTENSIONS } from '../../../constants';
import { useState } from 'react';

const Content = styled.span`
  color: #e5b611;
  cursor: pointer;
  font-size: 0.875rem;
`;

export const RequestPreview = ({ state, setState }) => {
  const [loaders, setLoaders] = useState({});
  const { user = {} } = state;

  const downloadFile = async ({ id, name }) => {
    try {
      setLoaders({
        ...loaders,
        [id]: {
          status: true,
          error: null,
        },
      });
      const response = await axios.get(`/user/v2/download/${id}`);
      const base64String = response.data.data.downloaded;
      const ext = name.split('.');
      saveAs(`${EXTENSIONS[ext[ext.length - 1].toLowerCase()]}${base64String}`, name);

      setLoaders({
        ...loaders,
        [id]: {
          status: false,
          error: null,
        },
      });
    } catch (e) {
      setLoaders({
        ...loaders,
        [id]: {
          status: false,
          error: e.response.status === 404 ? 'File not found' : 'Something went wrong. Try again',
        },
      });
    }
  };

  console.log(loaders);

  return (
    <Modal
      size='50%'
      opened={state.open}
      onClose={() => setState({ open: false, user: null })}
      title=''
    >
      <Grid>
        <Grid.Col md={6} lg={6}>
          <CustomTextInput disabled value={user.name} label='Name' />
        </Grid.Col>
        <Grid.Col md={6} lg={6}>
          <CustomTextInput disabled label='Email' value={user.email} />
        </Grid.Col>
        <Divider />
        <Grid.Col md={6} lg={6}>
          <CustomTextInput disabled value={user.role_requested} label='Role' />
        </Grid.Col>

        <Grid.Col md={6} lg={6}>
          <CustomTextInput disabled label='Producing Plant' value={user.plant} />
        </Grid.Col>
        <Divider />

        <Grid.Col md={6} lg={6}>
          <CustomTextInput disabled label='Country' value={user.country} />
        </Grid.Col>

        <Grid.Col md={6} lg={6}>
          <CustomTextInput disabled label='Type of Request' value={user.type_of_request} />
        </Grid.Col>
        <Divider />

        <Grid.Col md={6} lg={6}>
          <CustomTextInput disabled label='Type of SKU' value={user.type_of_sku} />
        </Grid.Col>

        <Divider />

        <Grid.Col md={12} lg={12}>
          <CustomTextArea disabled label='Justification' value={user.justification} />
        </Grid.Col>
        <Divider />

        <Grid.Col md={12} lg={12}>
          <Grid>
            <Grid.Col md={3}>
              <label class='mantine-InputWrapper-label mantine-Textarea-label'>
                Line Manager Approval
              </label>
            </Grid.Col>

            <Grid.Col md={8}>
              {user.attachments.map((attachment, key) => (
                <>
                  <Flex
                    key={key}
                    direction={{ base: 'column', sm: 'row' }}
                    gap={{ base: 'sm', sm: 'lg' }}
                    justify={{ sm: 'start' }}
                    align={{ sm: 'center' }}
                  >
                    <Content onClick={() => downloadFile(attachment)}>{attachment.name}</Content>
                    {loaders[attachment.id]?.status && <Loader size='sm' />}
                    {loaders[attachment.id]?.error && (
                      <Text fz='sm' c='red'>
                        {loaders[attachment.id].error}
                      </Text>
                    )}
                  </Flex>
                </>
              ))}
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};
