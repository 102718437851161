import React, { useState, useContext, useEffect } from 'react';
import { Text, Button } from '@mantine/core';
import { Photo } from 'tabler-icons-react';
import ProjectName from '../projectName/projectName';
import { Tabs } from '@mantine/core';
import { Row, Col } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RfsContext } from '../../contexts/RfsContext';
import { AttachmentModal } from '../modal/AttachmentModal';

import './attachmentComp.css';
import ContentPaper from '../layouts/rfs/Content';
import { AppLoader } from '../common/loader';
import { routes } from '../../routes';
import { rfsStatus } from '../../constants';
import { CustomDropzone } from '../../common/dropzone';
import { FileList } from '../../common/dropzone/fileList';

function AttachmentCompPd(props) {
  const params = useParams();
  const navigate = useNavigate();
  const [attachmentModal, setAttachmentModal] = useState(false);
  const [artworkRequired, setArtworkRequired] = useState(false);
  const [error, setError] = useState({ state: false, message: null, type: null });

  const { form, addAttachments, loading, saveAsDraft, downloadFile } = useContext(RfsContext);

  const { status = '', fast_track = '', capex_needed = '' } = form.rfsInformation;

  const scopeSelectedSkus = form.scopeSelectedSkus;

  const repack = Object.values(scopeSelectedSkus).some((item) => item.production == 'Repacking');

  const { artworkBriefing, fastTrackApproval, repacking, capexEmailApproval, emailAttachment } =
    form.attachments;

  useEffect(() => {
    const skus = Object.values(form.scopeSelectedSkus);

    for (const sku of skus) {
      const boms = sku.boms;
      const index = (boms || []).findIndex((bom) => bom.artwork_change);

      if (index > -1) {
        setArtworkRequired(true);
        break;
      }
    }
  }, [form.scopeSelectedSkus]);

  const isDisabled = () => {
    return (
      (Boolean(artworkRequired) && artworkBriefing.length === 0) ||
      (Boolean(fast_track) && fastTrackApproval.length === 0) ||
      (Boolean(capex_needed) && capexEmailApproval.length === 0) ||
      (Boolean(repack) && repacking.length === 0)
    );
  };

  const handleNext = (callback) => {
    if (!callback) {
      addAttachments(callback);
      return;
    }
    if (isDisabled()) {
      setAttachmentModal(true);
    } else {
      addAttachments(callback);
    }
  };

  const Footer = () =>
    props.disableButtons ? null : (
      <Row className='justify-content-md-end' md={4}>
        <Col md='auto'>
          <Link to={routes.submission(params.rfsNumber).scopeDefine}>
            <Button
              className='back'
              color='primary'
              radius='md'
              size='md'
              fullWidth
              style={{
                marginRight: '1rem',
                background: 'white',
                color: 'black',
                border: ' 0.25px solid gray',
                width: '200px',
              }}
            >
              Back
            </Button>
          </Link>
        </Col>
        <Col md='auto'>
          <Button
            disabled={saveAsDraft}
            className='save'
            color='dark'
            radius='md'
            size='md'
            fullWidth
            style={{ marginRight: '1rem', width: '200px' }}
            onClick={() => handleNext()}
          >
            {!saveAsDraft ? 'Save' : <AppLoader />}
          </Button>
        </Col>
        <Col md='auto'>
          <Button
            disabled={loading}
            className='next'
            variant='gradient'
            radius='md'
            size='md'
            fullWidth
            style={{ marginRight: '1rem', width: '200px' }}
            onClick={() =>
              handleNext(() =>
                status === rfsStatus.draft
                  ? navigate(routes.submission(params.rfsNumber).review)
                  : navigate(-1),
              )
            }
          >
            {!loading ? 'Next' : <AppLoader />}
          </Button>
        </Col>
      </Row>
    );

  return (
    <ContentPaper page={'attachments'} footer={<Footer />}>
      {props.withProjectName ? (
        <Row>
          <div className='projectNameComp'>
            <ProjectName initialItem={props.initialItem} />
          </div>
        </Row>
      ) : null}

      <Row>
        <Row>
          <Text size='sm' weight={600}>
            Please upload Artwork Briefing{' '}
            {artworkRequired && (
              <span style={{ color: 'red', fontWeight: '400' }}>* (Required Field)</span>
            )}
          </Text>

          <Tabs color='dark' tabPadding='md' defaultValue={'Files'}>
            <Tabs.List>
              <Tabs.Tab value='Files' icon={<Photo size={14} />}></Tabs.Tab>
              <Tabs.Tab value='Template' icon={<Photo size={14} />}></Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value='Files'>
              <CustomDropzone type={'artworkBriefing'} setError={setError} />

              <FileList
                files={artworkBriefing}
                type={'artworkBriefing'}
                error={Boolean(artworkRequired) && artworkBriefing.length === 0}
              />
              {error.state && error.type === 'artworkBriefing' && (
                <div className='red-custom-mantine-Textarea-error'>{error.message}</div>
              )}
            </Tabs.Panel>

            <Tabs.Panel value='Template'>
              <Col md={{ span: 2 }}>
                <Button
                  style={{ marginBottom: '1rem', background: 'black', color: 'white' }}
                  color='yellow'
                  fullWidth
                  onClick={() => downloadFile('Artwork Briefing Template.xlsx')}
                >
                  Download PDF
                </Button>
              </Col>
            </Tabs.Panel>
          </Tabs>
        </Row>

        <Row>
          <Text size='sm' weight={600}>
            Please upload Fast Track Approval{' '}
            {fast_track && (
              <span style={{ color: 'red', fontWeight: '400' }}>* (Required Field)</span>
            )}
          </Text>
          <Tabs color='dark' tabPadding='md' defaultValue={'Files'}>
            <Tabs.List>
              <Tabs.Tab value='Files' icon={<Photo size={14} />}></Tabs.Tab>
              <Tabs.Tab value='Template' icon={<Photo size={14} />}></Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value='Files'>
              <CustomDropzone type={'fastTrackApproval'} setError={setError} />
              <FileList
                files={fastTrackApproval}
                type={'fastTrackApproval'}
                error={Boolean(fast_track) && fastTrackApproval.length === 0}
              />
              {error.state && error.type === 'fastTrackApproval' && (
                <div className='red-custom-mantine-Textarea-error'>{error.message}</div>
              )}
            </Tabs.Panel>

            <Tabs.Panel value='Template'>
              <Col md={{ span: 2 }}>
                <Button
                  style={{ marginBottom: '1rem', background: 'black', color: 'white' }}
                  color='yellow'
                  fullWidth
                  onClick={() => downloadFile('Fast Track Document.pdf')}
                >
                  Download PDF
                </Button>
              </Col>
            </Tabs.Panel>
          </Tabs>
        </Row>

        <Row>
          <Text size='sm' weight={600}>
            Please upload Repacking Attachment{' '}
            {repack && <span style={{ color: 'red', fontWeight: '400' }}>* (Required Field)</span>}
          </Text>

          <Tabs color='dark' tabPadding='md' defaultValue={'Files'}>
            <Tabs.List>
              <Tabs.Tab value='Files' icon={<Photo size={14} />}></Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value='Files'>
              <CustomDropzone type={'repacking'} setError={setError} />
              <FileList
                files={repacking}
                type={'repacking'}
                error={Boolean(repack) && repacking.length === 0}
              />
              {error.state && error.type === 'repacking' && (
                <div className='red-custom-mantine-Textarea-error'>{error.message}</div>
              )}
            </Tabs.Panel>
          </Tabs>
        </Row>

        <Row>
          <Text size='sm' weight={600}>
            Please upload Capex Email Approval{' '}
            {capex_needed && (
              <span style={{ color: 'red', fontWeight: '400' }}>* (Required Field)</span>
            )}
          </Text>
          <Tabs color='dark' tabPadding='md' defaultValue={'Files'}>
            <Tabs.List>
              <Tabs.Tab value='Files' icon={<Photo size={14} />}></Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value='Files'>
              <CustomDropzone type={'capexEmailApproval'} setError={setError} />
              <FileList
                files={capexEmailApproval}
                type={'capexEmailApproval'}
                error={Boolean(capex_needed) && capexEmailApproval.length === 0}
              />
              {error.state && error.type === 'capexEmailApproval' && (
                <div className='red-custom-mantine-Textarea-error'>{error.message}</div>
              )}
            </Tabs.Panel>
          </Tabs>
        </Row>
        <Row>
          <Text size='sm' weight={600}>
            Please upload Additional Email Attachment
          </Text>

          <Tabs color='dark' tabPadding='md' defaultValue={'Files'}>
            <Tabs.List>
              <Tabs.Tab value='Files' icon={<Photo size={14} />}></Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value='Files'>
              <CustomDropzone type={'emailAttachment'} setError={setError} maxFiles={10} size={8} />

              <FileList files={emailAttachment} type={'emailAttachment'} />
              {error.state && error.type === 'emailAttachment' && (
                <div className='red-custom-mantine-Textarea-error'>{error.message}</div>
              )}
            </Tabs.Panel>
          </Tabs>
        </Row>
      </Row>

      {attachmentModal && (
        <AttachmentModal invalidModal={attachmentModal} setInvalidModal={setAttachmentModal} />
      )}
    </ContentPaper>
  );
}

export default AttachmentCompPd;
