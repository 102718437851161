export const onlyLetters = (str) => {
  return /^[a-zA-Z]+$/.test(str);
};

export const onlyNumbers = (str) => {
  return /\d/.test(str);
};

export const removeTrailingTR = (str) => {
  const regex = /[tr]+$/i;
  const lastIndex = str?.search(regex);

  if (lastIndex !== -1) {
    return str?.slice(0, lastIndex);
  }

  return str;
};

export const hasSpecialCharacters = (str) => {
  const regex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  return (str || '').match(regex);
};

export const removeSpecialCharacters = (str) => {
  return (str || '').replace(/[^a-zA-Z0-9 ]/g, '');
};

export const attachmentChunks = (attachments, size = 8388608) => {
  const combinedAttachments = {};
  let groupIndex = 0;
  let groupSize = 0;

  attachments.forEach((obj) => {
    if (!combinedAttachments[groupIndex]) {
      combinedAttachments[groupIndex] = [];
      groupSize = 0;
    }

    if (groupSize + obj.size > size) {
      groupIndex++;
      combinedAttachments[groupIndex] = [];
      groupSize = 0;
    }

    combinedAttachments[groupIndex].push(obj);
    groupSize += obj.size;
  });

  return combinedAttachments;
};

export const tabStyles = (theme) => ({
  tab: {
    ...theme.fn.focusStyles(),
    backgroundColor: 'white',
    //theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    color: theme.colors.gray[9],
    border: '1px solid #e5b611',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    cursor: 'pointer',
    fontSize: theme.fontSizes.sm,
    display: 'flex',
    alignItems: 'center',

    '& .mantine-Tabs-tabLabel': {
      fontWeight: 'bold',
    },

    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },

    '&:not(:first-of-type)': {
      borderLeft: 0,
    },

    '&:first-of-type': {
      borderTopLeftRadius: theme.radius.md,
      borderBottomLeftRadius: theme.radius.md,
    },

    '&:last-of-type': {
      borderTopRightRadius: theme.radius.md,
      borderBottomRightRadius: theme.radius.md,
    },

    '&[data-active]': {
      background: 'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)',
      borderColor: '#f5e003',
      color: '#000000',
    },

    '&[data-active]>span': {
      color: '#000000',
    },
  },
  tabsList: {
    display: 'flex',
  },
});

export const sCodeStyles = (theme) => ({
  background: 'black',
  textAlign: 'center',
  padding: theme.spacing.xs,
  borderRadius: theme.radius.md,
  cursor: 'pointer',

  color: theme.colors.yellow[5],
  fontSize: theme.fontSizes.lg,
  fontWeight: 1000,

  '&:hover': {
    backgroundColor: 'black',
  },
});

export const getValueByKey = (obj, key) => key.split('.').reduce((o, k) => o?.[k], obj);

export const setCookie = (name, value, seconds) => {
  const date = new Date();
  date.setTime(date.getTime() + seconds * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
};

export const deleteCookie = (name) => {
  const date = new Date();
  date.setTime(date.getTime() - 1000); // Set the date to a past time
  const expires = 'expires=' + date.toUTCString();
  document.cookie = name + '=; ' + expires + '; path=/';
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null; // If the cookie is not found, return null
};
