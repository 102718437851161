import MantineTable from '../../../../common/table/mantine';
import { useMemo } from 'react';
import moment from 'moment';
import { IconRefresh } from '@tabler/icons-react';

export const Mother = ({ activeSubTab, loading, skus, fetch }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'sku_number',
        header: 'SKU Number',
      },
      {
        accessorKey: 'sku_description',
        header: 'SKU Description',
      },
      {
        accessorKey: 'material_number',
        header: 'Material Number',
      },
      {
        accessorKey: 'material_description',
        header: 'Material Description',
      },

      {
        accessorKey: 'urgency',
        header: 'Urgency',
      },
      {
        accessorKey: 'producing_plant',
        header: 'Producing Plant',
      },
      {
        accessorKey: 'base_unit',
        header: 'Base Unit',
      },
      {
        accessorKey: 'uom',
        header: 'UOM',
      },
      {
        accessorKey: 'currency',
        header: 'Currency',
      },
      {
        accessorKey: 'cost_value',
        header: 'Cost Value',
      },
      {
        accessorKey: 'mother_costing_request_id',
        header: 'Costing Request ID',
      },
      {
        accessorKey: 'mother_costing_request_created_on',
        header: 'Costing Request Created On',
      },
      {
        accessorKey: 'monther_cr_status',
        header: 'CR Status',
      },
      {
        accessorKey: 'fpna_analyst_responsible',
        header: 'FPNA Analyst responsible',
      },
    ],
    [skus],
  );

  const rightButtons = [{ label: <IconRefresh size={16} />, onClick: () => fetch(true) }];

  return (
    <>
      <MantineTable
        columns={columns}
        initialData={(skus || []).filter((sku) => sku.asm)}
        unique={'sku'}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        enableExport={true}
        rightButtons={rightButtons}
        csvFilename={`bom_component_price_monther_${moment().format('YYYY-MM-DD')}`}
        pageSize={10}
      />
    </>
  );
};
