import { useMemo, useState } from 'react';
import MantineTable from '../../../../../common/table/mantine';
import { CustomTextArea } from '../../../../../common/inputs/CustomTextArea';

export const Table = ({ data = [], loading }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'component',
        header: 'BOM/Norm Component',
      },
      {
        accessorKey: 'resource',
        header: 'Resource',
      },
      {
        header: 'Total Value (per HL)',
        Cell: ({ row }) => {
          const { RequestedSkuCostEstimation } = row.original;

          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>Requested: {}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>
                  Reference: {RequestedSkuCostEstimation?.total_value}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: 'currency',
        header: 'Currency',
      },
      {
        header: 'Quantity Value',
        Cell: ({ row }) => {
          const { RequestedSkuCostEstimation } = row.original;
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>Requested:</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>
                  Reference: {RequestedSkuCostEstimation?.quantity}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: 'component_unit',
        header: 'Quantity Unit',
      },
      {
        header: 'Price Value',
        Cell: ({ row }) => {
          const { RequestedSkuCostEstimation } = row.original;
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>Requested:</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>
                  Reference: {RequestedSkuCostEstimation?.price}
                </span>
              </div>
            </div>
          );
        },
      },

      {
        accessorKey: 'price_unit',
        header: 'Price Unit',
      },

      {
        header: 'Scrap(%)',
        Cell: ({ row }) => {
          const { RequestedSkuCostEstimation } = row.original;
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>Requested:</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>
                  Reference: {RequestedSkuCostEstimation?.scrap}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        header: 'Raw Material',
        Cell: ({ row }) => {
          const { RequestedSkuCostEstimation } = row.original;
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>Requested:</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>
                  Reference: {RequestedSkuCostEstimation?.resource}
                </span>
              </div>
            </div>
          );
        },
      },

      {
        header: 'By Products',
        Cell: ({ row }) => {
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span key={row.original?.sku_id || 'key-1'} style={{ marginRight: '8px' }}>
                  Requested:
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span key={row.original?.sku_id} style={{ marginRight: '8px' }}>
                  Reference:
                </span>
              </div>
            </div>
          );
        },
      },

      {
        accessorKey: 'component_description',
        header: 'Bulk Transport',
      },

      {
        header: 'Packaging',
        Cell: ({ row }) => {
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span key={row.original?.sku_id || 'key-1'} style={{ marginRight: '8px' }}>
                  Requested:
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span key={row.original?.sku_id} style={{ marginRight: '8px' }}>
                  Reference:
                </span>
              </div>
            </div>
          );
        },
      },

      {
        header: 'Comment',
        Cell: ({ row }) => {
          return (
            <div>
              <div style={{ alignItems: 'center', marginBottom: '8px' }}>
                <CustomTextArea value={row.original?.comment} placeholder='Comment here' />
              </div>
            </div>
          );
        },
      },
    ],
    [],
  );
  return (
    <>
      <MantineTable
        columns={columns}
        initialData={data}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        pageSize={10}
        enableColumnFilter={false}
        enableFilters={false}
        columnFilterDisplayMode={false}
      />
    </>
  );
};
