import React, { useEffect, useState } from 'react';
import { Table } from './table';
import { Button } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { axios } from '../../../../../utils/axios';

export const BomComponent = ({ activeTab }) => {
  const { rfsNumber } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (activeTab === 'vicSubmission') fetch();
  }, [activeTab]);

  const fetch = async (refresh = false) => {
    try {
      setLoading(true);
      const response = await axios.get(`costing-request/step/vic-submission/${rfsNumber}`, {
        params: {
          refresh_vic_bom: refresh,
        },
      });
      setData(response.data.data.bom_steps);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    fetch(true);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          marginBottom: '16px',
        }}
      >
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button variant='light' color='dark' onClick={() => handleRefresh()}>
            <IconRefresh size={16} />
          </Button>
        </div>
      </div>
      <div>
        <Table data={data} loading={loading} />
      </div>
    </>
  );
};
